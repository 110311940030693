import styled from 'styled-components';

export const Wrapper = styled.div`
  ${props => props.theme.media.desktop`
    padding: 16px 48px;
    `};
  ${props => props.theme.media.phoneAndTablet`
    padding: 10px 16px 16px;
    `};
`;

export const Logo = styled.img`
  height: 40px;
  width: auto;
`;
