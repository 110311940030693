import { useContext } from 'react';
import { copyFor } from 'config/copy';
import { SignupContext, Signup } from 'contexts/signup/signup.context';
import { Alert } from '@arcadiapower/shrike';
import {
  formatPaymentMethod,
  getDefaultPaymentMethod,
  PartialPaymentMethodFragment,
} from 'utilities/payment-method';
import {
  Description,
  InfoWrapper,
  Label,
  SmallInfo,
  UserInfo,
  Wrapper,
} from './review-content.style';
import { ReviewForm } from './review-form';

const getCopy = copyFor('signup.steps.review');

export type ReviewContentProps = {
  onSubmit: () => void;
};

export const ReviewContent = ({
  onSubmit,
}: ReviewContentProps): JSX.Element => {
  const { user, localPayment } = useContext<SignupContext>(Signup);
  // This should never happen if the context is set up properly, but we're going
  // to check for it anyway
  if (!user) return <Alert>{getCopy('errors.generic')}</Alert>;
  const defaultPaymentMethod = getDefaultPaymentMethod(user.paymentMethods);

  const paymentMethod: PartialPaymentMethodFragment | undefined =
    defaultPaymentMethod || localPayment;

  return (
    <Wrapper>
      <InfoWrapper>
        <UserInfo>
          <Label>{getCopy('userData.name')}</Label>
          <Description>
            {user.firstName} {user.lastName}
          </Description>
        </UserInfo>
        <UserInfo>
          <Label>{getCopy('userData.email')}</Label>
          <Description>{user.email}</Description>
        </UserInfo>
      </InfoWrapper>
      {paymentMethod && (
        <InfoWrapper>
          <UserInfo>
            <Label>{getCopy('userData.payment')}</Label>
            <Description>{formatPaymentMethod(paymentMethod)}</Description>
            <SmallInfo>{getCopy('userData.paymentInfo')}</SmallInfo>
          </UserInfo>
        </InfoWrapper>
      )}
      <InfoWrapper>
        <ReviewForm onSubmit={onSubmit} />
      </InfoWrapper>
    </Wrapper>
  );
};
