import { BackButton } from 'components/dashboard-back-button';
import { Text } from '@arcadiapower/shrike';
import { NarrowSpacer, HeaderContainer } from './profile-header.style';

export type Props = {
  title: string;
  subtitle?: string;
  backLink?: string;
  hideSpacer?: boolean;
};

export const ProfileHeader = ({
  title,
  subtitle,
  backLink,
  hideSpacer = false,
}: Props): JSX.Element => {
  return (
    <>
      {backLink && <BackButton to={backLink} />}
      <HeaderContainer>
        <Text textStyle="heading800">{title}</Text>
        <Text margin="1rem 0 2rem 0">{subtitle}</Text>
      </HeaderContainer>
      {!hideSpacer && <NarrowSpacer />}
    </>
  );
};
