import { useMemo, useState } from 'react';
import { Charts } from '@arcadiapower/shrike';
import { formatDate } from '@arcadiapower/warbler';
import { BrightenStatementList } from 'components/usage/energy-use/energy-use.component';
import { GraphWrapper } from './statement-graph.style';
import {
  calculateMaxAndMinXAxisDates,
  calculateYAxisMaximum,
  processGraphData,
  GraphMarker,
} from './statement-graph.utils';

export type StatementGraphProps = {
  className?: string;
  currentStatementIndex: number;
  statements: BrightenStatementList;
};

export const StatementGraph: React.FC<StatementGraphProps> = ({
  className,
  currentStatementIndex,
  statements,
}) => {
  const [yAxisMax, setYAxisMax] = useState<number>();
  const [xAxisMaxAndMin, setXAxisMaxAndMin] = useState({});
  const [seriesData, setSeriesData] = useState<GraphMarker[]>([
    { x: '0', y: 0 },
  ]);
  const xAxisMonthRange = 8;
  const currentStatementSinceLatest =
    statements && currentStatementIndex !== undefined
      ? statements.length - 1 - currentStatementIndex
      : 0;

  useMemo(() => {
    const usageGraphData = processGraphData(
      statements.map(s => s.utilityStatement)
    );
    if (usageGraphData.length) {
      const yAxisMaximum = calculateYAxisMaximum(usageGraphData);
      setSeriesData(usageGraphData);
      setYAxisMax(yAxisMaximum);
      setXAxisMaxAndMin(
        calculateMaxAndMinXAxisDates({
          currentStatementSinceLatest,
          graphData: usageGraphData,
          xAxisMonthRange,
        })
      );
    }
  }, [currentStatementSinceLatest, statements]);

  const graphConfig = {
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      discrete: [
        {
          dataPointIndex: currentStatementSinceLatest,
          seriesIndex: 0,
          size: 7,
          strokeColor: 'red',
        },
      ],
      size: 5,
    },
    stroke: {
      dashArray: 0,
      lineCap: 'butt' as 'butt' | 'square' | 'round',
      show: true,
      width: 2,
    },
    subtitle: {
      offsetY: 20,
      style: {
        fontSize: '14px',
      },
      text: formatDate(
        statements[currentStatementIndex]?.serviceEndDate || new Date(),
        { format: 'yyyy' }
      ),
    },
    tooltip: {
      intersect: true,
      shared: false,
      x: {
        show: false,
      },
      y: {
        formatter: (value: number) => {
          return `${value} kwh`;
        },
        title: {
          formatter: (seriesName: string) => `${seriesName}:`,
        },
      },
    },
  };

  return (
    <GraphWrapper className={className}>
      <Charts.Graph
        config={graphConfig}
        id="StatementGraph"
        series={[
          {
            data: seriesData,
            name: 'Energy Use',
          },
        ]}
        type="line"
        xAxisConfig={{
          labels: {
            format: 'MMM',
            hideOverlappingLabels: false,
          },
          ...xAxisMaxAndMin,
        }}
        yAxisConfig={{ max: yAxisMax }}
      />
    </GraphWrapper>
  );
};
