import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  margin: 2rem 1rem;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  margin: 2rem 1rem 1rem 1rem;
  border: 1px solid #ced4da;
  height: max-content;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${props => props.theme.media.desktop`
    grid-area: top_left;
    margin: 0;
  `}
`;

export const ContentWrapper = styled.div`
  padding: 2rem 1rem;
  border-radius: 8px 8px 0 0;
  background-color: white;
`;

export const IconSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HelpImage = styled.img`
  width: 100%;
`;
