import { useState, useContext, useCallback } from 'react';
import { SignupContext, Signup } from 'contexts/signup/signup.context';
import { PrimaryButton } from 'styles/simple-grid.style';
import { useMutation } from '@apollo/client';
import { copyFor } from 'config/copy';
import { Alert } from '@arcadiapower/shrike';
import { noop, contractLink, contractTypes } from 'utilities';
import { AgreementsWrapper, Description } from '../review-content.style';
import { SET_ACCOUNT_SETUP_COMPLETED } from './review-form.api';
import { InfoLink } from '../../create-account/create-account-form.style';
import { StyledCheckbox } from './review-form.style';

const getCopy = copyFor('signup.steps.review');

export type ReviewFormProps = {
  onSubmit: () => void;
};

export const ReviewForm = ({ onSubmit }: ReviewFormProps): JSX.Element => {
  const { next, utility } = useContext<SignupContext>(Signup);
  const [agreement, setAgreement] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pdfError, setPdfError] = useState(false);
  const [setAccountSetupCompleted, { loading, error }] = useMutation(
    SET_ACCOUNT_SETUP_COMPLETED,
    { onCompleted: next, onError: noop }
  );

  const submit = useCallback(async () => {
    setSubmitting(true);
    try {
      await onSubmit();
      setSubmitting(false);
    } catch (error) {
      // Could not generate PDF file
      setPdfError(true);
      setSubmitting(false);
      return;
    }

    await setAccountSetupCompleted({
      variables: {
        input: {},
      },
    });
  }, [setAccountSetupCompleted, onSubmit]);

  if (pdfError) {
    return (
      <AgreementsWrapper>
        <div>{getCopy('form.errors.pdfGenerationError')}</div>
      </AgreementsWrapper>
    );
  }

  const utilityId = (utility as NonNullable<SignupContext['utility']>).id;

  return (
    <>
      <AgreementsWrapper>
        <StyledCheckbox
          checked={agreement}
          onChange={() => setAgreement(!agreement)}
          data-testid="reviewFormButtonCheckbox"
          disabled={loading || submitting}
        >
          <Description>
            {getCopy('form.agreements.beforeLinks')}
            <InfoLink
              href={contractLink(contractTypes.DISCLOSURE, utilityId)}
              openInNewTab={true}
              type="inline"
            >
              {getCopy('form.terms')}
            </InfoLink>
            {getCopy('form.agreements.betweenLinks')}
            <InfoLink
              href={contractLink(contractTypes.CONTRACT, utilityId)}
              openInNewTab={true}
              type="inline"
            >
              {getCopy('form.contract')}
            </InfoLink>
            {getCopy('form.agreements.afterLinks')}
          </Description>
        </StyledCheckbox>
      </AgreementsWrapper>
      <PrimaryButton
        disabled={!agreement}
        loading={loading || submitting}
        onClick={submit}
        data-testid="reviewFormButton"
      >
        {getCopy('form.cta')}
      </PrimaryButton>
      {!!error && <Alert>{getCopy('form.errors.generic')}</Alert>}
    </>
  );
};
