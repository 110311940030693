import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { copyFor } from 'config/copy';
import { Alert, Modal, Text } from '@arcadiapower/shrike';
import { PaymentMethodItem } from 'components/dashboard-profile-payment-method';
import {
  ActionWrapper,
  DeleteButton,
  SecondaryButton,
  StyledModal,
} from 'styles/simple-grid.style';
import { DELETE_PAYMENT_METHOD } from './payment-method-delete.api';

const getCopy = copyFor('dashboard.paymentMethod.delete');

export type Props = {
  paymentMethod: FullPaymentMethodFragment;
  hide: () => void;
};

export const PaymentMethodDeleteModal = ({
  paymentMethod,
  hide,
}: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deletePaymentMethodMutation, { error }] = useMutation<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >(DELETE_PAYMENT_METHOD);

  const deletePaymentMethod = async () => {
    // Delete payment method here
    setLoading(true);
    try {
      const input = { paymentMethodId: paymentMethod.id };
      await deletePaymentMethodMutation({
        variables: { input },
      });
      hide();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <StyledModal
      aria-label={`delete-modal-${paymentMethod.id}`}
      data-testid={`delete-modal-${paymentMethod.id}`}
    >
      <Modal.Header title={getCopy('title')} onClose={hide} />
      <Modal.Content>
        <Text>{getCopy('confirmation')}</Text>

        <PaymentMethodItem
          key={paymentMethod.id}
          paymentMethod={paymentMethod}
          hideActions={true}
        />
        <ActionWrapper>
          {error && <Alert>{getCopy('error')}</Alert>}
          <DeleteButton
            loading={loading}
            data-testid="confirmDeletion"
            margin={{ top: '3rem' }}
            onClick={deletePaymentMethod}
          >
            {getCopy('deletePayment')}
          </DeleteButton>
          <SecondaryButton
            disabled={loading}
            data-testid="cancelDeletion"
            margin={{ top: '1rem' }}
            onClick={hide}
          >
            {getCopy('cancel')}
          </SecondaryButton>
        </ActionWrapper>
      </Modal.Content>
    </StyledModal>
  );
};
