import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;

type ButtonProps = {
  current?: boolean;
};

export const PageButton = styled.button<ButtonProps>`
  cursor: pointer;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  ${props => `
    background-color: ${
      props.current ? props.theme.colors.primary : 'transparent'
    }
  `};
  ${props => `
    color: ${props.current ? 'white' : props.theme.colors.text1}
  `};

  ${props =>
    props.disabled &&
    `
    color: ${props.theme.colors.accent3}
  `};
`;
