import { Notification as ShrikeToastNotification } from '@arcadiapower/shrike';
import { DashboardWrapper } from 'components/dashboard-wrapper';
import { Switch, Route } from 'react-router-dom';
import {
  dashboardProfile,
  dashboardUsage,
  dashboardBill,
  dashboardEnrollment,
  statementDetail,
} from 'config/routes';
import { PreEnrollment } from './pre-enrollment';
import { StatementDetail } from './statement-detail';
import { DashboardBill } from './bill';
import { DashboardProfile } from './profile';
import { DashboardUsage } from './usage';

export const Dashboard = (): JSX.Element => {
  return (
    <DashboardWrapper>
      <ShrikeToastNotification.Provider
        wrapperProps={{
          className: 'toastWrapper',
          'data-testid': 'toastWrapper',
        }}
      >
        <Switch>
          <Route path={dashboardUsage} component={DashboardUsage} />
          <Route path={dashboardProfile} component={DashboardProfile} />
          <Route path={dashboardBill} component={DashboardBill} />
          <Route path={dashboardEnrollment} component={PreEnrollment} />
          <Route path={statementDetail} component={StatementDetail} />
        </Switch>
      </ShrikeToastNotification.Provider>
    </DashboardWrapper>
  );
};
