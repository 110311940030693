import { useContext } from 'react';
import { GridContainer, PrimaryGrid, Title } from 'styles/simple-grid.style';
import { Text } from '@arcadiapower/shrike';
import { PaymentForm } from 'components/form/payment-form';
import { Signup, SignupContext } from 'contexts/signup';
import { copyFor } from 'config/copy';
import { PartialPaymentMethodFragment } from 'utilities/payment-method';

const getCopy = copyFor('signup.steps.payment');

export const Payment = (): JSX.Element => {
  const { next, setLocalPayment } = useContext<SignupContext>(Signup);

  return (
    <GridContainer data-testid="step.payment">
      <PrimaryGrid>
        <Title>{getCopy('title')}</Title>
        <Text margin={{ bottom: '2rem', top: '0' }}>
          {getCopy('description')}
        </Text>

        <PaymentForm
          onSubmit={setupIntent => {
            if (
              setupIntent &&
              setupIntent.payment_method !== null &&
              typeof setupIntent.payment_method !== 'string'
            ) {
              const paymentMethod: PartialPaymentMethodFragment = setupIntent
                .payment_method.card
                ? {
                    details: {
                      __typename: 'CreditCardPayment',
                      cardNumberLastFour: setupIntent.payment_method.card.last4,
                      vendorName: setupIntent.payment_method.card.brand,
                    },
                  }
                : {
                    details: {
                      __typename: 'AchPayment',
                      accountNumberLastFour:
                        // @ts-expect-error Bank Account actually has last4 but its type inside Shrike is missing it
                        setupIntent.payment_method.us_bank_account?.last4,
                    },
                  };
              setLocalPayment(paymentMethod);
            }
            next();
          }}
        />
      </PrimaryGrid>
    </GridContainer>
  );
};
