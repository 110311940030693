import { Page } from 'styles';
import { GridContainer, PrimaryGrid, Title } from 'styles/simple-grid.style';
import { NavBarSimple } from 'components/nav-bar-simple';
import { PasswordResetForm } from 'components/password-reset-form';
import { copyFor } from 'config/copy';

const getCopy = copyFor('login.passwordReset');

export const PasswordReset: React.FC = () => {
  return (
    <Page>
      <NavBarSimple />
      <GridContainer>
        <PrimaryGrid>
          <Title>{getCopy('title')}</Title>
          <PasswordResetForm />
        </PrimaryGrid>
      </GridContainer>
    </Page>
  );
};
