import { jsPDF } from 'jspdf';
import { formatDate } from '@arcadiapower/warbler';
import html2canvas from 'html2canvas';

export const noop = (): void => {
  // Intentionally Blank
};

export const isValidPassword = (password: string): boolean => {
  return !!password.match(/^.{8,}$/);
};

export const isValidEmail = (email: string): boolean => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(email);
};

export const parseDynamicRoute = (
  route: string,
  props: Record<string, string | number>
): string => {
  for (const [key, value] of Object.entries(props)) {
    route = route.replace(`:${key}`, value.toString());
  }
  return route;
};

export const wait = async (ms: number): Promise<void> =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });

export const addressFormatter = ({
  serviceAddressStreetOne,
  serviceAddressCity,
  serviceAddressState,
  serviceAddressZip,
}: {
  serviceAddressStreetOne: string;
  serviceAddressCity: string;
  serviceAddressState: string;
  serviceAddressZip: string;
}): string =>
  `${serviceAddressStreetOne}, ${serviceAddressCity}, ${serviceAddressState}, ${serviceAddressZip}`;

export const formatDateRange = ({
  serviceStartDate,
  serviceEndDate,
}: {
  serviceStartDate: string;
  serviceEndDate: string;
}): string => {
  const start = formatDate(serviceStartDate, { format: 'MMM d' });
  const end = formatDate(serviceEndDate, { format: 'MMM d, Y' });
  return `${start} - ${end}`;
};

export const monthName = (date: string): string => {
  return formatDate(date, { format: 'MMMM' });
};

export const generatePdfFromCanvas = (canvas: HTMLCanvasElement): string => {
  const imgData = canvas.toDataURL('image/png');
  // eslint-disable-next-line new-cap
  const pdf = new jsPDF({ unit: 'px' });
  const width = 200; // hard set width to fit on A4 page
  // Scale height according to this new width
  const heightScaling = ((canvas.width - width) / canvas.width) * 100;
  const height = canvas.height * ((100 - heightScaling) / 100);
  pdf.addImage({ height, imageData: imgData, width, x: 10, y: 0 });

  const binary = pdf.output();
  return btoa(binary);
};

export const generatePdf = async (pdfContent: HTMLElement): Promise<string> => {
  const canvas = await html2canvas(pdfContent);
  return generatePdfFromCanvas(canvas);
};

export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  }).format(amount);
};

export const formatCurrencyFromCents = (amount: number): string => {
  return formatCurrency(amount / 100);
};

export const formatRate = (rate: number): string => {
  return `x $${(rate / 100).toFixed(4)}`;
};

export enum contractTypes {
  CONTRACT = 'contract',
  DISCLOSURE = 'disclosure',
}

export const contractLink = (
  contractType: contractTypes,
  utilityId: number
): string => {
  return `${process.env.ARCADIA_API_URL}/contracts/${utilityId}/${contractType}.pdf`;
};

export const discountToPercentage = (discountRate: number): number => {
  return Math.floor(discountRate * 100);
};

export const isGoogleAuthActivated = (): boolean => {
  return process.env.ARCADIA_GOOGLE_FEATURE?.toUpperCase() === 'TRUE';
};
