import { useCallback, useState } from 'react';
import { SignupContext } from 'contexts/signup';
import {
  SIGNUP_STEPS,
  SignupStepType,
  SignupStepKeyType,
} from '../config/signup-funnel';

export type Props = {
  user: SignupContext['user'];
};

export type useSignupFunnelHook = {
  currentStep: SignupStepType;
  setStepByKey: (key: SignupStepKeyType) => void;
  next: () => void;
};

export const useSignupFunnel = ({ user }: Props): useSignupFunnelHook => {
  const [currentStep, setCurrentStep] = useState<SignupStepType>(
    SIGNUP_STEPS[0]
  );

  const setStepByKey = useCallback((key: SignupStepKeyType) => {
    const destination = SIGNUP_STEPS.find(step => key === step.key);
    if (destination) setCurrentStep(destination);
  }, []);

  const next = useCallback(
    ({ fromStep }: { fromStep?: SignupStepType } = {}): void => {
      const keyToSearch = fromStep?.key || currentStep.key;

      const currentStepIndex = SIGNUP_STEPS.findIndex(
        step => keyToSearch === step.key
      );

      const nextStepIndex = currentStepIndex + 1;

      if (nextStepIndex === SIGNUP_STEPS.length) {
        throw new Error('You have reached the final step and cannot move on');
      }

      const nextStep = SIGNUP_STEPS[nextStepIndex];
      if (nextStep.shouldSkip && user && nextStep.shouldSkip(user)) {
        return next({ fromStep: nextStep });
      }
      setCurrentStep(nextStep);
    },
    [currentStep, user]
  );

  return { currentStep, next, setStepByKey };
};
