import { useQuery } from '@apollo/client';
import { STRIPE_KEY } from 'config/constants';
import { copyFor } from 'config/copy';
import {
  Loading,
  Alert,
  PaymentMethodFormWithMicrodepositsProps,
} from '@arcadiapower/shrike';
import { GET_CLIENT_SECRET } from './payment-form.api';
import { StyledPaymentMethodForm } from './payment-form.style';

export type OnSubmitType = Pick<
  PaymentMethodFormWithMicrodepositsProps,
  'onSubmit'
>;

const getCopy = copyFor('dashboard.paymentMethod.add');

export type Props = OnSubmitType & {
  className?: string;
};

export const PaymentForm = ({ className, onSubmit }: Props): JSX.Element => {
  const {
    data: clientSecretData,
    loading: loadingGettingToken,
    error: getConnectTokenError,
  } = useQuery<GetClientSecretQuery, GetClientSecretQueryVariables>(
    GET_CLIENT_SECRET
  );
  const clientSecret = clientSecretData?.getClientSecret;

  if (loadingGettingToken) {
    return <Loading margin={{ left: '175px' }} />;
  } else if (getConnectTokenError || !clientSecret) {
    return <Alert>{getCopy('errorLoadingPayment')}</Alert>;
  }

  return (
    <div data-testid="payment-form" className={className}>
      <StyledPaymentMethodForm
        onSubmit={onSubmit}
        stripeKey={STRIPE_KEY || ''}
        stripeClientSecret={clientSecret}
      />
    </div>
  );
};
