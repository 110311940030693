import styled from 'styled-components';

export const ExternalLoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
`;
