import { ApolloClient } from '@apollo/client';
import {
  STORE_KEY_UTILITY,
  STORE_KEY_ZIP,
  STORE_KEY_BRIGHTEN_PLAN_ID,
  STORE_SIGNUP_MONTH,
} from 'config/constants';
import { SignupStepKeyType, SIGNUP_STEP_KEYS } from 'config/signup-funnel';
import store from 'store';
import { GET_SIGNUP_USER } from './signup.api';

const getRouteFromUser = (
  currentUser: NonNullable<GetSignupUserQuery['currentUser']>
) => {
  let route: SignupStepKeyType;
  if (currentUser.accountSetupCompleted) {
    route = SIGNUP_STEP_KEYS.WELCOME;
  } else if (
    !currentUser.arcadiaUtilityCredential ||
    currentUser.arcadiaUtilityCredential?.verificationStatus === 'rejected'
  ) {
    // If no credentials or credentials are incorrect - return to the connect screen
    route = SIGNUP_STEP_KEYS.CONNECT_ACCOUNT;
  } else if (!currentUser.paymentMethods?.length) {
    // Return payment screens if no payment
    route = SIGNUP_STEP_KEYS.PAYMENT;
  } else {
    // Return review screen if account set up is not completed
    route = SIGNUP_STEP_KEYS.REVIEW;
  }
  return route;
};

type ResumingDataResult = {
  authenticated: boolean;
  route: SignupStepKeyType;
  localValues?: {
    brightenPlanId: number;
    utility: ArcadiaUtility;
    zip: string;
  };
};

export const getResumingData = async ({
  apolloClient,
}: {
  apolloClient: ApolloClient<unknown>;
}): Promise<ResumingDataResult> => {
  let route: SignupStepKeyType;
  let localValues;

  const {
    data: { currentUser },
  } = await apolloClient.query<GetSignupUserQuery>({
    query: GET_SIGNUP_USER,
  });

  if (currentUser) {
    route = getRouteFromUser(currentUser);
  } else {
    const currentDate = new Date();
    const signupMonth = store.get(STORE_SIGNUP_MONTH);

    const utility = store.get(STORE_KEY_UTILITY);
    const zip = store.get(STORE_KEY_ZIP);
    const brightenPlanId = store.get(STORE_KEY_BRIGHTEN_PLAN_ID);

    const validStore = utility && zip && brightenPlanId && signupMonth;

    route = 'utility-select';
    if (validStore && signupMonth === currentDate.getMonth() + 1) {
      localValues = { brightenPlanId, utility, zip };
      route = 'plan-selection';
    }
  }
  return { authenticated: !!currentUser, localValues, route };
};
