import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'styles';
import { client } from 'config/apollo-client';
import { ApolloProvider } from '@apollo/client';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';
import { Login } from 'pages/login';
import { Signup } from 'pages/signup';
import { Logout } from 'pages/logout';
import { loginRoot, dashboard, signup, logout } from 'config/routes';
import { Dashboard } from 'pages/dashboard';
import { useGlobalTracking } from 'hooks/use-global-tracking.hook';
import { theme } from './config/theme';
import '@arcadiapower/shrike/dist/shrike.css';

// We have an empty inline component here because the global
// tracking hook needs to be nested under the apollo client and react-router
// provider
const Tracking = () => {
  useGlobalTracking();
  return null;
};

function App(): JSX.Element {
  return (
    <Router>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Switch>
            <Route path={loginRoot} component={Login} />
            <Route path={signup} component={Signup} />
            <Route path={logout} component={Logout} />
            <Route path={dashboard} component={Dashboard} />
            <Redirect to={signup} />
          </Switch>
          <Tracking />
        </ThemeProvider>
      </ApolloProvider>
    </Router>
  );
}

export { App };
