import { gql } from '@apollo/client';
import { generateDashboardUsageUser } from 'mocks/user';

export const GET_DASHBOARD_USAGE_USER = gql`
  query getDashboardUsageUser {
    currentUser {
      email
      id
      firstName
      lastName
      enrolledState
      arcadiaUtilityCredential {
        id
        username
        verificationStatus
      }
      utility {
        id
        name
      }
    }
  }
`;

export const generateCurrentUserOutput = (): GetDashboardUsageUserQuery => {
  return {
    currentUser: generateDashboardUsageUser(),
  };
};
