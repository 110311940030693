import { gql } from '@apollo/client';

export const UPLOAD_ENROLLMENT_CONFIRMATION = gql`
  mutation uploadEnrollmentConfirmation(
    $input: UploadEnrollmentConfirmationInput!
  ) {
    uploadEnrollmentConfirmation(input: $input) {
      id
    }
  }
`;
