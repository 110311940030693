import { gql } from '@apollo/client';

export const GET_DASHBOARD_BRIGHTEN_STATEMENTS_GRAPH = gql`
  query listBrightenStatementsGraph($offset: Int!, $limit: Int!) {
    listBrightenStatements(offset: $offset, limit: $limit) {
      pages
      totalCount
      statements {
        id
        serviceStartDate
        serviceEndDate
        utilityStatement {
          id
          kwh
          serviceStartDate
          serviceEndDate
        }
      }
    }
  }
`;

export const generateGetBrightenStatementsGraph = ({
  pages = 2,
  statements = 10,
  totalCount = 20,
} = {}): ListBrightenStatementsGraphQuery => {
  if (totalCount === 0) {
    return {
      listBrightenStatements: {
        pages: 0,
        statements: [],
        totalCount: 0,
      },
    };
  }
  return {
    listBrightenStatements: {
      pages,
      statements: Array.from(Array(statements).keys()).map(id => ({
        id: id + 1,
        serviceEndDate: '10-10-2021',
        serviceStartDate: '10-10-2021',
        statementTotalCents: 1000,
        utilityStatement: {
          id: id * 2 + 1,
          kwh: 500,
          serviceEndDate: '10-10-2021',
          serviceStartDate: '10-10-2021',
          utilityCharge: 200,
        },
      })),
      totalCount,
    },
  };
};
