import { useState, useMemo, useContext } from 'react';
import { MainMenuContainer } from 'styles';
import { ProfileHeader } from 'components/dashboard-profile-header';
import { copyFor } from 'config/copy';
import { PasswordInput } from 'components/password-input';
import {
  Alert,
  Text,
  Notification as ShrikeToastNotification,
} from '@arcadiapower/shrike';
import { PrimaryButton, SecondaryButton } from 'styles/simple-grid.style';
import { useMutation } from '@apollo/client';
import { TOAST_DISPLAY_TIME, Toast } from 'config/constants';
import { isValidPassword } from 'utilities';
import { profileAccount } from 'config/routes';
import { UPDATE_USER_PASSWORD } from './account-details-password.api';

const getCopy = copyFor('dashboard.accountDetails.passwordForm');

export const ProfileAccountDetailsPassword = (): JSX.Element => {
  const [password, setPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  const isDirty = password !== '';

  const isValid = useMemo(
    () => isValidPassword(password) && currentPassword,
    [password, currentPassword]
  );

  const { addNotification } = useContext(ShrikeToastNotification.Context);

  const clearForm = () => {
    setPassword('');
    setCurrentPassword('');
  };

  const triggerToast = ({ message, variant = 'success' }: Toast): void => {
    addNotification({
      displayTime: TOAST_DISPLAY_TIME,
      message,
      variant,
    });
  };

  const onPasswordUpdateCompleted = () => {
    clearForm();
    triggerToast({ message: getCopy('toast.success') });
  };

  const onPasswordUpdateFail = (message = getCopy('toast.error')) => {
    triggerToast({ message, variant: 'error' });
  };

  const [updateUserPassword, { loading, error }] = useMutation<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >(UPDATE_USER_PASSWORD, {
    onError: () => onPasswordUpdateFail(),
  });

  const submit = async () => {
    const { data } = await updateUserPassword({
      variables: { input: { currentPassword, password } },
    });
    const success = data?.updateUserPassword.success;
    if (success) onPasswordUpdateCompleted();
    else onPasswordUpdateFail(getCopy('toast.errorPassword'));
  };

  return (
    <MainMenuContainer>
      <ProfileHeader title={getCopy('title')} backLink={profileAccount} />
      <PasswordInput
        onChange={(value: string) => {
          setCurrentPassword(value);
        }}
        name={getCopy('currentPasswordInput.name')}
        label={getCopy('currentPasswordInput.label')}
        placeholder={getCopy('currentPasswordInput.placeholder')}
        value={currentPassword}
        margin={{ bottom: '24px', top: '24px' }}
      />
      <PasswordInput
        onChange={(value: string) => {
          setPassword(value);
        }}
        name={getCopy('newPasswordInput.name')}
        label={getCopy('newPasswordInput.label')}
        placeholder={getCopy('newPasswordInput.placeholder')}
        value={password}
        margin={{ bottom: '24px', top: '24px' }}
      />
      <Text>{getCopy('newPasswordInput.description')}</Text>
      <PrimaryButton
        disabled={!isValid}
        loading={loading}
        onClick={submit}
        margin={{ bottom: '24px', top: '24px' }}
      >
        {getCopy('update')}
      </PrimaryButton>
      {error && (
        <Alert data-testid="passwordErrorAlert" margin={{ bottom: '24px' }}>
          {getCopy('error')}
        </Alert>
      )}
      {isDirty && (
        <SecondaryButton onClick={clearForm} disabled={loading}>
          {getCopy('undo')}
        </SecondaryButton>
      )}
    </MainMenuContainer>
  );
};
