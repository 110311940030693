import styled, { css } from 'styled-components';
import { Spacer, Text } from '@arcadiapower/shrike';
import { Link } from 'react-router-dom';

const menuOptionStyles = css`
  display: flex;
  flex: 1;
  justify-content: space-between;
  text-decoration: none;
  min-height: 4.5rem;
  color: ${props => props.theme.colors.background.primaryInverse};
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
  margin: 0.5rem 0;
`;

export const MenuOptionLink = styled(Link)`
  ${menuOptionStyles}
`;

type MenuTextProps = {
  notification?: boolean;
};

export const HeaderText = styled(Text)<MenuTextProps>`
  ${props =>
    props.notification &&
    `
    color: ${props.theme.colors.content.error};
  `}
`;

export const SubtitleText = styled(Text)<MenuTextProps>`
  ${props =>
    props.notification &&
    `
    color: ${props.theme.colors.content.error};
    opacity:1;
  `}
`;

export const MenuOptionDiv = styled.div`
  ${menuOptionStyles}
`;

export const NarrowSpacer = styled(Spacer)`
  padding: 0 0;
`;
