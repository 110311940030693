const longOrShortZipRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const isValidZipCode = (value: string): boolean => {
  return longOrShortZipRegExp.test(value);
};

export const partitionSupportedUtilities = (
  utilities: Array<ArcadiaUtility>
): {
  supportedUtilities: Array<ArcadiaUtility>;
  unsupportedUtilities: Array<ArcadiaUtility>;
} => {
  const supportedUtilities: Array<ArcadiaUtility> = [];
  const unsupportedUtilities: Array<ArcadiaUtility> = [];

  utilities.forEach(u =>
    (u.supported ? supportedUtilities : unsupportedUtilities).push(u)
  );

  return { supportedUtilities, unsupportedUtilities };
};

type ShrikeOption = {
  text: string;
  value: number;
};

export const mapUtilitiesArrayToOptions = (
  utilities: ArcadiaUtility[] | undefined
): ShrikeOption[] | undefined =>
  utilities?.map(({ id, name, supported }) => ({
    text: supported ? name : `${name} (not supported)`,
    value: id,
  }));

type utilitiesOptions = {
  recommendedOptions: Array<ArcadiaUtility>;
  generalOptions: Array<ArcadiaUtility>;
};

export const mapUtilitiesDataToOptions = ({
  recommendedOptions,
  generalOptions,
}: utilitiesOptions): {
  options: ShrikeOption[];
  suggestedOptions: ShrikeOption[];
} => {
  const suggestedOptions = mapUtilitiesArrayToOptions(
    recommendedOptions
  ) as ShrikeOption[];
  const options = mapUtilitiesArrayToOptions(generalOptions) as ShrikeOption[];
  return {
    options,
    suggestedOptions,
  };
};
