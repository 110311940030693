import styled from 'styled-components';
import { Checkbox } from '@arcadiapower/shrike';

export const StyledCheckbox = styled(Checkbox)`
  ${props => `
    & span{
      border: 1px solid ${props.theme.colors.text1};
    }
  `};
`;
