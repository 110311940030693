import styled from 'styled-components';
import { Link } from '@arcadiapower/shrike';

export const TextWrapper = styled.div`
  text-align: center;
  display: inline;
  margin: 1rem 0;
`;

export const PlanProperties = styled.div`
  background-color: ${props => props.theme.colors.propertyBackground};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 8px;
  align-items: center;
`;

export const PropertiesContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const DetailsLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-around;
`;

export const DetailsLink = styled(Link)`
  font-size: 14px;
  color: ${props => props.theme.colors.accent2};
`;

export const LoginLink = styled(Link)`
  color: ${props => props.theme.colors.accent2};
`;

export const DetailsContainer = styled.div`
  border: 1px solid gray;
  border-radius: 8px;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 2rem;
`;
