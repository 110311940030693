import { gql } from '@apollo/client';

export const GET_BRIGHTEN_STATEMENTS = gql`
  query listBrightenStatementsHistory($limit: Int!, $offset: Int!) {
    listBrightenStatements(limit: $limit, offset: $offset) {
      totalCount
      pages
      statements {
        id
        serviceStartDate
        serviceEndDate
        statementTotalCents
        arcadiaInvoice {
          id
          total
        }
        utilityStatement {
          id
          kwh
          utilityCharge
        }
      }
    }
  }
`;

export const generateGetBrightenStatements =
  (): ListBrightenStatementsHistoryQuery => ({
    listBrightenStatements: {
      pages: 3,
      statements: Array.from(Array(10).keys()).map(id => ({
        arcadiaInvoice: {
          id: 1,
          total: 2000,
        },
        id: id + 1,
        serviceEndDate: '10-10-2021',
        serviceStartDate: '10-10-2021',
        statementTotalCents: 1000,
        utilityStatement: {
          id: id * 2,
          kwh: 500,
          utilityCharge: 200,
        },
      })),
      totalCount: 30,
    },
  });
