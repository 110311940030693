import { SignupContext } from 'contexts/signup';
import { ValueOf } from 'utilities/typescript';

type AuthenticatedUser = NonNullable<SignupContext['user']>;

type shouldSkipFunction = (user: AuthenticatedUser) => boolean;

export const SIGNUP_STEP_KEYS = {
  UTILITY_SELECT: 'utility-select',
  PLAN_SELECTION: 'plan-selection',
  CREATE_ACCOUNT: 'create-account',
  CONNECT_ACCOUNT: 'connect-arcadia',
  PAYMENT: 'payment',
  REVIEW: 'review',
  WELCOME: 'welcome',
} as const;

export type SignupStepKeyType = ValueOf<typeof SIGNUP_STEP_KEYS>;

export type SignupStepType = Readonly<{
  key: SignupStepKeyType;
  shouldSkip?: shouldSkipFunction;
}>;

export const SIGNUP_STEPS: ReadonlyArray<SignupStepType> = [
  { key: SIGNUP_STEP_KEYS.UTILITY_SELECT },
  { key: SIGNUP_STEP_KEYS.PLAN_SELECTION },
  { key: SIGNUP_STEP_KEYS.CREATE_ACCOUNT },
  { key: SIGNUP_STEP_KEYS.CONNECT_ACCOUNT },
  {
    key: SIGNUP_STEP_KEYS.PAYMENT,
    shouldSkip: (user: AuthenticatedUser): boolean => {
      return !!user.paymentMethods.length;
    },
  },
  {
    key: SIGNUP_STEP_KEYS.REVIEW,
    shouldSkip: (user: AuthenticatedUser): boolean => {
      return user.accountSetupCompleted;
    },
  },
  { key: SIGNUP_STEP_KEYS.WELCOME },
];
