import { useState } from 'react';
import { DashboardTips } from 'components/dashboard-tips';
import { copyFor } from 'config/copy';
import { EnergyUse } from 'components/usage/energy-use';
import { EnergyUseHistory } from 'components/energy-use-history';
import { EmailConfirmationModal } from 'components/dashboard-profile-email-confirmation-modal';
import { useQueryParams } from 'hooks/use-query-params.hook';
import { HelpBox } from 'components/help-box';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_USAGE_USER } from './dashboard-usage.api';
import { UsagePage, UsageContainer } from './dashboard-usage.style';

export type DashboardUsageUser = NonNullable<
  GetDashboardUsageUserQuery['currentUser']
>;

const getCopy = copyFor('usage.tips');

export const DashboardUsage = (): JSX.Element => {
  const { data: dataCurrentUserQuery, loading: loadingCurrentUser } = useQuery<
    GetDashboardUsageUserQuery,
    GetDashboardUsageUserQueryVariables
  >(GET_DASHBOARD_USAGE_USER);
  const [emailConfirmationModalOpen, setEmailConfirmationModalOpen] =
    useState<boolean>(true);

  const queryParams = useQueryParams();
  const emailConfirmationToken = queryParams.get('token');
  const currentUser = dataCurrentUserQuery?.currentUser;

  return (
    <UsagePage className="usagePage">
      {emailConfirmationModalOpen &&
        !!emailConfirmationToken &&
        !loadingCurrentUser &&
        currentUser && (
          <EmailConfirmationModal
            onClose={() => setEmailConfirmationModalOpen(false)}
            token={emailConfirmationToken}
          />
        )}
      <DashboardTips
        loading={loadingCurrentUser}
        header={getCopy('header', { firstName: currentUser?.firstName })}
        content={getCopy('content')}
      />
      <UsageContainer>
        <EnergyUse loading={loadingCurrentUser} />
        <EnergyUseHistory loading={loadingCurrentUser} />
        <HelpBox loading={loadingCurrentUser} />
      </UsageContainer>
    </UsagePage>
  );
};
