import styled from 'styled-components';
import { Icon, Link } from '@arcadiapower/shrike';

export const BackContainer = styled.div`
  margin: 2rem 0;
`;

export const StyledIcon = styled(Icon)`
  vertical-align: top;
  margin-right: 0.5rem;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
