import { gql } from '@apollo/client';
import { generateUtility } from 'mocks/utility';
import { FULL_PAYMENT_METHOD_FRAGMENT } from 'api/fragments';

export const generateSignupUser = ({
  authenticated = true,
} = {}): GetSignupUserQuery => {
  if (!authenticated) {
    return { currentUser: null };
  }
  return {
    currentUser: {
      accountSetupCompleted: false,
      arcadiaUtilityCredential: null,
      brightenPlanId: 1,
      email: 'myemail@gmail.com',
      firstName: 'Signup',
      id: '1',
      isOauthLogin: false,
      lastName: 'User',
      paymentMethods: [],
      utility: generateUtility(),
      zipCode: '20001',
    },
  };
};

export const GET_SIGNUP_USER = gql`
  query getSignupUser {
    currentUser {
      email
      id
      firstName
      lastName
      utility {
        id
        name
      }
      zipCode
      accountSetupCompleted
      isOauthLogin
      arcadiaUtilityCredential {
        id
        verificationStatus
      }
      brightenPlanId
      paymentMethods {
        id
        ...FullPaymentMethod
      }
    }
  }
  ${FULL_PAYMENT_METHOD_FRAGMENT}
`;
