import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const Wrapper = styled.div<{ gridArea: string }>`
  background-color: white;
  padding: 2rem 1rem;
  ${props => props.theme.media.desktop`
    border: 1px solid ${props.theme.colors.accent3};
    border-radius: 8px;
    grid-area: ${props.gridArea};
  `}
`;

export const LoadingWrapper = styled.div`
  background-color: white;
  ${props => props.theme.media.desktop`
    border-radius: 8px;
    grid-area: bottom_right;
  `}
`;

export const TableWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.colors.accent3};
  width: 100%;
`;

export const AmountContainer = styled.div`
  display: inline-flex;
`;

export const IconContainer = styled.div`
  margin-left: 1rem;
`;

export const TableRow = styled.div`
  &.linked:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.accent3};
  }

  border-bottom: 1px solid ${props => props.theme.colors.accent3};
  display: flex;
  justify-content: space-between;
  padding: 14px 3px;
`;

export const CenteredText = styled(Text)`
  text-align: center;
`;
