import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMutation, useApolloClient } from '@apollo/client';
import { noop } from 'utilities';
import store from 'store';
import { copyFor } from 'config/copy';
import { Loading, Text } from '@arcadiapower/shrike';
import { useQueryParams } from 'hooks/use-query-params.hook';
import { loginRoot } from 'config/routes';
import { resetTracking } from 'utilities/analytics';
import { LOGOUT } from './logout.api';
import { Wrapper } from './logout.style';

const getCopy = copyFor('logout.root');

export const Logout = (): JSX.Element => {
  const client = useApolloClient();
  const history = useHistory();
  const queryParams = useQueryParams();
  const emailConfirmationToken = queryParams.get('token');

  const [logoutMutation, { error: errorLogout }] = useMutation(LOGOUT, {
    onCompleted: async () => {
      await client.clearStore();
      store.clearAll();
      const destination = emailConfirmationToken
        ? `${loginRoot}?token=${emailConfirmationToken}`
        : loginRoot;
      history.push(destination);
    },
    onError: noop,
  });

  useEffect(() => {
    logoutMutation({
      variables: {
        input: {},
      },
    });
    resetTracking();
  }, [logoutMutation]);

  if (errorLogout) {
    return (
      <Wrapper>
        <Text>{getCopy('errorTitle')}</Text>
        <Text>{getCopy('errorDetail')}</Text>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Loading />
      <Text>{getCopy('processing')}</Text>
    </Wrapper>
  );
};
