import styled from 'styled-components';
import { Page } from 'styles';

export const BillPage = styled(Page)`
  background-color: ${props => props.theme.colors.background2};
`;

export const BillContainer = styled.div`
  width: 100%;
  ${props => props.theme.media.desktop`
    display: grid;
    gap: 2rem;
    grid-template-columns: 33.3333% 66.6666%;
    padding: 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
    grid-template-areas: 
      "top_left top_right"
      "top_left bottom_right"
      "top_left tail_right"
      "bottom_left tail_right";
  `}
  background-color: ${props => props.theme.colors.background2};
`;
