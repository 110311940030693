type NotificationsDataUser = NonNullable<
  GetNotificationsDataQuery['currentUser']
>;

export const shouldShowConnectNotification = (
  user: NotificationsDataUser
): boolean => {
  const verificationStatus = user?.arcadiaUtilityCredential?.verificationStatus;

  return !!(
    verificationStatus && ['rejected', 'error'].includes(verificationStatus)
  );
};
