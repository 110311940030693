import { useContext } from 'react';
import BrightenLogo from 'assets/brighten-logo.png';
import {
  dashboardUsage,
  dashboardBill,
  dashboardProfile,
  dashboardEnrollment,
} from 'config/routes';
import { DashboardNavButton, PathType } from 'components/dashboard-nav-button';
import { copyFor } from 'config/copy';
import { USER_ENROLLED_STATES } from 'config/constants';
import { Notifications, NotificationsContext } from 'contexts/notifications';
import { ValueOf } from 'utilities/typescript';
import {
  Logo,
  NavbarWrapper,
  NavButtonsContainer,
} from './dashboard-navbar.style';

type ButtonKey = 'usage' | 'bill' | 'profile' | 'enrollment';

const getCopy = copyFor('dashboard.navbar');

export type Props = {
  enrolledState: ValueOf<typeof USER_ENROLLED_STATES>;
};

export const DashboardNavbar = ({ enrolledState }: Props): JSX.Element => {
  const { notifications } = useContext<NotificationsContext>(Notifications);
  const buttons = [
    { key: 'usage', label: getCopy('usage'), path: dashboardUsage },
    { key: 'bill', label: getCopy('bill'), path: dashboardBill },
    {
      key: 'profile',
      label: getCopy('profile'),
      notificationBadge: !!notifications.length,
      path: dashboardProfile,
    },
  ];

  let disabledButtons: string[] = [];

  if (enrolledState !== USER_ENROLLED_STATES.enrolled) {
    buttons.push({
      key: 'enrollment',
      label: getCopy('enrollment'),
      path: dashboardEnrollment,
    });
    disabledButtons = ['usage'];
    if (enrolledState !== USER_ENROLLED_STATES.removed) {
      disabledButtons.push('bill');
    }
  }

  return (
    <NavbarWrapper>
      <Logo alt="brighten logo" src={BrightenLogo} />
      <NavButtonsContainer>
        {buttons.map(button => (
          <DashboardNavButton
            key={button.path}
            path={button.path as PathType}
            label={button.label}
            notificationBadge={button.notificationBadge || false}
            data-testid={`nav-button-container-${button.path}`}
            disabled={disabledButtons.includes(button.key as ButtonKey)}
          />
        ))}
      </NavButtonsContainer>
    </NavbarWrapper>
  );
};
