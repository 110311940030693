import { DEBUG_ANALYTICS, APP_BUILD_ENV, SENTRY_DSN } from 'config/constants';
import * as Sentry from '@sentry/react';
import { ValueOf } from './typescript';

const initSentry = () => {
  const shouldUseSentry = ['production'].includes(APP_BUILD_ENV);
  if (!shouldUseSentry) return;
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: APP_BUILD_ENV,
    normalizeDepth: 5,
  });
};

export const initErrorReporting = (): void => {
  initSentry();
};

export const clearUserForErrorReporting = (): void => {
  Sentry.configureScope(scope => scope.setUser(null));
};

export const logAnalyticsError = (error: Error): void => {
  // TODO: Report this in sentry?
  // eslint-disable-next-line no-console
  if (DEBUG_ANALYTICS) console.error(error.message);
};
export const logAnalytics = (message: string): void => {
  // eslint-disable-next-line no-console
  if (DEBUG_ANALYTICS) console.log(message);
};

export const trackEvent = (
  eventLabel: ValueOf<typeof EVENT_LABELS>,
  eventProperties?: Record<string, unknown>
): void => {
  try {
    let loggingText = `Tracking event: "${eventLabel}"`;
    if (eventProperties) {
      loggingText += ` with properties ${JSON.stringify(eventProperties)}`;
    }
    logAnalytics(loggingText);
    analytics.track(eventLabel, eventProperties);
  } catch (e) {
    logAnalyticsError(e as Error);
  }
};

export const trackPage = (): void => {
  try {
    logAnalytics(`Page view: ${window.location}`);
    window.analytics.page();
  } catch (e) {
    logAnalyticsError(e as Error);
  }
};

export type UserForIdentifying = NonNullable<
  IdentifyUserDataQuery['currentUser']
>;

const identifyUserForSentry = (user: UserForIdentifying) => {
  Sentry.setUser({ email: user.email, id: user.id });
};

export const identifyUser = (user: UserForIdentifying): void => {
  try {
    logAnalytics(`Identifying user: ${user.id}`);
    window.analytics.identify(`vistra-${user.id}`, {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
    });
    identifyUserForSentry(user);
  } catch (e) {
    logAnalyticsError(e as Error);
  }
};

export const resetTracking = (): void => {
  try {
    logAnalytics(`Resetting tracking`);
    clearUserForErrorReporting();
    window.analytics.reset();
  } catch (e) {
    logAnalyticsError(e as Error);
  }
};

export const captureError = ({
  error,
  extras = {},
}: {
  error: Error;
  extras?: Record<string, unknown>;
}): void => {
  Sentry.withScope(scope => {
    scope.setExtras({ ...extras });
    const errorMessage = error?.message;
    scope.setFingerprint([errorMessage]);
    Sentry.captureException(error);
  });
};

// Note: Typing these all out for readability/documentation
/* eslint-disable sort-keys */
export const EVENT_LABELS = {
  ENTER_FUNNEL: 'Enter funnel',
  SIGNUP_STEP_UTILITY_SELECT: 'Signup step: utility-select',
  SIGNUP_STEP_PLAN_SELECTION: 'Signup step: plan-selection',
  SIGNUP_STEP_CREATE_ACCOUNT: 'Signup step: create-account',
  SIGNUP_STEP_CONNECT_ARCADIA: 'Signup step: connect-arcadia',
  SIGNUP_STEP_PAYMENT: 'Signup step: payment',
  SIGNUP_STEP_REVIEW: 'Signup step: review',
  SIGNUP_STEP_WELCOME: 'Signup step: welcome',
  SIGNUP_SUBMIT_UTILITY_SELECT: 'Signup submit: utility-select',
  SIGNUP_SUBMIT_PLAN_SELECTION: 'Signup submit: plan-selection',
  SIGNUP_ZIP_CODE_ENTRY: 'Signup action: Zip code entry',
  SIGNUP_UTILITY_SELECTED: 'Signup action: Utility selected',
  UTILITY_CREDENTIALS_STATUS_UPDATED: 'Utility credentials status updated',
} as const;
/* elint-enable sort-keys */
