import { useEffect, useState } from 'react';
import { AppleSignInButton } from 'components/signup/forms/apple-signin/apple-signin-button.component';
import jwt from 'jwt-decode';
import { Page } from 'styles';
import { useHistory } from 'react-router';
import {
  ActionWrapper,
  Form,
  GridContainer,
  PrimaryButton,
  PrimaryGrid,
  Title,
} from 'styles/simple-grid.style';
import { EmailConfirmationModal } from 'components/dashboard-profile-email-confirmation-modal';
import { Loading } from 'components/signup/loading';
import { PasswordInput } from 'components/password-input';
import { Input, Alert, Text, Link } from '@arcadiapower/shrike';
import { useApolloClient, useMutation } from '@apollo/client';
import { useQueryParams } from 'hooks/use-query-params.hook';
import { NavBarSimple } from 'components/nav-bar-simple';
import { copyFor } from 'config/copy';
import {
  dashboardProfile,
  signup,
  forgotPassword,
  loginRoot,
} from 'config/routes';
import {
  GoogleLogin,
  GoogleOAuthProvider,
  CredentialResponse,
} from '@react-oauth/google';
import { isGoogleAuthActivated } from 'utilities';
import {
  APPLE_LOGIN_REDIRECT_URI,
  APPLE_SIGNIN_ENABLED,
} from 'config/constants';
import { LOGIN, LOGIN_GOOGLE, EXTERNAL_LOGIN } from './login-email.api';
import { ExternalLoginContainer } from './login-email.style';

type AppleJWT = {
  authTime: number;
  cHash: string;
  email: string;
  emailVerified: boolean;
  exp: number;
  iat: number;
  isPrivateEmail: boolean;
  iss: string;
  sub: string;
  nonceSupported: boolean;
};

const getCopy = copyFor('login.loginEmail');

export const LoginEmail = (): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const queryParams = useQueryParams();

  const [externalLoginLoading, setExternalLoginLoading] =
    useState<boolean>(false);
  const [externalSignInCode, setSignInCode] = useState<string>('');

  const externalSignIn = queryParams.get('externalSignIn');
  const externalSignInToken = queryParams.get('signInToken');

  const isExpired = !!queryParams.get('expired');
  const emailConfirmationToken = queryParams.get('token');

  const [errorString, setErrorString] = useState<string | null>(() =>
    isExpired ? getCopy('errors.expired') : null
  );

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailConfirmationModalOpen, setEmailConfirmationModalOpen] =
    useState<boolean>(true);

  const client = useApolloClient();
  const googleAuthActivated = isGoogleAuthActivated();

  const [login] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN);
  const [externalLogin] = useMutation<
    ExternalLoginMutation,
    ExternalLoginMutationVariables
  >(EXTERNAL_LOGIN);

  const [loginGoogle] = useMutation<
    LoginGoogleMutation,
    LoginGoogleMutationVariables
  >(LOGIN_GOOGLE);

  const canSubmit = !!(email && password);

  const handleSuccessfulResponse = async (
    loggedIn: boolean,
    setupComplete: boolean
  ) => {
    if (loggedIn) {
      // Reset store because unautheticated users have a null currentUser
      await client.resetStore();
      const routingDestination = setupComplete ? dashboardProfile : signup;
      history.push(routingDestination);
    } else {
      setErrorString(getCopy('errors.invalidCredentials'));
    }
  };

  useEffect(() => {
    async function loginWithExternalEmail() {
      if (externalSignIn && externalSignInCode && email) {
        setErrorString(null);
        try {
          const { data } = await externalLogin({
            variables: {
              input: {
                email,
                signInCode: externalSignInCode,
                source: externalSignIn,
              },
            },
          });
          const loggedIn = data?.externalLogin?.success;
          if (loggedIn) {
            // Reset store because unautheticated users have a null currentUser
            await client.resetStore();
            const routingDestination = data?.externalLogin?.user
              ?.accountSetupCompleted
              ? dashboardProfile
              : signup;
            history.push(routingDestination);
            return;
          } else {
            setExternalLoginLoading(false);
            history.push(loginRoot);
          }
        } catch (e) {
          setExternalLoginLoading(false);
          history.push(loginRoot);
        }
      }
    }
    if (externalSignInToken) {
      try {
        setExternalLoginLoading(true);
        const decodedToken: AppleJWT = jwt(externalSignInToken);

        setEmail(decodedToken.email);
        setSignInCode(decodedToken.sub);

        loginWithExternalEmail();
      } catch (e) {
        setExternalLoginLoading(false);
        history.push(loginRoot);
      }
    }
  }, [
    client,
    email,
    externalLogin,
    externalSignIn,
    externalSignInCode,
    externalSignInToken,
    history,
  ]);

  const handleSubmit = async () => {
    setLoading(true);
    setErrorString(null);

    try {
      const { data } = await login({
        variables: { input: { email, password } },
      });
      const loggedIn = !!data?.login?.success;
      const setupComplete = !!data?.login?.user?.accountSetupCompleted;
      handleSuccessfulResponse(loggedIn, setupComplete);
    } catch (e) {
      setErrorString(getCopy('errors.generic'));
    }
    setLoading(false);
  };

  const handleGoogleSuccess = async (response: CredentialResponse) => {
    setLoading(true);
    setErrorString(null);
    try {
      const { data } = await loginGoogle({
        variables: { input: { token: response.credential || '' } },
      });
      const loggedIn = !!data?.loginGoogle?.success;
      const setupComplete = !!data?.loginGoogle?.user?.accountSetupCompleted;
      handleSuccessfulResponse(loggedIn, setupComplete);
    } catch (error) {
      setErrorString(getCopy('errors.generic'));
    }
    setLoading(false);
  };

  if (externalLoginLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <NavBarSimple />
      {emailConfirmationModalOpen && !!emailConfirmationToken && (
        <EmailConfirmationModal
          location="login"
          onClose={() => setEmailConfirmationModalOpen(false)}
          token={emailConfirmationToken}
        />
      )}
      <GridContainer>
        <PrimaryGrid>
          <Title>{getCopy('title')}</Title>
          <ExternalLoginContainer>
            {googleAuthActivated && (
              <GoogleOAuthProvider
                clientId={process.env.ARCADIA_GOOGLE_CLIENT_ID || ''}
              >
                <GoogleLogin
                  text="signin_with"
                  theme="filled_blue"
                  width="100%"
                  onSuccess={handleGoogleSuccess}
                  onError={() => {
                    setErrorString(getCopy('errors.generic'));
                  }}
                />
              </GoogleOAuthProvider>
            )}
            {APPLE_SIGNIN_ENABLED && (
              <AppleSignInButton
                buttonCopy={getCopy('appleSignin')}
                redirectURI={APPLE_LOGIN_REDIRECT_URI}
              />
            )}
          </ExternalLoginContainer>
          <Form onSubmit={handleSubmit}>
            <Input
              onChange={setEmail}
              name={getCopy('inputs.email.name')}
              label={getCopy('inputs.email.label')}
              placeholder={getCopy('inputs.email.placeholder')}
              value={email}
              margin={{ bottom: '24px', top: '24px' }}
            />
            <PasswordInput
              onChange={setPassword}
              name={getCopy('inputs.password.name')}
              label={getCopy('inputs.password.label')}
              placeholder={getCopy('inputs.password.placeholder')}
              value={password}
              margin={{ bottom: '24px' }}
            />
            {errorString && <Alert>{errorString}</Alert>}
            <ActionWrapper>
              <PrimaryButton
                loading={loading}
                disabled={!canSubmit}
                type="submit"
              >
                {getCopy('cta')}
              </PrimaryButton>
            </ActionWrapper>
            <ActionWrapper>
              <Link to={forgotPassword} hideIcon>
                <Text>{getCopy('forgotPwd')}</Text>
              </Link>
            </ActionWrapper>
          </Form>
        </PrimaryGrid>
      </GridContainer>
    </Page>
  );
};
