import billActiveIcon from 'assets/nav/bill-active.svg';
import billIcon from 'assets/nav/bill.svg';
import profileActiveIcon from 'assets/nav/profile-active.svg';
import profileIcon from 'assets/nav/profile.svg';
import usageActiveIcon from 'assets/nav/usage-active.svg';
import usageIcon from 'assets/nav/usage.svg';
import enrollmentActiveIcon from 'assets/nav/enrollment-active.svg';
import enrollmentInactiveIcon from 'assets/nav/enrollment-inactive.svg';
import { useRouteMatch } from 'react-router-dom';

import {
  dashboardUsage,
  dashboardBill,
  dashboardProfile,
  dashboardEnrollment,
} from 'config/routes';
import {
  NavButton,
  NavText,
  StyledLink,
  NotificationIconContainer,
} from './dashboard-nav-button.style';

const iconsMap = {
  [dashboardBill]: {
    active: billActiveIcon,
    regular: billIcon,
  },
  [dashboardEnrollment]: {
    active: enrollmentActiveIcon,
    regular: enrollmentInactiveIcon,
  },
  [dashboardProfile]: {
    active: profileActiveIcon,
    regular: profileIcon,
  },
  [dashboardUsage]: {
    active: usageActiveIcon,
    regular: usageIcon,
  },
};

const paths = [
  dashboardUsage,
  dashboardBill,
  dashboardProfile,
  dashboardEnrollment,
] as const;
export type PathType = typeof paths[number];

export type Props = {
  label: string;
  path: PathType;
  notificationBadge: boolean;
  disabled?: boolean;
};

export const DashboardNavButton = ({
  label,
  path,
  notificationBadge,
  disabled,
}: Props): JSX.Element => {
  const selected = !!useRouteMatch(path);
  const icon = selected ? iconsMap[path]?.active : iconsMap[path].regular;

  return (
    <StyledLink
      to={disabled ? '#' : path}
      data-testid={`nav-button-link-${path}`}
    >
      <NavButton
        selected={selected}
        disabled={disabled}
        data-testid={`nav-button-${path}`}
      >
        <NotificationIconContainer notificationBadge={notificationBadge}>
          <img src={icon} alt={path} />
        </NotificationIconContainer>
        <NavText selected={selected}>{label}</NavText>
      </NavButton>
    </StyledLink>
  );
};
