import { gql } from '@apollo/client';

export const GET_CONNECT_TOKEN = gql`
  query getConnectToken($utilityCredentialId: Int) {
    connectToken(utilityCredentialId: $utilityCredentialId)
  }
`;

export const SAVE_UTILITY_CREDENTIAL_ID = gql`
  mutation updateUtilityCredentialId($input: UpdateUtilityCredentialIdInput!) {
    updateUtilityCredentialId(input: $input) {
      user {
        id
        arcadiaUtilityCredentialId
        arcadiaUtilityCredential {
          id
          verificationStatus
        }
      }
    }
  }
`;
