import { statementDetail, profilePayment } from 'config/routes';
import { Text, Button, Icon } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import {
  formatCurrencyFromCents,
  parseDynamicRoute,
  formatDateRange,
} from 'utilities';
import { useHistory } from 'react-router-dom';
import {
  getPaymentMethodStringFromInvoice,
  invoiceDeclinedAndRequiresAction,
  isArcadiaInvoiceDeclined,
  paymentDateString,
  splitLineItems,
} from 'utilities/invoice';
import { EnergySkeleton } from '../usage/energy-skeleton';

import {
  TableWrapper,
  TableRow,
  Wrapper,
  LoadingWrapper,
  CenteredText,
  StatementDetailContainer,
  StatementDetailItem,
  PaymentDate,
  StubText,
  StubTextWrapper,
  UnderlinedText,
} from './latest-statement.style';

const getCopy = copyFor('bill');

type StatementType =
  GetLatestBrightenStatementQuery['listBrightenStatements']['statements'][0];

export type Props = {
  paymentMethods?: FullPaymentMethodFragment[];
  statement?: StatementType;
  queryError: boolean;
  loading: boolean;
};

export const LatestStatement = ({
  statement,
  paymentMethods,
  queryError,
  loading,
}: Props): JSX.Element => {
  const history = useHistory();

  if (loading) {
    return (
      <LoadingWrapper>
        <EnergySkeleton />
      </LoadingWrapper>
    );
  }

  if (queryError || !paymentMethods) {
    return (
      <Wrapper marginMobile="0 0 1rem 0">
        <Text textStyle="heading900">
          {getCopy('latestStatement.latestStatement')}
        </Text>
        <div style={{ margin: '1rem 0', textAlign: 'center' }}>
          <Icon
            icon="ExclamationCircleInverted"
            margin={{ top: '1rem' }}
            scale={4}
          />
        </div>
        <CenteredText
          textStyle="paragraph400"
          margin={{ left: '2rem', right: '2rem' }}
        >
          {getCopy('error.general')}
        </CenteredText>
      </Wrapper>
    );
  }

  const arcadiaInvoice = statement?.arcadiaInvoice;

  if (!statement || !arcadiaInvoice) {
    return (
      <Wrapper marginMobile="0 0 1rem 0">
        <Text textStyle="heading900">
          {getCopy('latestStatement.latestStatement')}
        </Text>
        <CenteredText
          margin={{ bottom: '1rem', left: '20%', right: '20%', top: '2rem' }}
        >
          {getCopy('noStatement')}
        </CenteredText>
      </Wrapper>
    );
  }

  const handleButtonClick = () => {
    history.push(
      parseDynamicRoute(statementDetail, {
        id: statement.id,
      })
    );
  };

  const { seasonPassDiscount } = splitLineItems(arcadiaInvoice.lineItems);
  // If the line item does not exist the discount is 0 and should not be shown
  const seasonPassDiscountCents = seasonPassDiscount?.amountCents || 0;
  // Season pass discount should be subtracted from the total on the BE, so
  // we can ensure the math adds up
  const currentChargesCents = arcadiaInvoice.total - seasonPassDiscountCents;

  // Show the manage payments button when the invoice is declined and the last
  // attempt to charge matches the default payment method
  const showManagePaymentsButton = invoiceDeclinedAndRequiresAction(
    arcadiaInvoice,
    paymentMethods
  );
  const isStubBill = !!statement?.stubBill;

  return (
    <Wrapper data-testid="latest-statement-wrapper">
      <Text textStyle="heading800">
        {getCopy('latestStatement.latestStatement')}
      </Text>
      <Text textStyle="heading1000">
        {formatCurrencyFromCents(arcadiaInvoice.total)}
      </Text>
      <Text textStyle="heading600" margin={{ bottom: '0', top: '2rem' }}>
        {getCopy('latestStatement.billingPeriod')}
      </Text>
      <Text textStyle="paragraph600" data-testid="billingPeriod">
        {formatDateRange(statement)}
      </Text>
      <StatementDetailContainer>
        <StatementDetailItem>
          <Text textStyle="heading600" margin={{ bottom: '0', top: '2rem' }}>
            {getCopy('latestStatement.paymentDate')}
          </Text>
          <PaymentDate
            data-testid="paymentDate"
            textStyle="paragraph600"
            className={
              isArcadiaInvoiceDeclined(arcadiaInvoice) ? 'declined' : ''
            }
          >
            {paymentDateString(arcadiaInvoice)}
          </PaymentDate>
        </StatementDetailItem>
        <StatementDetailItem>
          <Text margin={{ bottom: '0', top: '1rem' }} textStyle="heading600">
            {getCopy('latestStatement.paymentMethod')}
          </Text>
          <Text textStyle="paragraph600" data-testid="paymentMethod">
            {getPaymentMethodStringFromInvoice(arcadiaInvoice, paymentMethods)}
          </Text>
        </StatementDetailItem>
      </StatementDetailContainer>

      {showManagePaymentsButton && (
        <Button
          color="error"
          onClick={() => history.push(profilePayment)}
          fullWidth
          margin={{ top: '1.5rem' }}
        >
          {getCopy('latestStatement.managePaymentMethods')}
        </Button>
      )}

      <TableWrapper>
        <TableRow data-testid="currentCharges">
          <Text textStyle="paragraph600">
            {getCopy('latestStatement.electricityCharge')}
          </Text>
          <UnderlinedText textStyle="heading600">
            {formatCurrencyFromCents(currentChargesCents)}
          </UnderlinedText>
        </TableRow>
        {seasonPassDiscountCents < 0 && (
          <TableRow data-testid="seasonPassDiscount">
            <Text textStyle="paragraph600">
              {getCopy('latestStatement.seasonPass')}
            </Text>
            <UnderlinedText textStyle="heading600">
              {formatCurrencyFromCents(seasonPassDiscountCents)}
            </UnderlinedText>
          </TableRow>
        )}
        <TableRow data-testid="billTotal">
          <Text textStyle="paragraph600">
            {getCopy('latestStatement.total')}
          </Text>
          <Text textStyle="heading600">
            {formatCurrencyFromCents(arcadiaInvoice.total)}
          </Text>
        </TableRow>
      </TableWrapper>
      {isStubBill && (
        <StubTextWrapper marginMobile="1rem 6rem 2rem 6rem">
          <StubText>
            {getCopy('latestStatement.stubBillSeasonPassMessage')}
          </StubText>
        </StubTextWrapper>
      )}
      <Button
        fullWidth
        backgroundColor="primaryInverse"
        onClick={handleButtonClick}
        backgroundless
      >
        {getCopy('latestStatement.fullDetails')}
      </Button>
    </Wrapper>
  );
};
