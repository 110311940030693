import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useSignupFunnel } from 'hooks/use-signup-funnel.hook';
import store from 'store';
import { SignupStepType } from 'config/signup-funnel';
import {
  STORE_KEY_UTILITY,
  STORE_KEY_ZIP,
  STORE_KEY_BRIGHTEN_PLAN_ID,
  STORE_SIGNUP_MONTH,
} from 'config/constants';
import { useHistory } from 'react-router-dom';
import { EVENT_LABELS, trackEvent } from 'utilities/analytics';
import { PartialPaymentMethodFragment } from 'utilities/payment-method';
import { getResumingData } from './signup.util';
import { GET_SIGNUP_USER } from './signup.api';

export type Props = {
  children?: ReactNode;
};

export interface SignupContext {
  authenticated: boolean;
  currentStep: SignupStepType;
  next: () => void;
  initializing: boolean;
  initializationError: Error | undefined;
  setAuthenticated: (authenticated: boolean) => void;
  setLocalUtility: (utility: ArcadiaUtility) => void;
  setLocalZip: (zip: string) => void;
  setLocalPayment: (payment?: PartialPaymentMethodFragment) => void;
  localPayment?: PartialPaymentMethodFragment;
  brightenPlanId?: Maybe<number>;
  setBrightenPlanId: (brightenPlanId: number) => void;
  utility: Maybe<ArcadiaUtility> | undefined;
  user?: GetSignupUserQuery['currentUser'];
  zip: Maybe<string> | undefined;
}

export interface AuthenticatedSignupContext extends SignupContext {
  user: NonNullable<GetSignupUserQuery['currentUser']>;
}

export const Signup = createContext<SignupContext>({} as SignupContext);

export const SignupProvider = ({ children }: Props): JSX.Element => {
  const history = useHistory();
  const apolloClient = useApolloClient();

  const [authenticated, setAuthenticated] = useState<boolean>(false);

  // State that we keep track of before we "create" the user
  const [localUtility, setLocalUtility] = useState<ArcadiaUtility>();
  const [localZip, setLocalZip] = useState<string>();
  const [localBrightenPlanId, setBrightenPlanId] = useState<number>();
  const [localPayment, setLocalPayment] =
    useState<PartialPaymentMethodFragment>();

  // State that has to do with initialization
  const [initializing, setInitializing] = useState<boolean>(true);
  const [initializationError, setInitializationError] = useState<Error>();

  // Get current user object after initialization if authenticated
  const { data: signupUserData } = useQuery<
    GetSignupUserQuery,
    GetSignupUserQueryVariables
  >(GET_SIGNUP_USER, {
    onError: error => setInitializationError(error),
    skip: !authenticated,
  });

  const { currentStep, next, setStepByKey } = useSignupFunnel({
    user: signupUserData?.currentUser,
  });

  useEffect(() => {
    const initializeUser = async () => {
      try {
        setInitializing(true);
        const { authenticated, localValues, route } = await getResumingData({
          apolloClient,
        });
        if (authenticated) {
          setAuthenticated(authenticated);
        } else if (localValues) {
          setLocalUtility(localValues.utility);
          setLocalZip(localValues.zip);
          setBrightenPlanId(localValues.brightenPlanId);
        }
        setStepByKey(route);
      } catch (e) {
        setInitializationError(e as Error);
      } finally {
        setInitializing(false);
      }
    };

    initializeUser();
  }, [apolloClient, setStepByKey]);

  // Alias local values through currentUser if it's avaiable so we can
  // access them directly when making copy
  const localValues = useMemo(() => {
    const currentUser = signupUserData?.currentUser;
    if (currentUser) {
      return {
        brightenPlanId: currentUser.brightenPlanId,
        utility: currentUser.utility,
        zip: currentUser.zipCode,
      };
    } else {
      return {
        brightenPlanId: localBrightenPlanId,
        utility: localUtility,
        zip: localZip,
      };
    }
  }, [signupUserData, localUtility, localZip, localBrightenPlanId]);

  // Save utility and zip to the local store
  // so we can "reload" them on refresh
  useEffect(() => {
    const currentDate = new Date();
    if (localUtility || localZip || localBrightenPlanId)
      store.set(STORE_SIGNUP_MONTH, currentDate.getMonth() + 1);
    if (localUtility) store.set(STORE_KEY_UTILITY, localUtility);
    if (localZip) store.set(STORE_KEY_ZIP, localZip);
    if (localBrightenPlanId)
      store.set(STORE_KEY_BRIGHTEN_PLAN_ID, localBrightenPlanId);
  }, [localUtility, localZip, localBrightenPlanId]);

  useEffect(() => {
    if (!initializing && !initializationError) {
      history.replace(`/signup/${currentStep.key}`);
      trackEvent(`Signup step: ${currentStep.key}`);
    }
  }, [currentStep, history, initializing, initializationError]);

  // Entered funnel on mount
  useEffect(() => {
    trackEvent(EVENT_LABELS.ENTER_FUNNEL);
  }, []);

  const value: SignupContext = useMemo(
    () => ({
      authenticated,
      currentStep,
      initializationError,
      initializing,
      localPayment,
      next,
      setAuthenticated,
      setBrightenPlanId,
      setLocalPayment,
      setLocalUtility,
      setLocalZip,
      user: signupUserData?.currentUser,
      ...localValues,
    }),
    [
      authenticated,
      currentStep,
      initializationError,
      initializing,
      localPayment,
      localValues,
      next,
      signupUserData?.currentUser,
    ]
  );
  return <Signup.Provider value={value}>{children}</Signup.Provider>;
};
