import { gql } from '@apollo/client';

export const SET_ACCOUNT_SETUP_COMPLETED = gql`
  mutation setAccountSetupCompleted($input: SetAccountSetupCompletedInput!) {
    setAccountSetupCompleted(input: $input) {
      user {
        id
        accountSetupCompleted
      }
    }
  }
`;
