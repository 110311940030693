import { ActionWrapper, PrimaryButton, Title } from 'styles/simple-grid.style';
import { Text } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';

const getCopy = copyFor('login.forgotPassword');

export type SubmittedEmailProps = {
  email: string;
  ctaAction: () => void;
};

export const SubmittedEmail: React.FC<SubmittedEmailProps> = ({
  email,
  ctaAction,
}) => {
  return (
    <>
      <Title>{getCopy('submitted.title')}</Title>
      <Text>{getCopy('submitted.description', { email })}</Text>
      <ActionWrapper>
        <PrimaryButton onClick={ctaAction}>
          {getCopy('submitted.cta')}
        </PrimaryButton>
      </ActionWrapper>
    </>
  );
};
