import styled from 'styled-components';
import { Radio } from '@arcadiapower/shrike';

export const ChangeRadio = styled(Radio)`
  width: 100%;
  align-items: center;

  & div {
    width: 100%;
  }
`;
export const RadioContainer = styled.div`
  padding: 0 5px;
`;
