import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text } from '@arcadiapower/shrike';

export const StyledLink = styled(Link)`
  display: flex;
  flex: 1;
  text-decoration: none;
  height: 100%;
`;

export const Wrapper = styled.div.attrs(
  (props: { marginMobile: string }) => props
)`
  background-color: white;
  padding: 2rem 1rem;
  ${props => props.theme.media.desktop`
    border: 1px solid ${props.theme.colors.accent3};
    border-radius: 8px;
    grid-area: top_right;
  `}
  ${props => props.theme.media.phoneAndTablet`
    margin: ${props.marginMobile};
  `}
`;

export const StatementDetailContainer = styled.div`
  ${props => props.theme.media.desktop`
    justify-content: flex-start;
  `}
  display: inline-flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  padding-right: 2rem;
`;

export const StatementDetailItem = styled.div`
  ${props => props.theme.media.desktop`
    margin-right: 2rem;
  `}
`;

export const LoadingWrapper = styled.div`
  background-color: white;
  ${props => props.theme.media.desktop`
    border-radius: 8px;
    grid-area: top_right;
  `}
  margin-bottom: 1rem;
`;

export const CenteredText = styled(Text)`
  text-align: center;
`;

export const StubTextWrapper = styled.div.attrs(
  (props: { marginMobile: string }) => props
)`
  display: flex;
  justify-content: center;
  margin: 1rem 0 2rem 0;
  ${props => props.theme.media.phoneAndTablet`
    margin: ${props.marginMobile};
  `}
`;

export const StubText = styled(Text)`
  text-align: center;
  color: ${props => props.theme.colors.accent2};
  min-width: 290px;
`;

export const TableWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.colors.accent3};
  width: 100%;
  margin: 2rem 0;
`;

export const TableRow = styled.div`
  &.linked:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.accent3};
  }

  border-bottom: 1px solid ${props => props.theme.colors.accent3};
  display: flex;
  justify-content: space-between;
  padding: 14px 3px;
`;

export const PaymentDate = styled(Text)`
  ${props =>
    props.className === 'declined' && `color: ${props.theme.colors.error}`}
`;

export const UnderlinedText = styled(Text)`
  text-decoration: underline;
`;
