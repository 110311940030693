import { Text } from '@arcadiapower/shrike';

import { copyFor } from 'config/copy';

const getCopy = copyFor('enrollment.rejected');

export type Props = {
  user: { firstName: string };
};

export const RejectedState = ({ user }: Props): JSX.Element => {
  return (
    <div data-testid="enrollmentRejected">
      <Text
        color="secondary"
        textStyle="heading900"
        margin={{ bottom: '2rem' }}
      >
        {getCopy('title', { firstName: user.firstName })}
      </Text>
      <Text color="secondary" textStyle="paragraph500">
        {getCopy('subtitle')}
      </Text>
    </div>
  );
};
