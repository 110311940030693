import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  text-align: center;
  ${props => props.theme.media.desktop`
    height: 100%;
    padding-top: 72px;
    `};
  ${props => props.theme.media.phoneAndTablet`
    padding-bottom: 72px;
    `};
`;
