import {
  derivePaymentDate,
  isArcadiaInvoiceDeclined,
  isArcadiaInvoicePaid,
  splitLineItems,
} from 'utilities/invoice';
import { copyFor } from 'config/copy';
import { bill as billCopyDictionary } from 'config/copy/bill';
import { formatDate } from '@arcadiapower/warbler';
import { formatCurrencyFromCents } from 'utilities';

const getCopy = copyFor('bill.header');

type ArcadiaInvoice =
  GetLatestBrightenStatementQuery['listBrightenStatements']['statements'][0]['arcadiaInvoice'];

export type HeaderType = keyof (typeof billCopyDictionary)['header'];

export const getHeaderText = (
  arcadiaInvoice: ArcadiaInvoice,
  isStubBill: boolean,
  accountIsRemoved: boolean
): null | { title: string; content: string } => {
  if (accountIsRemoved) {
    return {
      content: getCopy('removedAccount.content'),
      title: getCopy('removedAccount.title'),
    };
  }
  if (!arcadiaInvoice || isArcadiaInvoiceDeclined(arcadiaInvoice)) return null;

  const { seasonPassDiscount } = splitLineItems(arcadiaInvoice.lineItems);
  const hasSeasonPassDiscount = !!seasonPassDiscount;
  let paymentDate = derivePaymentDate(arcadiaInvoice);
  let headerType: HeaderType;

  if (isArcadiaInvoicePaid(arcadiaInvoice)) {
    headerType = 'paidWithoutSavings';

    if (hasSeasonPassDiscount) {
      headerType = 'paidWithSavings';
    }
    if (isStubBill) {
      headerType = 'paidStub';
    }
  } else {
    const DAYS_TO_COLLECT_INVOICES = 3;
    headerType = 'pending';

    // Determine the day the user will be charged
    // Charge will be made 3 days after invoice, if it's older than 3 days it will be tomorrow.

    // Default the paymentDate to tomorrow
    paymentDate = new Date(new Date().setDate(new Date().getDate() + 1));

    const chargeDate = new Date(arcadiaInvoice.issuedAt);
    chargeDate.setDate(chargeDate.getDate() + DAYS_TO_COLLECT_INVOICES);
    if (chargeDate > new Date()) paymentDate = chargeDate;
  }

  return {
    content: getCopy(`${headerType}.content`, {
      date: formatDate(paymentDate, { format: 'MMMM d' }),
      total: formatCurrencyFromCents(arcadiaInvoice.total),
    }),
    title: getCopy(`${headerType}.title`),
  };
};
