import styled from 'styled-components';
import { Text, Link, Button } from '@arcadiapower/shrike';

export const ErrorText = styled(Text)`
  color: ${props => props.theme.colors.error};
`;

export const IconContainer = styled.div`
  margin-right: 1rem;
`;

export const MessageContainer = styled.div`
  padding-right: 1rem;
  margin-bottom: 1rem;
`;

export const NotificationContainer = styled.div<{ inMenu: boolean }>`
  ${props => props.inMenu && 'border-radius: 8px 8px 0 0;'}
  background-color: ${props => props.theme.colors.errorBackground};
  padding: 2rem 1rem;
`;

export const StyledLink = styled(Link)`
  display: block;
  margin-top: 1rem;
  text-align: start;
`;

export const StyledButton = styled(Button)`
  display: block;
  margin-top: 1rem;
  text-align: start;
`;

export const ContentContainer = styled.div<{ inMenu: boolean }>`
  display: flex;
  align-items: baseline;
  ${props =>
    !props.inMenu &&
    props.theme.media.desktop`
    max-width: 1200px;
    padding: 0 1.5rem;
    margin: 0 auto;
  `}
`;
