import styled from 'styled-components';

export const Container = styled.div`
  ${props => props.theme.media.desktop`
    padding: 16px 48px;
  `};

  ${props => props.theme.media.phoneAndTablet`
    padding: 0;
  `};
`;
