import { useState } from 'react';
import visibleIcon from 'assets/components/visible.svg';
import hiddenIcon from 'assets/components/hidden.svg';
import {
  InputContainer,
  InputIcon,
  StyledInput,
  IconContainer,
  InputButton,
} from './password-input.style';

export type Props = {
  error?: string;
  label?: string;
  margin?: Record<string, unknown>;
  name: string;
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
};

export const PasswordInput = ({
  error = '',
  label = '',
  name,
  margin = {},
  onChange,
  placeholder = '',
  value = '',
}: Props): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputContainer>
      <IconContainer>
        <StyledInput
          data-recording-ignore="mask"
          data-testid="toggled-password-input"
          errorText={error}
          label={label}
          margin={margin}
          name={name}
          onChange={e => onChange(e)}
          placeholder={placeholder}
          type={showPassword ? 'text' : 'password'}
          value={value}
        />
        <InputButton
          type="button"
          aria-label="Reveal Password"
          onClick={toggleShowPassword}
        >
          <InputIcon
            src={showPassword ? visibleIcon : hiddenIcon}
            data-testid="password-toggler-icon"
            alt="show password"
          />
        </InputButton>
      </IconContainer>
    </InputContainer>
  );
};
