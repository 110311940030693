import styled from 'styled-components';

export const MainMenuContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 8px;
  margin: auto;
  padding: 1rem;

  ${props => props.theme.media.desktop`
    border: 1px solid ${props.theme.colors.accent3};
    max-width: 600px;
  `}
`;

export const ProfileMenuOptionsWrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 8px;
  margin: auto;
  padding: 1rem;

  ${props => props.theme.media.desktop`
    max-width: 600px;
  `}
`;

export const DashboardProfileMenuWrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 8px;
  margin: auto;

  ${props => props.theme.media.desktop`
    border: 1px solid ${props.theme.colors.accent3};
    max-width: 600px;
  `}
`;
