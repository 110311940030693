import styled from 'styled-components';

export const GraphWrapper = styled.div`
  padding: 0;
  & .apexcharts-marker {
    fill: ${props => props.theme.colors.accent1};
    stroke: ${props => props.theme.colors.accent1};
  }
  & .apexcharts-tooltip-marker {
    background-color: ${props => props.theme.colors.accent1} !important;
  }
  & .apexcharts-line {
    stroke: ${props => props.theme.colors.accent1};
  }
  & .apexcharts-subtitle-text {
    fill: ${props => props.theme.colors.accent2};
  }
  & .apexcharts-xaxistooltip {
    border-radius: 4px;
    background-color: ${props => props.theme.colors.primary};
    font-weight: bold;
    color: white;
    border: none;
  }
  & .apexcharts-xaxistooltip::before,
  & .apexcharts-xaxistooltip::after {
    content: none;
  }
`;
