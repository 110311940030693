import { useContext, useMemo, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { PrimaryButton, ActionWrapper, Form } from 'styles/simple-grid.style';
import { Input, Alert, Text } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { Signup, SignupContext } from 'contexts/signup';
import { PasswordInput } from 'components/password-input';
import {
  isValidPassword,
  isValidEmail,
  isGoogleAuthActivated,
} from 'utilities';
import { GET_SIGNUP_USER } from 'contexts/signup/signup.api';
import {
  GoogleLogin,
  GoogleOAuthProvider,
  CredentialResponse,
} from '@react-oauth/google';
import {
  APPLE_SINGUP_REDIRECT_URI,
  APPLE_SIGNIN_ENABLED,
} from 'config/constants';
import { CREATE_USER, CREATE_GOOGLE_USER } from './create-account-form.api';
import {
  CenteredText,
  PasswordNotice,
  HelpContainer,
  InfoLink,
  ExternalLoginContainer,
} from './create-account-form.style';
import { AppleSignInButton } from '../apple-signin/apple-signin-button.component';

export type Props = {
  className?: string;
};

const getCopy = copyFor('signup.forms.createAccount');

export const CreateAccountForm = ({ className }: Props): JSX.Element => {
  const [pending, setPending] = useState<boolean>(false);
  const [error, setError] = useState<undefined | ApolloError | boolean>();

  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [createUser] = useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CREATE_USER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_SIGNUP_USER }],
  });

  const [createGoogleUser] = useMutation<
    CreateGoogleUserMutation,
    CreateGoogleUserMutationVariables
  >(CREATE_GOOGLE_USER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_SIGNUP_USER }],
  });

  const { brightenPlanId, utility, setAuthenticated, zip, next } =
    useContext<SignupContext>(Signup);

  const disabled = !!(
    isValidEmail(email) &&
    lastName &&
    firstName &&
    isValidPassword(password)
  );

  const generalTerms = `${process.env.PUBLIC_URL}/contracts/Terms_of_Service.pdf`;
  const privacyPolicy = `${process.env.PUBLIC_URL}/contracts/Privacy_Policy.pdf`;
  const googleAuthActivated = isGoogleAuthActivated();

  const handleSubmit = async () => {
    if (isValidPassword(password) && isValidEmail(email)) {
      setPending(true);
      try {
        await createUser({
          variables: {
            input: {
              brightenPlanId,
              email,
              firstName: firstName.trim(),
              lastName: lastName.trim(),
              password,
              utilityId: (utility as NonNullable<SignupContext['utility']>).id,
              zipCode: zip as NonNullable<SignupContext['zip']>,
            },
          },
        });
        setAuthenticated(true);
        next();
      } catch (error) {
        setPending(false);
        setError(error as ApolloError);
      }
    }
  };

  const alertText = useMemo(() => {
    if (error) return getCopy('errors.generic');
  }, [error]);

  const parsePasswordError = (password: string) => {
    if (!password || pending) return;
    if (!isValidPassword(password)) {
      return getCopy('errors.invalidPassword');
    }
    return getCopy('errors.noError');
  };

  const parseEmailError = (email: string, error: ApolloError | undefined) => {
    if (!email || pending) return;
    if (!isValidEmail(email)) {
      return getCopy('errors.invalidEmail');
    }
    const errorMessage = error?.message;
    if (errorMessage?.includes('Email has already been taken')) {
      return getCopy('errors.takenEmail');
    }
    return getCopy('errors.noError');
  };

  const responseGoogle = async (response: CredentialResponse) => {
    setPending(true);
    try {
      await createGoogleUser({
        variables: {
          input: {
            brightenPlanId,
            token: response.credential || '',
            utilityId: (utility as NonNullable<SignupContext['utility']>).id,
            zipCode: zip as NonNullable<SignupContext['zip']>,
          },
        },
      });
      setAuthenticated(true);
      next();
    } catch (error) {
      setPending(false);
      setError(error as ApolloError);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <ExternalLoginContainer>
        {googleAuthActivated && (
          <GoogleOAuthProvider
            clientId={process.env.ARCADIA_GOOGLE_CLIENT_ID || ''}
          >
            <GoogleLogin
              text="signup_with"
              theme="filled_blue"
              width="100%"
              onSuccess={responseGoogle}
              onError={() => {
                setError(true);
              }}
            />
          </GoogleOAuthProvider>
        )}
        {APPLE_SIGNIN_ENABLED && (
          <AppleSignInButton
            buttonCopy={getCopy('appleSignin')}
            redirectURI={APPLE_SINGUP_REDIRECT_URI}
          />
        )}
      </ExternalLoginContainer>
      <Input
        name={getCopy('inputs.firstName.name')}
        label={getCopy('inputs.firstName.label')}
        placeholder={getCopy('inputs.firstName.placeholder')}
        onChange={setFirstName}
        value={firstName}
        margin={{ bottom: '24px' }}
      />

      <Input
        name={getCopy('inputs.lastName.name')}
        label={getCopy('inputs.lastName.label')}
        placeholder={getCopy('inputs.lastName.placeholder')}
        onChange={setLastName}
        value={lastName}
        margin={{ bottom: '24px' }}
      />

      <Input
        name={getCopy('inputs.email.name')}
        label={getCopy('inputs.email.label')}
        placeholder={getCopy('inputs.email.placeholder')}
        onChange={setEmail}
        value={email}
        errorText={parseEmailError(email, error as ApolloError)}
        margin={{ bottom: '24px' }}
      />

      <PasswordInput
        name={getCopy('inputs.password.name')}
        label={getCopy('inputs.password.label')}
        placeholder={getCopy('inputs.password.placeholder')}
        onChange={setPassword}
        value={password}
        error={parsePasswordError(password)}
      />

      <PasswordNotice textStyle="paragraph400">
        {getCopy('passwordInfo')}
      </PasswordNotice>
      {alertText && <Alert>{alertText}</Alert>}

      <CenteredText margin={{ top: '2rem' }} textStyle="paragraph400">
        {getCopy('termsStart')}
        <InfoLink href={generalTerms} openInNewTab={true} type="inline">
          {getCopy('terms')}
        </InfoLink>
        {getCopy('termsAnd')}
        <InfoLink href={privacyPolicy} openInNewTab={true} type="inline">
          {getCopy('privacyPolicy')}
        </InfoLink>
        {getCopy('termsEnd')}
      </CenteredText>
      <ActionWrapper>
        <PrimaryButton disabled={!disabled} loading={pending} type="submit">
          {getCopy('cta')}
        </PrimaryButton>
      </ActionWrapper>

      <HelpContainer>
        <Text>{getCopy('help')}</Text>
        <Text>{getCopy('contact')}</Text>
        <Text>{getCopy('schedule')}</Text>
      </HelpContainer>
    </Form>
  );
};
