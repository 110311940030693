import { useCallback, useEffect, useMemo, useState } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { PrimaryButton, ActionWrapper, Form } from 'styles/simple-grid.style';
import { Input, Text } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { EVENT_LABELS, trackEvent } from 'utilities/analytics';
import { Select, ContactInfoWrapper } from './utility-select-form.style';
import { GET_UTILITIES_BY_ZIP } from './utility-select-form.api';
import {
  mapUtilitiesDataToOptions,
  isValidZipCode,
  partitionSupportedUtilities,
} from './utility-select-form.util';

export type Props = {
  className?: string;
  onSubmit: ({
    utility,
    zip,
  }: {
    utility: ArcadiaUtility;
    zip: string;
  }) => void;
};

const getCopy = copyFor('signup.forms.utilitySelect');

export const UtilitySelectForm = ({
  onSubmit,
  className,
}: Props): JSX.Element => {
  const [zip, setLocalZip] = useState<string>('');
  const [typed, setTyped] = useState(false);
  const [zipHasSupportedUtilities, setZipHasSupportedUtilities] =
    useState(false);
  const [utility, setLocalUtility] = useState<ArcadiaUtility | null>(null);

  const validZip = isValidZipCode(zip);

  const [fetchUtilities, { data, loading, error }] = useLazyQuery<
    GetUtilitiesByZipQuery,
    GetUtilitiesByZipQueryVariables
  >(GET_UTILITIES_BY_ZIP);

  const allUtilities = useMemo(() => {
    if (data) {
      const { recommendedByZip, recommendedByState } = data.utilitiesByZip;
      return [...recommendedByZip, ...recommendedByState];
    }
  }, [data]);

  const handleUtilityChange = (id: string) => {
    const newUtility = allUtilities?.find(utility => String(utility.id) === id);
    setLocalUtility(newUtility as ArcadiaUtility);
  };

  const handleZipChange = (newZip: string) => {
    if (!typed) {
      trackEvent(EVENT_LABELS.SIGNUP_ZIP_CODE_ENTRY);
      setTyped(true);
    }
    setLocalZip(newZip);
  };

  useEffect(() => {
    // Fetch utilities once we have a valid zip
    if (validZip) {
      // Send only the first 5 chars, in case an extended zip was provided.
      fetchUtilities({ variables: { zip: zip.substring(0, 5) } });
      setZipHasSupportedUtilities(
        allUtilities?.some(utility => utility.supported) || false
      );
    }
  }, [validZip, zip, fetchUtilities, data, allUtilities]);

  useEffect(() => {
    if (!utility) return;

    // Clear utility if zip is invalid
    if (!validZip) {
      setLocalUtility(null);
      setZipHasSupportedUtilities(false);
    } else {
      trackEvent(EVENT_LABELS.SIGNUP_UTILITY_SELECTED, {
        utility: utility.name,
        zip,
      });
    }
  }, [utility, validZip, zip]);

  const canSubmit = !!(validZip && utility && utility.supported);

  const handleSubmit = useCallback(() => {
    // For unknown reasons typescript needs to "reconfirm" that utility is not
    // null here (canSubmit does not work)
    if (canSubmit && utility) onSubmit({ utility, zip });
  }, [onSubmit, canSubmit, utility, zip]);

  const getErrorMessage = (error: ApolloError | undefined) => {
    if (loading) return;

    let errorCopy = '';
    if (!zipHasSupportedUtilities && zip)
      errorCopy = getCopy('errors.notSupported');
    if (!validZip && zip) errorCopy = getCopy('errors.invalidZip');
    if (error) {
      const errorMessage = error.message;
      errorCopy = getCopy('errors.generic');
      if (errorMessage.includes('Zip code does not exist')) {
        errorCopy = getCopy('errors.invalidZip');
      }
    }

    if (errorCopy) return errorCopy;
  };

  useEffect(() => {
    // Once we get data - automatically select an utility if there's only one supported zip one.
    if (!data) return;

    const { recommendedByZip } = data.utilitiesByZip;
    const supportedRecommendedByZip = recommendedByZip.filter(u => u.supported);
    // Don't select anything if there's not only 1 supported utility
    if (supportedRecommendedByZip.length !== 1) return;

    // Select the supported utility
    if (supportedRecommendedByZip[0]) {
      setLocalUtility(supportedRecommendedByZip[0]);
    }
  }, [data]);

  const selectOptions = useMemo(() => {
    if (!validZip || !data || !zipHasSupportedUtilities)
      return { disabled: true };
    const { recommendedByZip, recommendedByState } = data.utilitiesByZip;

    const { supportedUtilities, unsupportedUtilities } =
      partitionSupportedUtilities(recommendedByState);

    return mapUtilitiesDataToOptions({
      generalOptions: unsupportedUtilities,
      recommendedOptions: [...recommendedByZip, ...supportedUtilities],
    });
  }, [validZip, data, zipHasSupportedUtilities]);

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <Input
        name={getCopy('inputs.zip.name')}
        label={getCopy('inputs.zip.label')}
        placeholder={getCopy('inputs.zip.placeholder')}
        onChange={handleZipChange}
        value={zip}
        errorText={getErrorMessage(error)}
        margin={{ bottom: '24px' }}
        type="tel" // using tel here instead of number to allow for the -'s that could be in extended zips
      />
      <Select
        {...selectOptions}
        value={utility?.id}
        name={getCopy('inputs.utility.name')}
        label={getCopy('inputs.utility.label')}
        onChange={handleUtilityChange}
        withEmptyPlaceholder={true}
        helperText={
          loading
            ? getCopy('inputs.utility.placeholderLoading')
            : getCopy('inputs.utility.placeholder')
        }
      />
      <ActionWrapper>
        <PrimaryButton disabled={!canSubmit} type="submit">
          {getCopy('cta')}
        </PrimaryButton>
      </ActionWrapper>

      <ContactInfoWrapper>
        <Text>{getCopy('contactInfo')}</Text>
        <Text>{getCopy('contactInfoLine2')}</Text>
        <Text margin={{ top: '40px' }} textStyle="paragraph400">
          {getCopy('offerDisclaimer')}
        </Text>
      </ContactInfoWrapper>
    </Form>
  );
};
