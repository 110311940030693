import { Text } from '@arcadiapower/shrike';
import { EnergySkeleton } from '../usage/energy-skeleton';
import { Wrapper, LoadingWrapper, TextContainer } from './dashboard-tips.style';

export type TipsProps = {
  className?: string;
  header?: string;
  content?: string;
  loading: boolean;
};

export const DashboardTips = ({
  className,
  content,
  header,
  loading,
}: TipsProps): JSX.Element => {
  if (loading) {
    return (
      <LoadingWrapper className={className}>
        <EnergySkeleton opacity="low" />
      </LoadingWrapper>
    );
  }
  return (
    <Wrapper className={className} data-testid="dashboard-tips">
      <TextContainer>
        <Text
          textStyle="heading800"
          color="primaryInverse"
          data-testid="tipsHeader"
        >
          {header}
        </Text>
        <Text color="primaryInverse">{content}</Text>
      </TextContainer>
    </Wrapper>
  );
};
