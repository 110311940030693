import styled from 'styled-components';
import { Input } from '@arcadiapower/shrike';

export const InputContainer = styled.div`
  width: 100%;
`;

export const StyledInput = styled(Input)`
  & input {
    max-width: 100%;
  }
  width: 100%;
  font-size: 20px;
  font-weight: 500;
`;

export const IconContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const InputButton = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  border: none;
  background: transparent;
  cursor: pointer;
  height: 48px;
`;

export const InputIcon = styled.img`
  color: rgb(49, 0, 128);
  height: 20px;
  width: 20px;
`;
