import { ApolloError, useQuery } from '@apollo/client';
import { createContext, useMemo } from 'react';
import { getDefaultPaymentMethod } from 'utilities/payment-method';
import { invoiceDeclinedAndRequiresAction } from 'utilities/invoice';
import { USER_ENROLLED_STATES } from 'config/constants';
import { shouldShowConnectNotification } from './notifications.util';
import { GET_NOTIFICATIONS_DATA } from './notifications.api';

export type Props = {
  children?: React.ReactNode;
  enrolledState: EnrollmentState;
};

export enum NotificationKeys {
  connect,
  paymentExpired,
  defaultPaymentMethodFailed,
  emailUnconfirmed,
}

export type NotificationsContext = {
  error?: ApolloError;
  loading: boolean;
  notifications: NotificationKeys[];
};

export const Notifications = createContext({} as NotificationsContext);

export const NotificationsProvider = ({
  children,
  enrolledState,
}: Props): JSX.Element => {
  const includeLatestStatement =
    enrolledState === USER_ENROLLED_STATES.ENROLLED;

  const { data, loading, error } = useQuery<
    GetNotificationsDataQuery,
    GetNotificationsDataQueryVariables
  >(GET_NOTIFICATIONS_DATA, { variables: { includeLatestStatement } });

  const notifications = useMemo(() => {
    const notificationsArray: NotificationKeys[] = [];

    const user = data?.currentUser;
    if (!user) return notificationsArray;

    if (shouldShowConnectNotification(user)) {
      notificationsArray.push(NotificationKeys.connect);
    }

    if (!user.emailConfirmed) {
      notificationsArray.push(NotificationKeys.emailUnconfirmed);
    }

    const defaultPaymentMethod = getDefaultPaymentMethod(user.paymentMethods);

    if (defaultPaymentMethod?.expired)
      notificationsArray.push(NotificationKeys.paymentExpired);

    const latestInvoice =
      data?.listBrightenStatements?.statements[0]?.arcadiaInvoice;
    if (
      latestInvoice &&
      invoiceDeclinedAndRequiresAction(latestInvoice, user.paymentMethods)
    ) {
      notificationsArray.push(NotificationKeys.defaultPaymentMethodFailed);
    }
    return notificationsArray;
  }, [data]);

  const value = useMemo(
    (): NotificationsContext => ({
      error,
      loading,
      notifications,
    }),
    [notifications, loading, error]
  );

  return (
    <Notifications.Provider value={value}>{children}</Notifications.Provider>
  );
};
