import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';
import { Link } from 'react-router-dom';

type ButtonProps = {
  selected?: boolean;
  disabled?: boolean;
};

type IconProps = {
  notificationBadge?: boolean;
};

export const StyledLink = styled(Link)`
  display: flex;
  flex: 1;
  text-decoration: none;
  height: 100%;
`;

export const NotificationIconContainer = styled.div<IconProps>`
  &::after {
    ${props =>
      props.notificationBadge &&
      `
    position: absolute;
    content: '';
    background-color: ${props.theme.colors.content.error};
    border-radius:50%;
    width: 8px;
    height: 8px;
    pointer-events: none;
    top: 10px;
  `}
  }
`;

export const NavButton = styled.button<ButtonProps>`
  border: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  background-color: white;
  opacity: 1;

  ${props =>
    props.disabled &&
    `
    background-color: rgba(245, 246, 247, 1);
    opacity: 0.5;
    cursor: not-allowed;
  `}

  ${props => props.theme.media.desktop`
    ${
      props.selected &&
      `border-bottom: 2px solid ${props.theme.colors.accent1};`
    }
  `};

  ${props => props.theme.media.phoneAndTablet`
    ${props.selected && `border-top: 2px solid ${props.theme.colors.accent1};`}
  `};
`;

type NavTextProps = {
  selected?: boolean;
};

export const NavText = styled(Text)<NavTextProps>`
  font-size: 12px;
  font-weight: 600;
  color: ${props => (props.selected ? props.theme.colors.accent1 : 'default')};
`;
