import { gql } from '@apollo/client';

export const UPDATE_USER_NAMES = gql`
  mutation updateUserNames($input: UpdateUserNamesInput!) {
    updateUserNames(input: $input) {
      id
      firstName
      lastName
    }
  }
`;
