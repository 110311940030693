import styled from 'styled-components';
import { Text, Link } from '@arcadiapower/shrike';

export const PasswordNotice = styled(Text)`
  color: ${props => props.theme.colors.accent4};
  margin-bottom: 1rem;
`;

export const CenteredText = styled(Text)`
  text-align: center;
  margin: 2rem 0;
  color: ${props => props.theme.colors.accent2};
`;

export const InfoLink = styled(Link)`
  text-align: center;
  margin: 2rem 0;
  color: ${props => props.theme.colors.accent2};
`;

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  text-align: center;
`;

export const ExternalLoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
`;
