import { useState, useCallback } from 'react';
import { MainMenuContainer } from 'styles';
import { ProfileHeader } from 'components/dashboard-profile-header';
import { copyFor } from 'config/copy';
import { MenuOption } from 'components/dashboard-menu-option';
import { DashboardMenuAccordion } from 'components/dashboard-menu-accordion';
import { DashboardProfileUser } from 'pages/dashboard/profile';
import { addressFormatter } from 'utilities';
import { useQuery } from '@apollo/client';
import { useConnect } from '@arcadia-eng/connect-react';
import { Alert, Text } from '@arcadiapower/shrike';
import { dashboardProfile } from 'config/routes';
import { CONNECT_ENV } from 'config/constants';
import { GET_CONNECT_TOKEN } from './connect.api';

const getCopy = copyFor('dashboard.connect');

export type Props = {
  user: DashboardProfileUser;
  refetchUser: () => void;
};

export const ProfileConnect = ({ user, refetchUser }: Props): JSX.Element => {
  const connectIsVerified =
    user.arcadiaUtilityCredential?.verificationStatus === 'verified';

  const { data: dataUtilityTokenQuery, error: getConnectTokenError } = useQuery<
    GetExistingConnectTokenQuery,
    GetExistingConnectTokenQueryVariables
  >(GET_CONNECT_TOKEN, {
    fetchPolicy: 'network-only',
    skip: connectIsVerified,
    variables: { utilityCredentialId: user.arcadiaUtilityCredential?.id },
  });
  const [{ error }, open] = useConnect();

  const [connectError, setConnectError] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const onClose = useCallback(
    ({ status }: { status: string }) => {
      if (status === 'error') {
        setConnectError(true);
        return;
      }
      if (status !== 'no_submit') {
        refetchUser();
      }
      setConnectError(false);
    },
    [setConnectError, refetchUser]
  );

  const config = {
    _experimental: { cannotChangeUtility: true },
    callbacks: {
      onClose,
    },
    connectToken: dataUtilityTokenQuery?.connectToken,
    env: CONNECT_ENV,
  };

  const showAlert = error || getConnectTokenError || connectError;

  const handleAccordionClick = () => {
    setAccordionOpen(!accordionOpen);
  };

  const utilityName =
    user.preferredUtilityAccount?.utilityName || user.utility?.name;

  return (
    <MainMenuContainer>
      <ProfileHeader title={getCopy('title')} backLink={dashboardProfile} />
      <MenuOption
        title={getCopy('login', {
          utility: utilityName,
        })}
        subtitle={user.arcadiaUtilityCredential?.username}
        onClick={
          dataUtilityTokenQuery && !connectIsVerified
            ? () => open(config)
            : undefined
        }
        testId="optionLogin"
        hideArrow={connectIsVerified}
      />
      <MenuOption
        title={getCopy('company')}
        subtitle={utilityName}
        testId="optionCompany"
        hideArrow
      />
      <MenuOption
        title={getCopy('address')}
        subtitle={
          user.preferredUtilityAccount
            ? addressFormatter(user.preferredUtilityAccount)
            : ''
        }
        testId="optionAddress"
        hideArrow
      />
      <MenuOption
        title={getCopy('account', {
          utility: utilityName,
        })}
        subtitle={user.preferredUtilityAccount?.accountNumber}
        testId="optionAccount"
        hideArrow
      />
      {showAlert && <Alert>{getCopy('error')}</Alert>}
      <DashboardMenuAccordion
        data-testid="dashboardMenuAccordion"
        isOpen={accordionOpen}
        onClick={() => handleAccordionClick()}
        title={getCopy('accordionTitle')}
      >
        <Text textStyle="paragraph500" color="accent1">
          {getCopy('accordionContentExplanation')}
        </Text>
        <Text textStyle="paragraph500" margin="1rem 0 0 0" color="accent1">
          {getCopy('accordionContentReason')}
        </Text>
      </DashboardMenuAccordion>
    </MainMenuContainer>
  );
};
