import { useState } from 'react';

export const useModal = ({
  isOpenInitially = false,
}: {
  isOpenInitially?: boolean;
} = {}): { isOpen: boolean; toggle: () => void } => {
  const [isOpen, setIsOpen] = useState(isOpenInitially);

  function toggle() {
    setIsOpen(!isOpen);
  }

  return {
    isOpen,
    toggle,
  };
};
