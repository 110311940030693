import { useContext } from 'react';
import { Signup, SignupContext } from 'contexts/signup';
import { GridContainer, PrimaryGrid, Title } from 'styles/simple-grid.style';
import { Text } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { trackEvent } from 'utilities/analytics';
import { UtilitySelectForm } from 'components/signup/forms/utility-select';
import signupImg from './assets/signupImg.png';
import { MainImg } from './utility-select.style';

const getCopy = copyFor('signup.steps.utilitySelect');

export const UtilitySelect = (): JSX.Element => {
  const {
    setLocalUtility: setLocalUtilityContext,
    setLocalZip: setLocalZipContext,
    next,
  } = useContext<SignupContext>(Signup);

  const handleSubmit = ({
    utility,
    zip,
  }: {
    utility: ArcadiaUtility;
    zip: string;
  }) => {
    setLocalUtilityContext(utility);
    setLocalZipContext(zip);
    trackEvent('Signup submit: utility-select', { utility: utility.name, zip });
    next();
  };

  return (
    <GridContainer data-testid="step.utility-select">
      <PrimaryGrid>
        <MainImg alt="happy family" src={signupImg} />
        <Title>{getCopy('title')}</Title>
        <Text margin={{ bottom: '20px', top: '0' }}>
          {getCopy('description')}
        </Text>
        <Text margin={{ bottom: '20px', top: '0' }}>
          {getCopy('quickDetail')}
        </Text>
        <UtilitySelectForm onSubmit={handleSubmit} />
      </PrimaryGrid>
    </GridContainer>
  );
};
