import { gql } from '@apollo/client';

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      user {
        id
      }
      success
    }
  }
`;
