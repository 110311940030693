import { gql } from '@apollo/client';

export const CONFIRM_USER_EMAIL = gql`
  mutation confirmEmail($input: ConfirmEmailInput!) {
    confirmEmail(input: $input) {
      success
    }
  }
`;

export const generateConfirmEmailResponse = (): ConfirmEmailMutation => ({
  confirmEmail: {
    success: true,
  },
});
