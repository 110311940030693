import { useCallback, useEffect, useMemo } from 'react';
import { Link } from '@arcadiapower/shrike';
import { useQuery } from '@apollo/client';
import {
  PendingState,
  RejectedState,
  RemovedState,
} from 'components/pre-enrollment';
import { ErrorState } from 'components/dashboard-general';
import { copyFor } from 'config/copy';
import { Redirect } from 'react-router';
import { USER_ENROLLED_STATES } from 'config/constants';
import { dashboardUsage, logout, profileConnect } from 'config/routes';
import { Loading } from 'components/signup/loading';
import { Wrapper } from './pre-enrollment.style';
import { CURRENT_USER_FOR_PRE_ENROLLMENT } from './pre-enrollment.api';

const getCopy = copyFor('enrollment');

export const PreEnrollment = (): JSX.Element => {
  const {
    data: userData,
    error: userError,
    startPolling,
    stopPolling,
  } = useQuery<
    CurrentUserForPreEnrollmentQuery,
    CurrentUserForPreEnrollmentQueryVariables
  >(CURRENT_USER_FOR_PRE_ENROLLMENT);

  const enrolledState = userData?.currentUser?.enrolledState;
  const utilityCredential = useMemo(
    () => userData?.currentUser?.arcadiaUtilityCredential,
    [userData]
  );

  const utilityCredentialIncorrect =
    utilityCredential?.verificationStatus === USER_ENROLLED_STATES.rejected;

  // Start polling on mount
  useEffect(() => {
    startPolling(1000);
  }, [startPolling]);

  // Stop polling if we've reached an "end state":
  // 1. Credentials are incorrect
  // 2. Account is not pending enrollment
  // 3. Accounts are loaded and we've selected a utility account
  useEffect(() => {
    if (!utilityCredential) return;
    const isSettled =
      enrolledState !== USER_ENROLLED_STATES.pending ||
      utilityCredentialIncorrect ||
      (!utilityCredential.accountsLoadInProgress &&
        userData?.currentUser?.preferredUtilityAccountId);

    if (isSettled) {
      stopPolling();
    }
  }, [
    utilityCredential,
    stopPolling,
    enrolledState,
    utilityCredentialIncorrect,
    userData?.currentUser?.preferredUtilityAccountId,
  ]);

  const renderPreEnrollmentStep = useCallback(() => {
    // Return error if api call fails
    if (userError)
      return (
        <ErrorState
          testId="enrollmentError"
          title={getCopy('error.general.title')}
        >
          {getCopy('error.general.subtitle')}
        </ErrorState>
      );

    // Return generic loading if we don't have our initial data
    if (!userData?.currentUser) return <Loading />;

    // Handle remove/rejected state
    if (enrolledState === USER_ENROLLED_STATES.rejected)
      return <RejectedState user={userData.currentUser} />;

    if (enrolledState === USER_ENROLLED_STATES.removed)
      return (
        <RemovedState
          maxDeclines={
            userData.currentUser.rejectionReason === 'MAX_PAYMENT_DECLINE'
          }
        />
      );

    // Return invalid credentials page
    if (utilityCredentialIncorrect)
      return (
        <ErrorState
          testId="invalidCredentials"
          title={getCopy('error.invalidCredentials.title')}
        >
          {getCopy('error.invalidCredentials.subtitle')}
          <Link
            color="accent2"
            data-testid="profileLink"
            to={profileConnect}
            type="inline"
          >
            {getCopy('error.invalidCredentials.profileLink')}
          </Link>
          {getCopy('error.invalidCredentials.subtitle2')}
        </ErrorState>
      );

    // Return pending state if the user has a selected account
    if (userData.currentUser.preferredUtilityAccountId) {
      return (
        <PendingState user={userData.currentUser} variant="pendingEnrollment" />
      );
    }

    // Fallback - they are waiting on data
    return <PendingState user={userData.currentUser} variant="pendingData" />;
  }, [userError, enrolledState, userData, utilityCredentialIncorrect]);

  // Redirect if not authenticated
  if (userData && !userData.currentUser) return <Redirect to={logout} />;

  // Redirect if they are enrolled
  if (enrolledState === USER_ENROLLED_STATES.enrolled) {
    return <Redirect to={dashboardUsage} />;
  }

  return <Wrapper>{renderPreEnrollmentStep()}</Wrapper>;
};
