import { home } from 'config/routes';
import { copyFor } from 'config/copy';
import { StyledLink, StyledIcon, BackContainer } from './back-button.style';

const getCopy = copyFor('dashboard');

export type Props = {
  to: string;
};

export const BackButton = ({ to = home }: Props): JSX.Element => {
  return (
    <BackContainer>
      <StyledLink
        to={to}
        color="accent2"
        data-testid="back-button-link"
        hideIcon
      >
        <StyledIcon icon="ChevronLeft" color="accent2" />
        {getCopy('backButton')}
      </StyledLink>
    </BackContainer>
  );
};
