import { Icon, Text } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';

const getCopy = copyFor('enrollment.removed');

export type Props = {
  maxDeclines?: boolean;
};

export const RemovedState = ({ maxDeclines = false }: Props): JSX.Element => {
  return (
    <div data-testid="enrollmentRemoved">
      <div>
        <Icon scale={7} icon="ExclamationCircleInverted" color="accent2" />
      </div>
      <Text color="accent2" textStyle="heading900" margin={{ bottom: '2rem' }}>
        {getCopy('title')}
      </Text>
      {maxDeclines ? (
        <div data-testid="maxDeclinesSubtitle">
          <Text
            color="accent2"
            textStyle="paragraph500"
            margin={{ bottom: '1rem' }}
          >
            {getCopy('maxDeclines')}
          </Text>{' '}
          <Text color="accent2" textStyle="paragraph500">
            {getCopy('maxDeclinesContact')}
          </Text>
        </div>
      ) : (
        <div data-testid="regularSubtitle">
          <Text color="accent2" textStyle="paragraph500">
            {getCopy('subtitle')}
          </Text>
        </div>
      )}
    </div>
  );
};
