import { gql } from '@apollo/client';
import { generateUtility } from 'mocks/utility';

export const GET_UTILITIES_BY_ZIP = gql`
  query getUtilitiesByZip($zip: String!) {
    utilitiesByZip(zip: $zip) {
      recommendedByState {
        id
        name
        supported
      }
      recommendedByZip {
        id
        name
        supported
      }
    }
  }
`;

export const generateGetUtilitiesByZipMock = ({
  // Sets the supported value of the returned mocked utilities
  customUtilitySupport = '',
}: {
  customUtilitySupport?: string;
} = {}): UtilitiesByZip => {
  const result = {
    recommendedByState: [
      generateUtility({
        id: 3,
        name: 'Spark Electrical',
        supported: true,
      }),
      generateUtility({
        id: 5,
        name: "Watt's Up?",
        supported: true,
      }),
      generateUtility({
        id: 6,
        name: 'The shocker inc.',
        supported: false,
      }),
    ],
    recommendedByZip: [
      generateUtility({
        id: 2,
        name: 'Surefire Electric',
        supported: true,
      }),
      generateUtility({
        id: 9,
        name: 'We con-duit Electric',
        supported: true,
      }),
    ],
  };

  // None are supported
  if (customUtilitySupport === 'noneSupported') {
    result.recommendedByState.forEach(function (u) {
      u.supported = false;
    });
    result.recommendedByZip.forEach(function (u) {
      u.supported = false;
    });
  }

  // Only one is supported
  if (customUtilitySupport === 'oneSupported') {
    result.recommendedByState.forEach(function (u) {
      u.supported = false;
    });
    result.recommendedByZip.forEach(function (u) {
      if (u.id !== 2) u.supported = false;
    });
  }

  return result;
};
