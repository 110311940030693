import { addDays, addMonths } from 'date-fns';

interface calculateMaxAndMinXAxisDatesProps {
  graphData: Array<GraphMarker>;
  currentStatementSinceLatest: number;
  xAxisMonthRange: number;
}

interface calculateMaxAndMinXAxisDatesReturn {
  max: number;
  min: number;
}

type GraphData = {
  serviceEndDate: string;
  kwh: number;
};

const firstOfMonth = (date: Date): Date => {
  const dateObj = typeof date.getMonth === 'function' ? date : new Date(date);
  dateObj.setDate(1);
  return dateObj;
};

export type GraphMarker = {
  x: string;
  y: number;
};

export const calculateMaxAndMinXAxisDates = ({
  currentStatementSinceLatest,
  graphData,
  xAxisMonthRange,
}: calculateMaxAndMinXAxisDatesProps): calculateMaxAndMinXAxisDatesReturn => {
  graphData.sort((a, b) => new Date(b.x).getTime() - new Date(a.x).getTime());
  let rangeMaxDate = firstOfMonth(
    addMonths(new Date(graphData[0].x), 1 - currentStatementSinceLatest)
  );

  let rangeMinDate = addMonths(rangeMaxDate, -(xAxisMonthRange - 1));

  if (graphData.length - currentStatementSinceLatest < xAxisMonthRange - 1) {
    rangeMinDate = addMonths(new Date(graphData[graphData.length - 1].x), -1);
    rangeMaxDate = addMonths(rangeMinDate, xAxisMonthRange - 1);
  }
  // hack: extend range to show min and max labels
  const max = addDays(rangeMaxDate, 9).getTime();
  const min = addDays(firstOfMonth(rangeMinDate), -9).getTime();
  return { max, min };
};

export const processGraphData = (data: GraphData[]): GraphMarker[] =>
  data.map(item => ({
    x: item.serviceEndDate,
    y: item.kwh,
  }));

export const calculateYAxisMaximum = (data: GraphMarker[]): number =>
  (data?.reduce((acc, item) => {
    if (acc < item.y) acc = item.y;
    return acc;
  }, 0) || 0) * 1.2;
