import { gql } from '@apollo/client';
import {
  FULL_PAYMENT_METHOD_FRAGMENT,
  generateFullPaymentMethod,
} from 'api/fragments';

export const DELETE_PAYMENT_METHOD = gql`
  mutation deletePaymentMethod($input: DeletePaymentMethodInput!) {
    deletePaymentMethod(input: $input) {
      user {
        id
        paymentMethods {
          id
          ...FullPaymentMethod
        }
      }
    }
  }
  ${FULL_PAYMENT_METHOD_FRAGMENT}
`;

export const generateDeletePaymentMethodResponse =
  (): DeletePaymentMethodMutation => ({
    deletePaymentMethod: {
      user: {
        id: '2',
        paymentMethods: [
          generateFullPaymentMethod({
            paymentMethod: {
              defaultPaymentMethodForUtilityAccountIds: [2],
              id: 2,
            },
          }),
        ],
      },
    },
  });
