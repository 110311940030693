export const home = '/';

export const loginRoot = '/login';
export const logout = '/logout';

export const forgotPassword = `${loginRoot}/forgot-password`;
export const passwordReset = `${loginRoot}/password-reset`;

export const signup = '/signup';

export const dashboard = '/dashboard';

export const dashboardUsage = '/dashboard/usage';

export const dashboardBill = '/dashboard/bill';
export const dashboardProfile = '/dashboard/profile';
export const dashboardEnrollment = '/dashboard/enrollment';

export const statementDetail = '/dashboard/statement/:id';

export const profileAccount = '/dashboard/profile/account';
export const profileAccountName = '/dashboard/profile/account/name';
export const profileAccountPassword =
  '/dashboard/profile/account/change-password';
export const profilePayment = '/dashboard/profile/payment-method';
export const profileConnect = '/dashboard/profile/connect';
