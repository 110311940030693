import { GridContainer, CenteredPrimaryGrid } from 'styles/simple-grid.style';
import { Loading as ShrikeLoading } from '@arcadiapower/shrike';

export const Loading = (): JSX.Element => {
  return (
    <GridContainer data-testid="signup.loading">
      <CenteredPrimaryGrid>
        <ShrikeLoading />
      </CenteredPrimaryGrid>
    </GridContainer>
  );
};
