import { copyFor } from 'config/copy';
import { Text, Skeleton } from '@arcadiapower/shrike';
import helpboxImage from 'assets/usage/helpbox-cta.png';
import {
  Wrapper,
  LoadingWrapper,
  ContentWrapper,
  IconSkeleton,
  HelpImage,
} from './help-box.style';

const getCopy = copyFor('dashboard.helpBox');

export type Props = {
  className?: string;
  loading: boolean;
};

export const HelpBox = ({ className, loading }: Props): JSX.Element => {
  if (loading) {
    return (
      <LoadingWrapper className={className}>
        <ContentWrapper>
          <IconSkeleton>
            <Skeleton height="2rem" width="100%" />{' '}
            <Skeleton
              borderRadius="medium"
              width="2rem"
              height="2rem"
              margin={{ left: '1rem', top: '8px' }}
            />
          </IconSkeleton>

          <Skeleton width="100%" height="4rem" />
        </ContentWrapper>
        <Skeleton width="100%" height="10rem" withShimmer={false} margin="0" />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper className={className} data-testid="helpbox-wrapper">
      <ContentWrapper>
        <Text textStyle="heading800" margin={{ bottom: '1rem' }}>
          {getCopy('header')}
        </Text>
        <Text>{getCopy('content')}</Text>
      </ContentWrapper>
      <HelpImage src={helpboxImage} alt="family cooking" />
    </Wrapper>
  );
};
