import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      email
      firstName
      lastName
      utilityId
      zipCode
    }
  }
`;

export const CREATE_GOOGLE_USER = gql`
  mutation createGoogleUser($input: CreateGoogleUserInput!) {
    createGoogleUser(input: $input) {
      id
      email
      firstName
      lastName
      utilityId
      zipCode
    }
  }
`;

export const generateCreateAccountOutput = ({
  id = '1',
  email = 'first.last@email.test',
  firstName = 'First',
  lastName = 'Last',
  zipCode = '11701',
  utilityId = 1,
} = {}): CreateUserMutation => {
  return {
    createUser: {
      email,
      firstName,
      id,
      lastName,
      utilityId,
      zipCode,
    },
  };
};

export const generateCreateGoogleAccountOutput = ({
  id = '1',
  email = 'first.last@email.test',
  firstName = 'First',
  lastName = 'Last',
  zipCode = '11701',
  utilityId = 1,
} = {}): CreateGoogleUserMutation => {
  return {
    createGoogleUser: {
      email,
      firstName,
      id,
      lastName,
      utilityId,
      zipCode,
    },
  };
};
