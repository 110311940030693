import { gql } from '@apollo/client';
import deepmerge from 'deepmerge';
/**
 * Important: Typenames need to be in fragment mocks for them to work in tests
 * The will fail silently otherwise
 * https://github.com/apollographql/react-apollo/issues/1747#issuecomment-553180150
 */

export const FULL_PAYMENT_METHOD_FRAGMENT = gql`
  fragment FullPaymentMethod on PaymentMethodSource {
    __typename
    id
    payableType
    defaultPaymentMethodForUtilityAccountIds
    expired
    details {
      __typename
      ... on AchPayment {
        bankName
        accountNumberLastFour
      }
      ... on CreditCardPayment {
        vendorName
        cardNumberLastFour
        expirationDate
      }
    }
  }
`;

export type PaymentMethodVariant =
  | 'credit_card'
  | 'visa_credit_card'
  | 'mastercard_credit_card'
  | 'discover_credit_card'
  | 'checking_account';

export const generateFullPaymentMethod = ({
  variant,
  paymentMethod = {},
}: {
  variant?: PaymentMethodVariant;
  paymentMethod?: Partial<FullPaymentMethodFragment>;
} = {}): FullPaymentMethodFragment => {
  const base = {
    __typename: 'PaymentMethodSource' as const,
    defaultPaymentMethodForUtilityAccountIds: [],
    expired: false,
    id: 2,
    payableType: variant ?? 'credit_card',
  };

  let basePaymentMethod: FullPaymentMethodFragment;
  switch (variant) {
    case 'checking_account':
      basePaymentMethod = {
        ...base,
        details: {
          __typename: 'AchPayment' as const,
          accountNumberLastFour: '1212',
          bankName: 'Madoff',
        },
      };
      break;
    case 'mastercard_credit_card':
      basePaymentMethod = {
        ...base,
        details: {
          __typename: 'CreditCardPayment' as const,
          cardNumberLastFour: '4242',
          expirationDate: '2025-02-01', // This value is expected in a test
          vendorName: 'Mastercard',
        },
      };
      break;
    case 'discover_credit_card':
      basePaymentMethod = {
        ...base,
        details: {
          __typename: 'CreditCardPayment' as const,
          cardNumberLastFour: '4242',
          expirationDate: '2025-02-01', // This value is expected in a test
          vendorName: 'Discover',
        },
      };
      break;
    case 'visa_credit_card':
    case 'credit_card':
    default:
      basePaymentMethod = {
        ...base,
        details: {
          __typename: 'CreditCardPayment' as const,
          cardNumberLastFour: '4242',
          expirationDate: '2025-02-01', // This value is expected in a test
          vendorName: 'Visa',
        },
      };
  }

  const overwriteMerge = (_: unknown[], sourceValue: unknown[]) => sourceValue;
  return deepmerge(basePaymentMethod, paymentMethod, {
    arrayMerge: overwriteMerge,
  });
};

export const PAYMENT_TRANSACTION_FOR_PAYMENT_METHOD = gql`
  fragment PaymentTransactionPaymentMethod on ArcadiaInvoicePaymentTransaction {
    __typename
    id
    finishedAt
    lastFour
    brand
    bankName
    paymentMethodId
  }
`;

type PaymentTransactionVariant = 'decline' | 'credit_card' | 'checking_account';

export const generatePaymentTransactionForPaymentMethod = ({
  paymentTransaction = {},
  variant,
}: {
  variant: PaymentTransactionVariant;
  paymentTransaction?: Partial<PaymentTransactionPaymentMethodFragment>;
}): PaymentTransactionPaymentMethodFragment => {
  const base: PaymentTransactionPaymentMethodFragment = {
    __typename: 'ArcadiaInvoicePaymentTransaction',
    bankName: null,
    brand: null,
    finishedAt: '2022-02-24T17:17:01-05:00',
    id: 2,
    lastFour: null,
    paymentMethodId: 2,
  };
  switch (variant) {
    case 'credit_card':
      base.brand = 'visa';
      base.lastFour = '4242';
      break;
    case 'checking_account':
      base.bankName = 'moneybags';
      base.lastFour = '8989';
      break;
    //  Declines do not populate any payment information besides id
    case 'decline':
      break;
  }
  return deepmerge(base, paymentTransaction);
};
