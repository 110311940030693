import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { noop } from 'utilities';
import { copyFor } from 'config/copy';
import { Text, Modal, Loading } from '@arcadiapower/shrike';
import { PrimaryButton } from 'styles/simple-grid.style';
import {
  CancelButtonContainer,
  SimpleModalContainer,
} from './email-confirmation.style';

import { CONFIRM_USER_EMAIL } from './email-confirmation.api';

const getCopy = copyFor('dashboard.emailConfirmation');
export type Props = {
  location?: string;
  token?: string;
  onClose: () => void;
};

export const EmailConfirmationModal = ({
  onClose,
  token,
  location = 'dashboard',
}: Props): JSX.Element => {
  const [confirmationSuccess, setConfirmationSuccess] =
    useState<boolean>(false);
  const [confirmEmail, { loading, error }] = useMutation<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >(CONFIRM_USER_EMAIL, {
    onCompleted: response => {
      const { confirmEmail } = response;
      if (confirmEmail.success) {
        setConfirmationSuccess(true);
      }
    },
    onError: noop,
  });

  const invalidToken = !confirmationSuccess || error;
  const buttonCopy =
    location === 'login' ? getCopy('confirmLogin') : getCopy('confirm');

  useEffect(() => {
    if (token) {
      confirmEmail({ variables: { input: { token } } });
    }
  }, [confirmEmail, token]);

  if (loading) {
    return (
      <Modal aria-label="emailConfirmationModal">
        <Modal.Header title="LoadingModal" />
        <Modal.Content>
          <Loading />
        </Modal.Content>
      </Modal>
    );
  }
  return (
    <Modal aria-label="emailConfirmationModal">
      <Modal.Header
        title={invalidToken ? getCopy('titleExpired') : getCopy('title')}
        onClose={() => onClose()}
      />
      <Modal.Content>
        <SimpleModalContainer>
          <Text>{invalidToken ? getCopy('contentExpired') : null}</Text>
          <CancelButtonContainer data-testid="emailConfirmationButton">
            <PrimaryButton
              data-testid="cancelCreation"
              margin={{ top: '1rem' }}
              onClick={onClose}
            >
              {invalidToken ? getCopy('confirmExpired') : buttonCopy}
            </PrimaryButton>
          </CancelButtonContainer>
        </SimpleModalContainer>
      </Modal.Content>
    </Modal>
  );
};
