import styled from 'styled-components';
import { Spacer } from '@arcadiapower/shrike';

export const NarrowSpacer = styled(Spacer)`
  padding: 0 0;
`;

export const HeaderContainer = styled.div`
  ${props => props.theme.media.desktop`
    ${`margin: 2rem 0 1rem 0;`}
  `};

  ${props => props.theme.media.phoneAndTablet`
    ${`margin: 0.5rem 0 1rem 0;`}
  `};
`;
