export const utilities = {
  invoice: {
    paymentDate: {
      accepted: 'Paid on %{paymentDate}',
      declined: 'Declined on %{paymentDate}',
      pending: 'Payment scheduled for %{paymentDate}',
    },
    unknownPaymentMethod: 'N/A',
  },
  paymentMethod: {
    checkingLabel: 'Checking',
  },
};
