export const signup = {
  forms: {
    utilitySelect: {
      cta: 'Get started',
      contactInfo: 'Questions about Brighten?',
      contactInfoLine2: 'Call us at 800-459-6040.',
      offerDisclaimer: '*July, August, December, January',
      errors: {
        invalidZip: 'Please enter a valid zip code.',
        notSupported: "At this time, we don't serve customers in your area.",
        generic: 'Something went wrong. Please re-enter your zip code.',
      },
      inputs: {
        utility: {
          name: 'Utility provider',
          label: 'Utility provider',
          placeholder: 'Select your provider',
          placeholderLoading: 'Loading...',
        },
        zip: {
          name: 'Zip code',
          label: 'Zip code',
          placeholder: 'Enter your zip code',
        },
      },
    },
    createAccount: {
      cta: 'Continue',
      appleSignin: 'Sign in with Apple',
      passwordInfo: 'Must contain at least 8 characters.',
      terms: 'General Terms of Service',
      privacyPolicy: 'Privacy Policy',
      termsStart: 'By continuing, you’re agreeing to Brighten Energy’s ',
      termsAnd: ' and ',
      termsEnd:
        '. You acknowledge that you’re not enrolled in any budget billing, Customer Assistance Program, net metering program or payment plan with your utility, and you’re not enrolling your small business on Season Pass.',
      help: 'Questions?',
      contact: 'Call 800-459-6040',
      schedule: 'Monday – Friday from 9 a.m. – 6 p.m. (ET)',
      errors: {
        invalidPassword: 'Password should be at least 8 characters long.',
        invalidEmail: 'Email is not valid',
        noError: '',
        takenEmail: 'Email has already been taken',
        generic: 'Something went wrong. Please try again.',
      },
      googleButton: 'Sign up with Google',
      inputs: {
        firstName: {
          name: 'firstName',
          label: 'First name',
          placeholder: 'Enter your first name',
          placeholderLoading: 'Loading...',
        },
        lastName: {
          name: 'lastName',
          label: 'Last name',
          placeholder: 'Enter your last name',
          placeholderLoading: 'Loading...',
        },
        email: {
          name: 'email',
          label: 'Email',
          placeholder: 'example@email.com',
          placeholderLoading: 'Loading...',
        },
        password: {
          name: 'password',
          label: 'New password',
          placeholder: '8+ characters',
          placeholderLoading: 'Loading...',
        },
      },
    },
  },
  error: {
    title: 'Something went wrong.',
    description: 'Please reload the page or clear your cookies.',
  },
  steps: {
    utilitySelect: {
      title: 'Power your life with 50% electricity savings.',
      description:
        'Cut your summer and winter* supply rate in half with 100% renewable electricity.',
      quickDetail: 'Sign up with Brighten Energy in a few quick steps.',
    },
    accountCreation: {
      title: "Let's get you started with a Brighten account.",
    },
    planSelection: {
      title: 'Good news: Season Pass is available in your area.',
      subtitle1:
        'Enjoy 50% off your supply charges July, August, December and January.',
      subtitle2: 'That’s a supply rate of only  %{discountRate}¢ per kWh.*',
      existingAccount: 'Have a Brighten Energy account?',
      login: 'Sign in',
      help: 'Questions?',
      contact: 'Call 800-459-6040',
      schedule: 'Monday – Friday from 9 a.m. – 6 p.m. (ET)',
      disclaimer:
        'Brighten Energy is an independent electric generation supplier and is not affiliated with %{utility}.',
      length:
        '*50% off supply charges applies to prorated usage incurred in the months of July, August, December and January (“Discount”). Utility and other billed charges apply normally in all months. Discount cannot be applied in the first or last billing cycle of service. For residential customers only.',
      button: 'Continue',
      months: 'months',
      perKWH: 'per kWh',
      planDetails: 'Plan details:',
      rate: 'Rate Plan',
      terms: 'Terms and Conditions',
      error:
        'Something went wrong, please refresh this page and try again, or try again later.',
    },
    connectArcadia: {
      title: 'Next, we’ll help you securely connect to your utility account.',
      tipsHeader: 'Tips for connecting:',
      tips: [
        'You’ll have the same utility, but we’ll supply your energy (at half-price for 4 months out of the year!) and manage your billing.',
        'You’ll need your utility login credentials handy to connect your accounts.',
        'If you don’t have an account with your utility or don’t remember your credentials, use your utility’s website to create or reset your account’s password.',
      ],
      cta: 'Connect',
      errors: {
        generic: 'Something went wrong. Please retry.',
      },
    },
    payment: {
      title: 'Save a payment method.',
      description:
        'Set up your preferred payment information to automatically pay your bills. You can update this at any time in your energy dashboard. You can also view your monthly bill and Season Pass savings there.',
    },
    review: {
      title: 'Almost there! Please review your details.',
      errors: {
        generic: 'Something went wrong',
      },
      description:
        "We'll be submitting your request for enrollment to your local utility. Once they accept, we'll send you an email to confirm your Season Pass enrollment.",
      userData: {
        name: 'Name',
        email: 'Email',
        payment: 'Payment method',
        paymentInfo: 'We will charge your next utility bill.',
      },
      form: {
        cta: 'Finish sign-up',
        agreements: {
          beforeLinks:
            'I confirm that I am at least 18 years old, I understand and accept the terms of the ',
          betweenLinks: ' and the ',
          afterLinks:
            ', I am authorized to make decisions about electric service, and I authorize Brighten Energy to take the actions required by my utility company to become my electric supplier.',
        },
        terms: 'PA Disclosure Statement',
        contract: 'Brighten Energy Contract Summary',
        errors: {
          generic: 'Something went wrong. Please retry.',
          pdfGenerationError:
            'An error ocurred joining the season pass, please refresh the application and try again.',
        },
      },
    },
    welcome: {
      title: "%{firstName}, you're all set!",
      description:
        "We'll reach out to you once your utility confirms your enrollment. You can visit your energy dashboard at any time to update your payment preference and utility credentials.",
      cta: 'Go to your energy dashboard',
    },
  },
};
