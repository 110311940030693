import { gql } from '@apollo/client';

export const GET_ENROLLED_STATE = gql`
  query getDashboardEnrolledState {
    currentUser {
      id
      enrolledState
    }
  }
`;
