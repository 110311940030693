import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  ServerError,
  ServerParseError,
  Operation,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import apolloLogger from 'apollo-link-logger';
import { GraphQLError } from 'graphql';
import { captureError } from 'utilities/analytics';
import { API_URL, APOLLO_LOGGER } from './constants';

const httpLink = new HttpLink({
  headers: {
    Accept: 'application/json',
  },
  uri: `${API_URL}/graphql`,
  credentials: 'include',
});

const parseAndReportApolloErrors = ({
  graphQLErrors,
  networkError,
  operation,
}: {
  graphQLErrors?: readonly GraphQLError[];
  networkError?: Error | ServerError | ServerParseError | null;
  operation: Operation;
}) => {
  let messages: string[] = [];
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // Note: GraphQL errors are not technically "errors" according to
      // sentry, so we format their error string and send it over
      const stringifiedLocations = JSON.stringify(locations);
      const humanFriendlyError = `[GraphQL error]: Message: ${message}, Location: ${stringifiedLocations}, Path: ${path}`;
      captureError({
        error: new Error(humanFriendlyError),
        extras: { operation },
      });
      messages.push(humanFriendlyError);
    });
  }
  if (networkError) {
    captureError({ error: networkError, extras: { operation } });
    messages.push(`[Network error]: ${networkError}`);
  }
  return messages;
};

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  const messages = parseAndReportApolloErrors({
    graphQLErrors,
    networkError,
    operation,
  });
  messages.forEach(message => {
    console.error(message); // eslint-disable-line no-console
  });
});

const apolloLinkArray = [errorLink, httpLink];
if (APOLLO_LOGGER) apolloLinkArray.unshift(apolloLogger);

export const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache(),
  link: ApolloLink.from(apolloLinkArray),
});
