import { useState, useEffect } from 'react';
import { MainMenuContainer } from 'styles';
import { ProfileHeader } from 'components/dashboard-profile-header';
import { copyFor } from 'config/copy';
import { Input, Alert } from '@arcadiapower/shrike';
import { PrimaryButton, SecondaryButton } from 'styles/simple-grid.style';
import { DashboardProfileUser } from 'pages/dashboard/profile';
import { useMutation } from '@apollo/client';
import { noop } from 'utilities';
import { profileAccount } from 'config/routes';
import { UPDATE_USER_NAMES } from './account-details-name.api';

const getCopy = copyFor('dashboard.accountDetails.nameForm');

export type Props = {
  user: DashboardProfileUser;
  onSubmit: () => void;
};

export const ProfileAccountDetailsName = ({
  user,
  onSubmit,
}: Props): JSX.Element => {
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setIsDirty(firstName !== user.firstName || lastName !== user.lastName);
  }, [firstName, lastName, user.firstName, user.lastName]);

  const clearForm = () => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setIsDirty(false);
  };

  const canSubmit = !!(isDirty && firstName && lastName);

  const [updateUserNames, { loading, error }] = useMutation<
    UpdateUserNamesMutation,
    UpdateUserNamesMutationVariables
  >(UPDATE_USER_NAMES, { onCompleted: onSubmit, onError: noop });

  const submit = () => {
    updateUserNames({ variables: { input: { firstName, lastName } } });
  };

  return (
    <MainMenuContainer>
      <ProfileHeader title={getCopy('title')} backLink={profileAccount} />
      <Input
        onChange={(value: string) => {
          setFirstName(value);
        }}
        name={getCopy('inputs.firstName')}
        label={getCopy('inputs.firstName')}
        placeholder={getCopy('inputs.firstName')}
        value={firstName}
        margin={{ bottom: '24px', top: '24px' }}
      />
      <Input
        onChange={(value: string) => {
          setLastName(value);
        }}
        name={getCopy('inputs.lastName')}
        label={getCopy('inputs.lastName')}
        placeholder={getCopy('inputs.lastName')}
        value={lastName}
        margin={{ bottom: '24px', top: '24px' }}
      />
      <PrimaryButton
        disabled={!canSubmit}
        loading={loading}
        onClick={submit}
        margin={{ bottom: '24px', top: '24px' }}
      >
        {getCopy('update')}
      </PrimaryButton>
      {error && <Alert>{getCopy('error')}</Alert>}
      {isDirty && (
        <SecondaryButton onClick={clearForm} disabled={loading}>
          {getCopy('undo')}
        </SecondaryButton>
      )}
    </MainMenuContainer>
  );
};
