import BrightenLogo from 'assets/brighten-logo.png';
import { Wrapper, Logo } from './nav-bar-simple.style';

export type Props = {
  className?: string;
};

export const NavBarSimple = ({ className }: Props): JSX.Element => {
  return (
    <Wrapper className={className}>
      <Logo alt="brighten logo" src={BrightenLogo} />
    </Wrapper>
  );
};
