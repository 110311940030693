export const bill = {
  notification: {
    invoiceDeclined:
      'There was an issue charging your latest statement. Please update your payment details to continue using your Brighten account.',
  },
  header: {
    pending: {
      title: 'A powerful statement',
      content:
        'On %{date}, you will be charged for your latest energy statement.',
    },
    paidWithSavings: {
      title: 'You saved big!',
      content:
        'On %{date}, you were charged %{total} for your latest statement which includes your Season Pass savings.',
    },
    paidWithoutSavings: {
      title: 'A powerful statement',
      content:
        'On %{date}, you were charged %{total} for your latest energy statement.',
    },
    paidStub: {
      title: 'Savings start next month',
      content:
        'On %{date}, you were charged %{total} for your latest statement. Your Season Pass savings will start next month.',
    },
    removedAccount: {
      title: "You've been removed from the Season Pass plan.",
      content:
        'For more information, please contact our support team at 800-459-6040 Monday - Friday from 9 a.m. - 6 p.m. (ET)',
    },
  },
  latestStatement: {
    latestStatement: 'Latest Statement',
    billingPeriod: 'Billing Period:',
    paymentDate: 'Payment Date:',
    paymentMethod: 'Payment Method:',
    electricityCharge: 'Current Charges',
    total: 'Total',
    seasonPass: 'Season Pass Savings',
    fullDetails: 'See full details',
    managePaymentMethods: 'Manage payment methods',
    stubBillSeasonPassMessage:
      'Season Pass savings will be applied starting with your next billing period',
  },
  statementHistory: 'Statement History',
  loading: 'Loading...',
  noStatement: "We're waiting for your statement. Check back soon.",
  error: {
    general: "Oops! Something went wrong. We're fixing it.",
  },
  statementDetail: {
    to: 'To:',
    period: 'Billing Period:',
    paymentDate: 'Payment Date:',
    paymentMethod: 'Payment Method:',
    account: 'Utility Account #:',
    total: 'Total',
    usage: 'Usage',
    rate: 'Rate',
    rateMonth: 'Rate: (%{month})',
    rateMonthDiscount: 'Rate: (%{month} - %{discount}% off)',
    supplyCharges: 'Brighten supply charges',
    deliveryCharges: 'Electric delivery charges',
    gasCharges: 'Gas supply and delivery charges',
    greenEnergy: 'Green energy supply',
    seasonPassDiscount: 'Season Pass savings:',
    otherCredits: 'Credits',
    pdfLink: 'View original %{utilityName} bill',
    otherCharges: 'Other',
    error: "Oops! Something went wrong. We're fixing it.",
    managePaymentMethods: 'Manage payment methods',
    stubBillElectricSupplyCharges: 'Electric supply charges',
  },
};
