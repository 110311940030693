import { useMemo } from 'react';
import { Icon } from '@arcadiapower/shrike';
import {
  MenuOptionLink,
  MenuOptionDiv,
  NarrowSpacer,
  HeaderText,
  SubtitleText,
} from './menu-option.style';

export type Props = {
  title: string;
  to?: string;
  subtitle?: string;
  hideArrow?: boolean;
  onClick?: React.MouseEventHandler;
  testId: string;
  notification?: boolean;
};

export const MenuOption = ({
  title,
  subtitle,
  hideArrow = false,
  onClick,
  testId,
  to,
  notification = false,
}: Props): JSX.Element => {
  const MenuOptionWrapper: React.ElementType = useMemo(
    () => (to ? MenuOptionLink : MenuOptionDiv),
    [to]
  );

  return (
    <>
      <MenuOptionWrapper onClick={onClick} data-testid={testId} to={to}>
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <div>
            <span data-testid="menuOptionTitle">
              <HeaderText
                textStyle="paragraph600"
                data-testid="menuOptionTitle"
                notification={notification}
              >
                {title}
              </HeaderText>
            </span>
            <span data-testid="menuOptionSubtitle">
              <SubtitleText
                textStyle="paragraph400"
                opacity="low"
                notification={notification}
              >
                {subtitle}
              </SubtitleText>
            </span>
          </div>
        </div>
        {hideArrow ? null : (
          <div
            style={{ alignItems: 'center', display: 'flex' }}
            data-testid={`${testId}ArrowIconContainer`}
          >
            <Icon color="accent2" icon="ChevronRight" />
          </div>
        )}
      </MenuOptionWrapper>
      <NarrowSpacer />
    </>
  );
};
