export const login = {
  loginEmail: {
    cta: 'Sign in',
    title: 'Sign in with email',
    appleSignin: 'Sign in with Apple',
    forgotPwd: 'Forgot password?',
    errors: {
      invalidCredentials: 'Your credentials are incorrect',
      generic: 'Something went wrong. Please try again.',
      expired: 'Your session has expired. Please login again.',
    },
    inputs: {
      email: {
        name: 'email',
        label: 'Email',
        placeholder: 'example@brightenenergy.com',
      },
      password: {
        name: 'password',
        label: 'Password',
        placeholder: 'Enter your password',
      },
    },
  },
  forgotPassword: {
    emailForm: {
      title: 'Forgot password',
      description: "No problem, we'll email you a link where you can reset it.",
      cta: 'Send link',
    },
    submitted: {
      title: "We've sent you a link to reset your password.",
      description:
        'An email with a special link should arrive soon to %{email}.',
      cta: 'Okay',
    },
    inputs: {
      email: {
        name: 'email',
        label: 'Email',
        placeholder: 'example@brightenenergy.com',
      },
    },
    errors: {
      generic: 'Something went wrong. Please try again.',
    },
  },
  passwordReset: {
    title: 'Reset your password',
    inputs: {
      password: {
        name: 'password',
        label: 'New password',
        placeholder: '8+ characters',
        description: 'Must contain at least 8 characters.',
      },
    },
    cta: 'Reset password',
    errors: {
      generic: 'Something went wrong. Please try again.',
    },
  },
};
