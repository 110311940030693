import { Icon } from '@arcadiapower/shrike';
import {
  Wrapper,
  Header,
  IconContainer,
  StyledText,
} from './dashboard-menu-accordion.style';

export type DashboardMenuAccordionProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClick?: React.MouseEventHandler;
  title?: string;
  invertColors?: boolean;
};

export const DashboardMenuAccordion = ({
  children,
  isOpen,
  onClick,
  title,
  invertColors = false,
}: DashboardMenuAccordionProps): JSX.Element => {
  return (
    <Wrapper data-testid="dashboardMenuAccordion">
      <Header onClick={onClick} data-testid="dashboardMenuAccordionHeader">
        <StyledText
          textStyle="paragraph600"
          data-testid="dashboardMenuAccordionTitle"
          color={invertColors ? 'primaryInverse' : 'accent2'}
        >
          <span>{title}</span>
        </StyledText>
        <IconContainer>
          <Icon
            color={invertColors ? 'primaryInverse' : 'accent2'}
            icon={isOpen ? 'ChevronUp' : 'ChevronDown'}
          />
        </IconContainer>
      </Header>
      {isOpen && (
        <div data-testid="dashboardMenuAccordionContent">{children}</div>
      )}
    </Wrapper>
  );
};
