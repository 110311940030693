import { Skeleton } from '@arcadiapower/shrike';
import { Wrapper } from './energy-skeleton.style';

export type EnergySkeletonProps = {
  className?: string;
  opacity?: 'high' | 'low' | 'medium';
};

export const EnergySkeleton: React.FC<EnergySkeletonProps> = ({
  className,
  opacity = 'high',
}) => {
  return (
    <Wrapper className={className} data-testid="energy-skeleton">
      <Skeleton height="2rem" opacity={opacity} />
      <Skeleton height="4rem" width="100%" opacity={opacity} />
      <Skeleton height="4rem" width="100%" opacity={opacity} />
    </Wrapper>
  );
};
