import { useContext } from 'react';
import { GridContainer, PrimaryGrid, Title } from 'styles/simple-grid.style';
import { Text, Alert } from '@arcadiapower/shrike';
import { Signup, SignupContext } from 'contexts/signup';
import { copyFor } from 'config/copy';
import { useQuery, useMutation } from '@apollo/client';
import { noop } from 'utilities';
import { ConnectArcadiaCtaButton } from 'components/signup/forms/connect-arcadia';
import {
  GET_CONNECT_TOKEN,
  SAVE_UTILITY_CREDENTIAL_ID,
} from './connect-arcadia.api';

const getCopy = copyFor('signup.steps.connectArcadia');

const useApollo = ({
  variables: connectTokenVariables,
}: {
  variables: GetConnectTokenQueryVariables;
}) => {
  const {
    data: dataUtilityTokenQuery,
    loading: loadingGettingToken,
    error: getConnectTokenError,
  } = useQuery<GetConnectTokenQuery, GetConnectTokenQueryVariables>(
    GET_CONNECT_TOKEN,
    { fetchPolicy: 'network-only', variables: connectTokenVariables }
  );

  const [saveUtilityCredentialId] = useMutation<
    UpdateUtilityCredentialIdMutation,
    UpdateUtilityCredentialIdMutationVariables
  >(SAVE_UTILITY_CREDENTIAL_ID, { onError: noop });

  return {
    dataUtilityTokenQuery,
    getConnectTokenError,
    loadingGettingToken,
    saveUtilityCredentialId,
  };
};

export const ConnectArcadia = (): JSX.Element => {
  const { next, zip, utility, user } = useContext<SignupContext>(Signup);
  const utilityCredentialId = user?.arcadiaUtilityCredential?.id;
  const {
    dataUtilityTokenQuery,
    getConnectTokenError,
    loadingGettingToken,
    saveUtilityCredentialId,
  } = useApollo({ variables: { utilityCredentialId } });

  return (
    <GridContainer data-testid="step.connect-arcadia">
      <PrimaryGrid>
        <Title>{getCopy('title')}</Title>
        <ConnectArcadiaCtaButton
          zip={zip as string}
          next={next}
          connectToken={dataUtilityTokenQuery?.connectToken}
          disabled={
            loadingGettingToken ||
            !dataUtilityTokenQuery ||
            !!getConnectTokenError
          }
          updatingCredential={!!utilityCredentialId}
          utility={utility as ArcadiaUtility}
          saveUtilityCredentialId={saveUtilityCredentialId}
        />
        {getConnectTokenError && <Alert>{getCopy('errors.generic')}</Alert>}

        <Text textStyle="heading600" margin={{ bottom: '0', top: '3rem' }}>
          {getCopy('tipsHeader')}
        </Text>

        <ul>
          {getCopy('tips').map((tip: string, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              <Text margin={{ bottom: '1.5rem', top: '0' }}>{tip}</Text>
            </li>
          ))}
        </ul>
      </PrimaryGrid>
    </GridContainer>
  );
};
