import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      success
      user {
        id
        accountSetupCompleted
      }
    }
  }
`;

export const LOGIN_GOOGLE = gql`
  mutation loginGoogle($input: LoginGoogleInput!) {
    loginGoogle(input: $input) {
      success
      user {
        id
        accountSetupCompleted
      }
    }
  }
`;

export const EXTERNAL_LOGIN = gql`
  mutation externalLogin($input: ExternalLoginInput!) {
    externalLogin(input: $input) {
      success
      user {
        id
        accountSetupCompleted
      }
    }
  }
`;

export const generateLoginEmailOutput = ({
  success = true,
  user = {
    accountSetupCompleted: false,
    id: '1',
  },
} = {}): LoginMutation => {
  if (!success) {
    return { login: { success, user: null } };
  }
  return {
    login: {
      success,
      user,
    },
  };
};

export const generateLoginGoogleOutput = ({
  success = true,
  user = {
    accountSetupCompleted: false,
    id: '1',
  },
} = {}): LoginGoogleMutation => {
  if (!success) {
    return { loginGoogle: { success, user: null } };
  }
  return {
    loginGoogle: {
      success,
      user,
    },
  };
};

export const generateExternalLoginOutput = ({
  success = true,
  user = {
    accountSetupCompleted: false,
    id: '1',
  },
} = {}): ExternalLoginMutation => {
  if (!success) {
    return { externalLogin: { success, user: null } };
  }
  return {
    externalLogin: {
      success,
      user,
    },
  };
};
