export const usage = {
  tips: {
    header: 'Hi, %{firstName}!',
    content:
      'Keep on top of your monthly energy usage, view historical data and compare how your usage varies month over month.',
  },
  useHistory: {
    title: 'Energy Use History',
    noStatements:
      'There is no historical data available right now. Check back soon.',
    error: {
      general: 'Something went wrong. Please try again.',
      query: "Oops! Something went wrong. We're fixing it.",
    },
  },
  energyUse: {
    header: 'Your Energy Use',
    noData: 'There is no usage data available right now. Check back soon.',
    error: 'Oops! Something went wrong. We’re fixing it.',
  },
  statementComparator: {
    insight:
      'From %{startDate} to %{endDate} you consumed %{percentage}% %{comparison} electricity than the %{comparedMonth} month.',
    insightSingle: 'From  %{startDate} to %{endDate} you consumed %{kwh}kwh.',
  },
};
