import { Text } from '@arcadiapower/shrike';
import { formatDate } from '@arcadiapower/warbler';
import { copyFor } from 'config/copy';
import { BrightenStatementList } from 'components/usage/energy-use/energy-use.component';
import { Wrapper } from './statement-comparator.style';

export type StatementComparatorProps = {
  className?: string;
  currentStatementIndex: number;
  statements: BrightenStatementList;
};

const getCopy = copyFor('usage.statementComparator');

export const StatementComparator: React.FC<StatementComparatorProps> = ({
  className,
  currentStatementIndex,
  statements,
}) => {
  const monthToCompare =
    currentStatementIndex === 0
      ? currentStatementIndex + 1
      : currentStatementIndex - 1;
  const startDate = statements[currentStatementIndex]?.serviceStartDate;
  const endDate = statements[currentStatementIndex]?.serviceEndDate;
  const thisMonthKWH = statements[currentStatementIndex]?.utilityStatement.kwh;
  const comparedMonthKWH = statements[monthToCompare]?.utilityStatement.kwh;
  const percentageChange =
    ((thisMonthKWH - comparedMonthKWH) / comparedMonthKWH) * 100;

  // If there's only one statement on that page
  const noMonthToCompare = statements.length === 1;

  const formattedStartDate = startDate
    ? formatDate(startDate, { format: 'MMM d' })
    : undefined;
  const formattedEndDate = endDate
    ? formatDate(endDate, { format: 'MMM d' })
    : undefined;

  return (
    <Wrapper
      className={className}
      data-testid={`comparatorForStatement${currentStatementIndex}`}
    >
      <Text textStyle="paragraph400">
        {noMonthToCompare
          ? getCopy('insightSingle', {
              endDate: formattedEndDate,
              kwh: thisMonthKWH,
              startDate: formattedStartDate,
            })
          : getCopy('insight', {
              comparedMonth: currentStatementIndex === 0 ? 'next' : 'previous',
              comparison: percentageChange > 0 ? 'more' : 'less',
              endDate: formattedEndDate,
              percentage: Math.abs(percentageChange).toFixed(1),
              startDate: formattedStartDate,
            })}
      </Text>
    </Wrapper>
  );
};
