import { copyFor } from 'config/copy';
import { Text, Modal, Button } from '@arcadiapower/shrike';
import { DashboardMenuAccordion } from 'components/dashboard-menu-accordion';
import { useState } from 'react';
import { StyledModal } from './dashboard-offer.style';

const getCopy = copyFor('dashboard.offer');

export type DashboardOfferModalProps = {
  hide: () => void;
};

export const DashboardOfferModal = ({
  hide,
}: DashboardOfferModalProps): JSX.Element => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleAccordionClick = () => {
    setAccordionOpen(!accordionOpen);
  };

  return (
    <StyledModal aria-label="offerModal" data-testid="modal-container">
      <Modal.Header
        onClose={hide}
        aria-label="offerModalHeader"
        title={getCopy('title')}
      />
      <Modal.Content>
        <Text
          color="primaryInverse"
          margin={{ bottom: '2rem', top: '0' }}
          textStyle="paragraph600"
        >
          {getCopy('message')}
        </Text>

        <Button
          onClick={hide}
          margin={{ bottom: '2rem', top: '0' }}
          fullWidth
          data-testid="modalButton"
        >
          {getCopy('button')}
        </Button>
        <DashboardMenuAccordion
          data-testid="dashboardMenuAccordion"
          isOpen={accordionOpen}
          onClick={handleAccordionClick}
          title={getCopy('accordionTitle')}
          invertColors
        >
          <div style={{ maxHeight: '13rem', overflow: 'auto' }}>
            <Text color="primaryInverse" data-testid="accordionMessage">
              {getCopy('accordionMessage')}
            </Text>
            <Text color="primaryInverse" margin={{ top: '1rem' }}>
              {getCopy('accordionMessage2')}
            </Text>
          </div>
        </DashboardMenuAccordion>
      </Modal.Content>
    </StyledModal>
  );
};
