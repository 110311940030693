import { MainMenuContainer } from 'styles';
import { Text, Loading, Alert, Button, Link } from '@arcadiapower/shrike';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router';
import {
  addressFormatter,
  formatDateRange,
  formatRate,
  monthName,
  formatCurrencyFromCents,
} from 'utilities';
import brightenLogo from 'assets/brighten-logo.png';
import { copyFor } from 'config/copy';
import { BackButton } from 'components/dashboard-back-button';
import { dashboardBill, profilePayment } from 'config/routes';
import {
  getPaymentMethodStringFromInvoice,
  invoiceDeclinedAndRequiresAction,
  isArcadiaInvoiceDeclined,
  paymentDateString,
  splitLineItems,
} from 'utilities/invoice';
import { GET_BRIGHTEN_STATEMENT } from './statement-detail.api';
import {
  Logo,
  Label,
  InfoContainer,
  MidTotalContainer,
  MidTotalLabel,
  MidTotalValue,
  ListItem,
  BottomTotalContainer,
  BottomTotalText,
  BackButtonContainer,
  PaymentDate,
  PdfLinkContainer,
  SeasonPassDiscountContainer,
} from './statement-detail.style';

const getCopy = copyFor('bill.statementDetail');

export const StatementDetail = (): JSX.Element => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const brightenStatementId = parseInt(id);

  const { data, loading, error } = useQuery<
    GetBrightenStatementQuery,
    GetBrightenStatementQueryVariables
  >(GET_BRIGHTEN_STATEMENT, { variables: { brightenStatementId } });

  if (loading) {
    return <Loading data-testid="loading" />;
  }

  const statement = data?.getBrightenStatement;

  if (!data || error || !statement || !statement.arcadiaInvoice) {
    return <Alert>{getCopy('error')}</Alert>;
  }

  const { user, arcadiaInvoice } = statement;

  const {
    seasonPassDiscount,
    supplyLineItem,
    electricDeliveryLineItem,
    gasChargeLineItem,
    otherLineItemsCreditCents,
    otherLineItemsDebitsCents,
  } = splitLineItems(arcadiaInvoice.lineItems);

  // Show the manage payments button when the invoice is declined and the last
  // attempt to charge matches the default payment method
  const showManagePaymentsButton = invoiceDeclinedAndRequiresAction(
    arcadiaInvoice,
    user.paymentMethods
  );

  const { utilityName } = statement.utilityStatement;

  const { pdfUrl } = statement.utilityStatement;

  return (
    <MainMenuContainer data-testid="statement-detail">
      <BackButtonContainer>
        <BackButton to={dashboardBill} />
      </BackButtonContainer>
      <Logo alt="invoice-logo" src={brightenLogo} />

      <InfoContainer>
        <Label>{getCopy('to')}</Label>
        <Text>
          {user.firstName} {user.lastName}
        </Text>
        <Text>{user.email}</Text>
        <Text>
          {user.preferredUtilityAccount &&
            addressFormatter(user.preferredUtilityAccount)}
        </Text>
      </InfoContainer>

      <InfoContainer>
        <Label>{getCopy('period')}</Label>
        <Text>{formatDateRange(statement.utilityStatement)}</Text>
      </InfoContainer>

      <InfoContainer>
        <Label>{getCopy('paymentDate')}</Label>
        <PaymentDate
          declined={isArcadiaInvoiceDeclined(arcadiaInvoice)}
          data-testid="paymentDate"
        >
          {paymentDateString(arcadiaInvoice)}
        </PaymentDate>
      </InfoContainer>

      {showManagePaymentsButton && (
        <Button
          backgroundColor="errorBright"
          onClick={() => history.push(profilePayment)}
          fullWidth
          margin={{ bottom: '1.5rem' }}
        >
          {getCopy('managePaymentMethods')}
        </Button>
      )}

      <InfoContainer>
        <Label>{getCopy('paymentMethod')}</Label>
        <Text data-testid="paymentMethod">
          {getPaymentMethodStringFromInvoice(
            arcadiaInvoice,
            user.paymentMethods
          )}
        </Text>
      </InfoContainer>

      <InfoContainer>
        <Label>{getCopy('account')}</Label>
        <Text>{user.preferredUtilityAccount?.accountNumber}</Text>
      </InfoContainer>

      <MidTotalContainer>
        <MidTotalLabel>{getCopy('total')}</MidTotalLabel>
        <MidTotalValue>
          {formatCurrencyFromCents(arcadiaInvoice.total)}
        </MidTotalValue>
      </MidTotalContainer>

      <ListItem>
        <Text>{getCopy('usage')}</Text>
        <Text>{statement.utilityStatement.kwh} kWh</Text>
      </ListItem>
      {!statement.stubBill &&
        statement.rate.map((rate, index) => (
          <ListItem key={`rate ${rate * index}`}>
            <Text>
              {getCopy(
                statement.discountRate[index] > 0
                  ? 'rateMonthDiscount'
                  : 'rateMonth',
                {
                  discount: 50, // TODO: Eventually get the actual percentage
                  month: monthName(
                    index === 0
                      ? statement.utilityStatement.serviceStartDate
                      : statement.utilityStatement.serviceEndDate
                  ),
                }
              )}
            </Text>
            <Text>
              {statement.kwh[index]} kWh{' '}
              {formatRate(rate - statement.discountRate[index])}
            </Text>
          </ListItem>
        ))}

      {supplyLineItem && (
        <ListItem data-testid="supplyCharges">
          <Text>
            {!statement.stubBill
              ? getCopy('supplyCharges')
              : getCopy('stubBillElectricSupplyCharges')}
          </Text>
          <Text>
            {formatCurrencyFromCents(
              supplyLineItem.amountCents +
                (seasonPassDiscount?.amountCents || 0)
            )}
          </Text>
        </ListItem>
      )}

      {electricDeliveryLineItem && (
        <ListItem data-testid="deliveryCharges">
          <Text>{getCopy('deliveryCharges')}</Text>
          <Text>
            {formatCurrencyFromCents(electricDeliveryLineItem.amountCents)}
          </Text>
        </ListItem>
      )}

      {gasChargeLineItem && (
        <ListItem data-testid="gasCharges">
          <Text>{getCopy('gasCharges')}</Text>
          <Text>{formatCurrencyFromCents(gasChargeLineItem.amountCents)}</Text>
        </ListItem>
      )}

      {!statement.stubBill && (
        <ListItem>
          <Text>{getCopy('greenEnergy')}</Text>
          <Text>Free</Text>
        </ListItem>
      )}

      {!!otherLineItemsDebitsCents && (
        <ListItem data-testid="otherCharges">
          <Text>{getCopy('otherCharges')}</Text>
          <Text>{formatCurrencyFromCents(otherLineItemsDebitsCents)}</Text>
        </ListItem>
      )}

      {!!otherLineItemsCreditCents && (
        <ListItem data-testid="otherCredits">
          <Text>{getCopy('otherCredits')}</Text>
          <Text>{formatCurrencyFromCents(otherLineItemsCreditCents)}</Text>
        </ListItem>
      )}

      <BottomTotalContainer data-testid="total">
        <BottomTotalText>{getCopy('total')}</BottomTotalText>
        <BottomTotalText>
          {formatCurrencyFromCents(arcadiaInvoice.total)}
        </BottomTotalText>
      </BottomTotalContainer>

      {seasonPassDiscount && (
        <SeasonPassDiscountContainer data-testid="seasonPassDiscount">
          <Text>{getCopy('seasonPassDiscount')}</Text>
          <Text>
            {formatCurrencyFromCents(Math.abs(seasonPassDiscount.amountCents))}
          </Text>
        </SeasonPassDiscountContainer>
      )}

      {pdfUrl && (
        <PdfLinkContainer data-testid="PdfLinkContainer">
          <Link href={pdfUrl} openInNewTab color="accent2">
            {getCopy('pdfLink', { utilityName })}
          </Link>
        </PdfLinkContainer>
      )}
    </MainMenuContainer>
  );
};
