import { PaginationWrapper, PageButton } from './energy-use-pagination.style';

export type EnergyUsePaginationProps = {
  currentPage: number;
  pages: number;
  fetchMore: (page: number) => void;
};

export const EnergyUsePagination: React.FC<EnergyUsePaginationProps> = ({
  currentPage,
  pages,
  fetchMore,
}) => {
  return (
    <PaginationWrapper>
      <PageButton
        onClick={() => {
          fetchMore(currentPage - 1);
        }}
        disabled={currentPage === 1}
      >
        {'<'}
      </PageButton>
      {Array.from(Array(pages).keys()).map(page => (
        <PageButton
          key={`page-${page + 1}`}
          onClick={() => {
            fetchMore(page + 1);
          }}
          current={page + 1 === currentPage}
        >
          {page + 1}
        </PageButton>
      ))}
      <PageButton
        onClick={() => {
          fetchMore(currentPage + 1);
        }}
        disabled={currentPage === pages}
      >
        {'>'}
      </PageButton>
    </PaginationWrapper>
  );
};
