import { copyFor } from 'config/copy';
import { capitalize } from '@arcadiapower/warbler';

const getCopy = copyFor('utilities.paymentMethod');

/**
 * Note: Our current assumption is that the user only has one utility account (and if they have multiple, they will be rejected before they can change the default). So for now, we can assume that, if the user has a payment method associated with their account, it is their default payment method
 */
export const getDefaultPaymentMethod = (
  paymentMethods: FullPaymentMethodFragment[]
): FullPaymentMethodFragment | void => {
  const defaultPaymentMethod = paymentMethods.find(paymentMethod => {
    const paymentMethodIsDefault =
      !!paymentMethod.defaultPaymentMethodForUtilityAccountIds.length;
    return paymentMethodIsDefault;
  });
  // Fallback to first payment method if the array is empty
  return defaultPaymentMethod ?? paymentMethods[0];
};

const formatPaymentMethodTypeLastFour = (
  paymentType: string,
  lastFour: string
) => `${capitalize(paymentType)} •••• ${lastFour}`;

export interface PartialPaymentMethodFragment {
  details:
    | {
        __typename: 'CreditCardPayment';
        vendorName: string;
        cardNumberLastFour: string;
      }
    | {
        __typename: 'AchPayment';
        accountNumberLastFour?: string;
      };
}

export const formatPaymentMethod = (
  paymentMethod: PartialPaymentMethodFragment
): string => {
  let humanReadablePaymentType: string;
  let lastFour: string;

  if (paymentMethod.details?.__typename === 'CreditCardPayment') {
    humanReadablePaymentType = paymentMethod.details.vendorName || '';
    lastFour = paymentMethod.details.cardNumberLastFour;
  } else {
    // other case is Ach
    humanReadablePaymentType = getCopy('checkingLabel');
    lastFour = paymentMethod.details?.accountNumberLastFour || '';
  }

  return formatPaymentMethodTypeLastFour(humanReadablePaymentType, lastFour);
};

export const formatPaymentTransactionPaymentMethod = (
  paymentTransaction: PaymentTransactionPaymentMethodFragment
): string | null => {
  // Declines will be missing the payment information - so we should return null
  if (
    !paymentTransaction.lastFour ||
    (!paymentTransaction.brand && !paymentTransaction.bankName)
  )
    return null;

  const { lastFour } = paymentTransaction;
  let humanReadablePaymentType;
  // Payment transactions should have a brand or be a checking account
  if (paymentTransaction.brand) {
    humanReadablePaymentType = paymentTransaction.brand;
  } else {
    humanReadablePaymentType = getCopy('checkingLabel');
  }
  return formatPaymentMethodTypeLastFour(humanReadablePaymentType, lastFour);
};
