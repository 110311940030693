import styled from 'styled-components';

export const Wrapper = styled.div`
  & .toastWrapper {
    ${props => props.theme.media.phoneAndTablet`
      bottom: 75px;
    `};
  }
  height: 100%;
  ${props => props.theme.media.desktop`
    padding-top: 72px;
    `};
  ${props => props.theme.media.phoneAndTablet`
    padding-bottom: 72px;
    `};
`;

export const Content = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${props => props.theme.media.desktop`
    background-color: ${props.theme.colors.background2};}
  `};
`;
