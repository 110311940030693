import { Route, Switch } from 'react-router-dom';
import { forgotPassword, passwordReset } from 'config/routes';
import { LoginEmail } from './login-email';
import { ForgotPassword } from './forgot-password';
import { PasswordReset } from './password-reset';

export const Login = (): JSX.Element => {
  return (
    <Switch>
      <Route exact path={forgotPassword} component={ForgotPassword} />
      <Route exact path={passwordReset} component={PasswordReset} />
      <Route component={LoginEmail} />
    </Switch>
  );
};
