import { GridContainer, PrimaryGrid, Title } from 'styles/simple-grid.style';
import { copyFor } from 'config/copy';
import { CreateAccountForm } from 'components/signup/forms/create-account/create-account-form.component';

const getCopy = copyFor('signup.steps.accountCreation');

export const CreateAccount = (): JSX.Element => {
  return (
    <GridContainer data-testid="step.create-account">
      <PrimaryGrid>
        <Title>{getCopy('title')}</Title>
        <CreateAccountForm />
      </PrimaryGrid>
    </GridContainer>
  );
};
