import { logout } from 'config/routes';
import { useQuery } from '@apollo/client';
import { Redirect, useParams } from 'react-router-dom';
import { NotificationsProvider } from 'contexts/notifications';
import { Error } from 'components/signup/error';
import { useQueryParams } from 'hooks/use-query-params.hook';
import { DashboardNavbar } from 'components/dashboard-navbar';
import { Wrapper, Content } from './dashboard-wrapper.style';
import { GET_ENROLLED_STATE } from './dashboard-wrapper.api';

export type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const DashboardWrapper = ({
  className,
  children,
}: Props): JSX.Element | null => {
  const { data, error, loading } = useQuery<
    GetDashboardEnrolledStateQuery,
    GetDashboardEnrolledStateQueryVariables
  >(GET_ENROLLED_STATE);
  const queryParams = useQueryParams();
  const emailConfirmationToken = queryParams.get('token');
  // Show error if the call fails
  // For now, let's show the sign in error
  if (error) {
    return <Error />;
  }

  // Show nothing if there is no data
  if (loading) {
    return null;
  }

  // If loaded and there is not a current user - that means our session
  // has expired and we should log out
  if (!data?.currentUser) {
    const destination = emailConfirmationToken
      ? `${logout}?token=${emailConfirmationToken}`
      : logout;
    return <Redirect to={destination} />;
  }

  const { enrolledState } = data.currentUser;

  return (
    <Wrapper className={className}>
      <NotificationsProvider enrolledState={enrolledState}>
        <DashboardNavbar enrolledState={enrolledState} />
        <Content>{children}</Content>
      </NotificationsProvider>
    </Wrapper>
  );
};
