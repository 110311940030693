import React, { useContext, useMemo } from 'react';
import { UtilitySelect } from 'components/signup/steps/utility-select';
import { CreateAccount } from 'components/signup/steps/create-account';
import { ConnectArcadia } from 'components/signup/steps/connect-arcadia';
import { PlanSelection } from 'components/signup/steps/plan-selection';
import { Payment } from 'components/signup/steps/payment';
import { Review } from 'components/signup/steps/review';
import { Welcome } from 'components/signup/steps/welcome';
import { Loading } from 'components/signup/loading';
import { Error } from 'components/signup/error';
import { SignupStepKeyType } from 'config/signup-funnel';
import { SignupContext, Signup } from 'contexts/signup/signup.context';

/* eslint-disable sort-keys */
const COMPONENT_SCREEN_MAP: Record<SignupStepKeyType, React.FC> = {
  'utility-select': UtilitySelect,
  'plan-selection': PlanSelection,
  'create-account': CreateAccount,
  'connect-arcadia': ConnectArcadia,
  payment: Payment,
  review: Review,
  welcome: Welcome,
};
/* eslint-enable sort-keys */

export const StepConnector = (): JSX.Element | null => {
  const { currentStep, initializing, initializationError } =
    useContext<SignupContext>(Signup);

  const ComponentToRender = useMemo(() => {
    if (initializing) return Loading;
    if (initializationError) {
      // eslint-disable-next-line no-console
      console.error(initializationError);
      return Error;
    }
    return COMPONENT_SCREEN_MAP[currentStep.key];
  }, [initializing, initializationError, currentStep]);

  return <ComponentToRender />;
};
