import { gql } from '@apollo/client';
import {
  FULL_PAYMENT_METHOD_FRAGMENT,
  generateFullPaymentMethod,
  generatePaymentTransactionForPaymentMethod,
  PAYMENT_TRANSACTION_FOR_PAYMENT_METHOD,
} from 'api/fragments';

export const GET_NOTIFICATIONS_DATA = gql`
  query getNotificationsData($includeLatestStatement: Boolean!) {
    currentUser {
      id
      arcadiaUtilityCredential {
        id
        verificationStatus
      }
      paymentMethods {
        id
        ...FullPaymentMethod
      }
      emailConfirmed
    }
    listBrightenStatements(limit: 1, offset: 0)
      @include(if: $includeLatestStatement) {
      statements {
        id
        arcadiaInvoice {
          id
          status
          paidAt
          paymentScheduledAt
          paymentTransactions {
            id
            ...PaymentTransactionPaymentMethod
          }
        }
      }
    }
  }
  ${PAYMENT_TRANSACTION_FOR_PAYMENT_METHOD}
  ${FULL_PAYMENT_METHOD_FRAGMENT}
`;

export const generateMockNotificationsData = (): GetNotificationsDataQuery => ({
  currentUser: {
    arcadiaUtilityCredential: {
      id: 22,
      verificationStatus: 'correct',
    },
    emailConfirmed: true,
    id: '2',
    paymentMethods: [
      generateFullPaymentMethod({
        paymentMethod: { defaultPaymentMethodForUtilityAccountIds: [2], id: 2 },
      }),
      generateFullPaymentMethod({ paymentMethod: { id: 3 } }),
    ],
  },
  listBrightenStatements: {
    statements: [
      {
        arcadiaInvoice: {
          id: 7,
          paidAt: '2022-02-25T11:55:55-05:00',
          paymentScheduledAt: '2022-02-27T17:17:01-05:00',
          paymentTransactions: [
            generatePaymentTransactionForPaymentMethod({
              variant: 'credit_card',
            }),
          ],
          status: 'paid',
        },
        id: 2,
      },
    ],
  },
});
