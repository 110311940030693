import { gql } from '@apollo/client';

export const SEND_CONFIRMATION_EMAIL = gql`
  mutation sendConfirmationEmail($input: SendConfirmationEmailInput!) {
    sendConfirmationEmail(input: $input) {
      success
    }
  }
`;

export const generateSendConfirmationEmailResponse = (
  response = true
): SendConfirmationEmailMutation => ({
  sendConfirmationEmail: {
    success: response,
  },
});
