import { useState } from 'react';
import { Icon } from '@arcadiapower/shrike';
import { noop } from 'utilities';
import {
  NotificationContainer,
  IconContainer,
  MessageContainer,
  ErrorText,
  ContentContainer,
  StyledLink,
  StyledButton,
} from './notification.style';

export type Props = {
  disableAfterClick?: boolean;
  errorMessage: string;
  inMenu?: boolean;
  linkUrl?: string;
  linkAction?: () => void;
  linkText?: string;
  testId?: string;
};

export const Notification = ({
  testId,
  inMenu = false,
  errorMessage,
  linkUrl,
  linkAction = noop,
  linkText,
  disableAfterClick = true,
}: Props): JSX.Element => {
  const [disabled, setDisabled] = useState<boolean>(false);

  const clickAction = () => {
    if (disableAfterClick) setDisabled(true);
    linkAction();
  };

  const linkActionPresent = linkAction !== noop;

  const showCTA = !!((linkUrl || linkActionPresent) && linkText);

  return (
    <NotificationContainer data-testid={testId} inMenu={inMenu}>
      <ContentContainer inMenu={inMenu}>
        <IconContainer>
          <Icon icon="ExclamationCircleInverted" color="error" />
        </IconContainer>
        <div>
          <MessageContainer>
            <ErrorText>{errorMessage}</ErrorText>
          </MessageContainer>
          {showCTA && linkUrl && (
            <StyledLink
              to={linkUrl}
              openInNewTab={false}
              color="accent2"
              data-testid="notificationLink"
              hideIcon
            >
              {linkText}
            </StyledLink>
          )}
          {showCTA && !linkUrl && (
            <StyledButton
              backgroundColor="primaryInverse"
              data-testid="notificationLink"
              size="small"
              onClick={clickAction}
              disabled={disabled}
            >
              {linkText}
            </StyledButton>
          )}
        </div>
      </ContentContainer>
    </NotificationContainer>
  );
};
