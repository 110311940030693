import { DashboardTips } from 'components/dashboard-tips';
import { copyFor } from 'config/copy';
import { EnergyUseHistory } from 'components/energy-use-history';
import { LatestStatement } from 'components/bill-latest-statement';
import { HelpBox } from 'components/help-box';
import { useQuery } from '@apollo/client';
import { USER_ENROLLED_STATES } from 'config/constants';
import { isArcadiaInvoiceDeclined } from 'utilities/invoice';
import { Notification } from 'components/dashboard-notification';
import { BillPage, BillContainer } from './dashboard-bill.style';
import { GET_LATEST_BRIGHTEN_STATEMENT } from './dashboard-bill.api';
import { getHeaderText } from './dashboard-bill.util';

export type DashboardBillUser = NonNullable<
  GetDashboardBillUserQuery['currentUser']
>;

const getCopy = copyFor('bill');

export const DashboardBill = (): JSX.Element => {
  const {
    data: statementData,
    loading: loadingStatement,
    error: statementError,
  } = useQuery<
    GetLatestBrightenStatementQuery,
    GetLatestBrightenStatementQueryVariables
  >(GET_LATEST_BRIGHTEN_STATEMENT);

  const paymentMethods = statementData?.currentUser?.paymentMethods;
  const latestStatement = statementData?.listBrightenStatements.statements[0];
  const latestArcadiaInvoice = latestStatement?.arcadiaInvoice;
  const isStubBill = !!latestStatement?.stubBill;
  const accountIsRemoved =
    statementData?.currentUser?.enrolledState === USER_ENROLLED_STATES.removed;
  const headerText = getHeaderText(
    latestArcadiaInvoice,
    isStubBill,
    accountIsRemoved
  );

  const showPaymentIssueNotification = !!(
    latestArcadiaInvoice && isArcadiaInvoiceDeclined(latestArcadiaInvoice)
  );

  return (
    <BillPage className="billPage">
      {showPaymentIssueNotification && (
        <Notification errorMessage={getCopy('notification.invoiceDeclined')} />
      )}
      {!statementError &&
        (headerText || loadingStatement || accountIsRemoved) && (
          <DashboardTips
            loading={loadingStatement}
            header={headerText?.title}
            content={headerText?.content}
          />
        )}
      <BillContainer>
        {!accountIsRemoved && (
          <LatestStatement
            loading={loadingStatement}
            queryError={!!statementError}
            statement={latestStatement}
            paymentMethods={paymentMethods}
            data-testid="bill-latest-statement"
          />
        )}
        <EnergyUseHistory
          title={getCopy('statementHistory')}
          loading={loadingStatement}
          showBillAmountInDetailField
          gridArea={accountIsRemoved ? 'top_right' : 'bottom_right'}
        />
        <HelpBox loading={loadingStatement} />
      </BillContainer>
    </BillPage>
  );
};
