import { copyFor } from 'config/copy';
import { useEffect, useState } from 'react';
import { PaymentMethodItem } from 'components/dashboard-profile-payment-method';
import {
  ActionWrapper,
  PrimaryButton,
  SecondaryButton,
  StyledModal,
} from 'styles/simple-grid.style';
import { getDefaultPaymentMethod } from 'utilities/payment-method';
import { useMutation } from '@apollo/client';
import { Alert, Modal } from '@arcadiapower/shrike';
import { ChangeRadio, RadioContainer } from './payment-method-change.style';
import { SET_DEFAULT_PAYMENT_METHOD } from './payment-method-change.api';

const getCopy = copyFor('dashboard.paymentMethod.change');

export type Props = {
  paymentMethods: FullPaymentMethodFragment[];
  isOpen?: boolean;
  hide: () => void;
};

export const PaymentMethodChangeModal = ({
  paymentMethods,
  isOpen = true,
  hide,
}: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOption, selectOption] = useState(
    getDefaultPaymentMethod(paymentMethods)?.id
  );
  const [changeDefaultPaymentMethodMutation, { error }] = useMutation<
    SetDefaultPaymentMethodMutation,
    SetDefaultPaymentMethodMutationVariables
  >(SET_DEFAULT_PAYMENT_METHOD);

  // Reset selection on open
  useEffect(() => {
    if (isOpen) {
      selectOption(getDefaultPaymentMethod(paymentMethods)?.id);
    }
  }, [isOpen, paymentMethods]);

  const changePaymentMethod = async () => {
    if (selectedOption) {
      setLoading(true);
      try {
        await changeDefaultPaymentMethodMutation({
          variables: { input: { paymentMethodId: selectedOption } },
        });
        hide();
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  return (
    <StyledModal
      aria-label="payment-method-change-modal"
      data-testid="payment-method-change-modal"
    >
      <Modal.Header onClose={hide} title={getCopy('title')} />
      <Modal.Content>
        {paymentMethods.map(paymentMethod => (
          <RadioContainer key={paymentMethod.id}>
            <ChangeRadio
              checked={selectedOption === paymentMethod.id}
              name="paymentMethod"
              onChange={() => selectOption(paymentMethod.id)}
              value={paymentMethod.id.toString()}
            >
              <PaymentMethodItem
                key={paymentMethod.id}
                paymentMethod={paymentMethod}
                hideActions
              />
            </ChangeRadio>
          </RadioContainer>
        ))}
        <ActionWrapper>
          {error && <Alert>{getCopy('error')}</Alert>}
          <PrimaryButton
            loading={loading}
            data-testid="confirmChange"
            margin={{ top: '2rem' }}
            onClick={changePaymentMethod}
          >
            {getCopy('confirm')}
          </PrimaryButton>
          <SecondaryButton
            data-testid="cancelChange"
            onClick={hide}
            disabled={loading}
          >
            {getCopy('cancel')}
          </SecondaryButton>
        </ActionWrapper>
      </Modal.Content>
    </StyledModal>
  );
};
