import styled from 'styled-components';

export const CancelButtonContainer = styled.div`
  max-width: 400px;
`;

export const SimpleModalContainer = styled.div`
  ${props => props.theme.media.phoneAndTablet`
  padding: 1rem;
  `}
`;
