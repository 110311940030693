import styled from 'styled-components';
import { Text, Button, Modal } from '@arcadiapower/shrike';
import { Form as FormComponent } from 'components/form';

export const GridContainer = styled.div`
  display: grid;
  flex-grow: 1;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 100%;
  grid-gap: 48px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  ${props => props.theme.media.desktop`
    padding: 75px 160px 120px;
    ${PrimaryGrid}:only-child {
      grid-column: 1 / -1;
    }
`}
`;

export const PrimaryGrid = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;

  ${props => props.theme.media.phoneAndTablet`
    padding: 40px 24px 48px;
  `}

  ${props => props.theme.media.phone`
    padding: 0px 16px 48px;
  `}

  ${props => props.theme.media.desktop`
    grid-column: 1 / 1;
  `}
`;

export const Title = styled(Text).attrs({
  color: 'accent4',
  margin: { bottom: '16px', top: '0' },
  tag: 'h1',
  textStyle: 'heading800',
})`
  line-height: 1.5;
  font-size: 24px;
`;

export const PrimaryButton = styled(Button).attrs({
  fullWidth: true,
})``;

export const SecondaryButton = styled(Button).attrs({
  backgroundColor: 'primary',
  fullWidth: true,
})`
  border-color: ${props => props.theme.colors.content.accent2};
  color: ${props => props.theme.colors.content.accent2};
  &:hover {
    background-color: #c2cad1;
  }
`;

export const DeleteButton = styled(Button).attrs({
  fullWidth: true,
  variant: 'error',
})``;

export const ActionWrapper = styled.div`
  margin-top: 24px;

  ${PrimaryButton} ~ ${SecondaryButton} {
    margin-top: 12px;
  }
`;

export const StyledModal = styled(Modal)`
  & > div {
    max-height: 40rem;
    margin-left: 1rem;
    margin-right: 1rem;

    ${props => props.theme.media.desktop`
    max-height: 50rem;
  `}
  }
`;

export const CenteredPrimaryGrid = styled(PrimaryGrid)`
  justify-content: center;
  text-align: center;
`;

export const Form = styled(FormComponent)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
