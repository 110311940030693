import { Loading, Text } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import {
  profileAccount,
  profileAccountName,
  profileAccountPassword,
  profileConnect,
  profilePayment,
  logout,
} from 'config/routes';
import { Redirect, Switch, Route } from 'react-router';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_PROFILE_USER } from './dashboard-profile.api';
import { ProfileAccountDetails } from './account-details';
import { ProfileAccountDetailsName } from './account-details-name';
import { ProfileAccountDetailsPassword } from './account-details-password';
import { ProfilePaymentMethod } from './payment-method';
import { ProfileConnect } from './connect';
import { DashboardProfileMenu } from './profile-menu';
import { Container } from './dashboard-profile.style';

const getCopy = copyFor('dashboard.profileMainMenu');

export type DashboardProfileUser = NonNullable<
  GetDashboardProfileUserQuery['currentUser']
>;

export const DashboardProfile = (): JSX.Element => {
  const {
    data: dataCurrentUserQuery,
    loading: loadingCurrentUser,
    error: errorCurrentUserQuery,
    refetch,
  } = useQuery<
    GetDashboardProfileUserQuery,
    GetDashboardProfileUserQueryVariables
  >(GET_DASHBOARD_PROFILE_USER);

  if (loadingCurrentUser) {
    return <Loading margin="auto" />;
  }

  if (errorCurrentUserQuery) {
    return (
      <>
        <Text>{getCopy('errorTitle')}</Text>
        <Text>{getCopy('errorDetail')}</Text>
      </>
    );
  }

  const user = dataCurrentUserQuery?.currentUser;

  if (user) {
    return (
      <Container>
        <Switch>
          <Route path={profileAccountName}>
            <ProfileAccountDetailsName user={user} onSubmit={refetch} />
          </Route>
          <Route path={profileAccountPassword}>
            <ProfileAccountDetailsPassword />
          </Route>
          <Route path={profileAccount}>
            <ProfileAccountDetails user={user} />
          </Route>
          <Route path={profilePayment}>
            <ProfilePaymentMethod user={user} />
          </Route>
          <Route path={profileConnect}>
            <ProfileConnect user={user} refetchUser={refetch} />
          </Route>
          <Route>
            <DashboardProfileMenu user={user} />
          </Route>
        </Switch>
      </Container>
    );
  }

  return <Redirect to={logout} />;
};
