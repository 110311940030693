import { copyFor } from 'config/copy';
import { useState, useEffect } from 'react';
import { Icon } from '@arcadiapower/shrike';
import VisaLogo from 'assets/visa-logo.svg';
import MCLogo from 'assets/mc-logo.svg';
import { useModal } from 'components/modals';
import { PaymentMethodDeleteModal } from 'components/dashboard-profile-payment-method-delete-modal';

import { formatPaymentMethod } from 'utilities/payment-method';
import { formatDate } from '@arcadiapower/warbler';
import {
  Wrapper,
  DataContainer,
  ActionContainer,
  InfoText,
  IconButton,
  CCIcon,
} from './payment-method-item.style';

const getCopy = copyFor('dashboard.paymentMethod');

export type Props = {
  paymentMethod: FullPaymentMethodFragment;
  hideActions?: boolean;
  toggleChangePaymentMethod?: () => void;
};

export const PaymentMethodItem = ({
  paymentMethod,
  hideActions = false,
  toggleChangePaymentMethod,
}: Props): JSX.Element => {
  const { isOpen, toggle } = useModal();
  const [creditCardLogo, setCreditCardLogo] = useState<string>('');

  const isPrimaryPaymentMethod =
    !!paymentMethod.defaultPaymentMethodForUtilityAccountIds.length;

  useEffect(() => {
    if (paymentMethod.details.__typename === 'CreditCardPayment') {
      switch (paymentMethod.details.vendorName.toLowerCase()) {
        case 'visa':
          setCreditCardLogo(VisaLogo);
          break;
        case 'mastercard':
          setCreditCardLogo(MCLogo);
          break;
        default:
          setCreditCardLogo('');
      }
    }
  }, [paymentMethod.details]);

  const isCreditCardPayment =
    paymentMethod.details.__typename === 'CreditCardPayment';

  return (
    <Wrapper>
      <DataContainer>
        <InfoText>
          {isCreditCardPayment ? (
            <CCIcon src={creditCardLogo} alt="Creditcard icon" />
          ) : null}{' '}
          {formatPaymentMethod(paymentMethod)}
        </InfoText>
        {paymentMethod.details.__typename === 'CreditCardPayment' && (
          <InfoText>
            {getCopy('expires', {
              date: formatDate(paymentMethod.details.expirationDate, {
                format: 'M/yy',
              }),
            })}
          </InfoText>
        )}
        {paymentMethod.details.__typename === 'AchPayment' && (
          <InfoText>{paymentMethod.details.bankName}</InfoText>
        )}
      </DataContainer>

      {!hideActions && (
        <ActionContainer>
          {toggleChangePaymentMethod && (
            <IconButton data-testid="edit" onClick={toggleChangePaymentMethod}>
              <Icon icon="Pencil" color="accent2" />
            </IconButton>
          )}
          {!isPrimaryPaymentMethod && (
            <IconButton data-testid="delete" onClick={toggle}>
              <Icon icon="UIDelete" color="error" />
            </IconButton>
          )}
        </ActionContainer>
      )}

      {isOpen && (
        <PaymentMethodDeleteModal hide={toggle} paymentMethod={paymentMethod} />
      )}
    </Wrapper>
  );
};
