import { formatDate, sortByDate } from '@arcadiapower/warbler';
import { copyFor } from 'config/copy';
import {
  formatPaymentMethod,
  formatPaymentTransactionPaymentMethod,
  getDefaultPaymentMethod,
} from './payment-method';

const getCopy = copyFor('utilities.invoice');

export type LineItems = Pick<
  ArcadiaInvoiceLineItem,
  'name' | 'amountCents' | 'type'
>[];

export const LINE_ITEM_NAMES = {
  BRIGHTEN_SUPPLY_CHARGE: 'Brighten Supply Charge',
  ELECTRIC_DELIVERY_CHARGE: 'Electric Delivery Charge',
  GAS_CHARGE: 'Gas Charge',
  SEASON_PASS_DISCOUNT: 'Arc Credit',
} as const;

export const PAYMENT_STATUSES = {
  DECLINED: 'payment_failed',
  PAID: 'paid',
  PENDING: 'issued',
} as const;

export type ArcadiaInvoiceForInvoiceUtilities = Pick<
  ArcadiaInvoice,
  'paidAt' | 'status' | 'paymentScheduledAt'
> & {
  paymentTransactions: Array<PaymentTransactionPaymentMethodFragment>;
};

export const isArcadiaInvoicePaid = (
  arcadiaInvoice: ArcadiaInvoiceForInvoiceUtilities
): boolean => arcadiaInvoice.status === PAYMENT_STATUSES.PAID;

export const isArcadiaInvoiceDeclined = (
  arcadiaInvoice: ArcadiaInvoiceForInvoiceUtilities
): boolean => arcadiaInvoice.status === PAYMENT_STATUSES.DECLINED;

export const derivePaymentDate = (
  arcadiaInvoice: ArcadiaInvoiceForInvoiceUtilities
): Date => {
  let derivedPaymentDate;
  // If the customer has paid, we show the paidAt date
  if (arcadiaInvoice.status === PAYMENT_STATUSES.PAID)
    derivedPaymentDate = arcadiaInvoice.paidAt;

  // For design reasons we want to display the "declined" payment date at
  // the date we last attempted to collect their payment method.
  // Otherwise, we should use the "issued" date
  if (
    arcadiaInvoice.status === PAYMENT_STATUSES.DECLINED &&
    arcadiaInvoice.paymentTransactions.length
  ) {
    const latestPaymentTransaction = getMostRecentPaymentTransaction(
      arcadiaInvoice.paymentTransactions
    );
    derivedPaymentDate = latestPaymentTransaction.finishedAt;
  }
  return new Date(derivedPaymentDate ?? arcadiaInvoice.paymentScheduledAt);
};

export const paymentDateString = (
  arcadiaInvoice: ArcadiaInvoiceForInvoiceUtilities
): string => {
  const paymentDate = formatDate(derivePaymentDate(arcadiaInvoice), {
    format: 'MMMM d, yyyy',
  });
  if (arcadiaInvoice.status === PAYMENT_STATUSES.PAID) {
    return getCopy('paymentDate.accepted', { paymentDate });
  }
  if (arcadiaInvoice.status === PAYMENT_STATUSES.DECLINED) {
    return getCopy('paymentDate.declined', { paymentDate });
  }
  return getCopy('paymentDate.pending', { paymentDate });
};

export type LineItem = LineItems[number];

const findLineItemByNameAndRemove = (
  name: string,
  lineItems: LineItems
): LineItem | undefined => {
  const lineItemIndexToRemove = lineItems.findIndex(item => item.name === name);
  if (lineItemIndexToRemove !== -1) {
    return lineItems.splice(lineItemIndexToRemove, 1)[0];
  }
};

const sumLineItems = (lineItems: LineItems): number =>
  lineItems.reduce((sum, lineItem) => sum + lineItem.amountCents, 0);

const getMostRecentPaymentTransaction = (
  paymentTransactions: Array<PaymentTransactionPaymentMethodFragment>
) => {
  // Payment transactions, like all apollo data, is a read-only array, so we
  // need to copy it
  const sortedPaymentTransactions = sortByDate(
    [...paymentTransactions],
    'finishedAt'
  );
  return sortedPaymentTransactions[0];
};

export const splitLineItems = (
  lineItems: LineItems
): {
  supplyLineItem?: LineItem;
  electricDeliveryLineItem?: LineItem;
  seasonPassDiscount?: LineItem;
  gasChargeLineItem?: LineItem;
  otherLineItemsDebitsCents: number;
  otherLineItemsCreditCents: number;
} => {
  const remainingLineItems = [...lineItems];
  const supplyLineItem = findLineItemByNameAndRemove(
    LINE_ITEM_NAMES.BRIGHTEN_SUPPLY_CHARGE,
    remainingLineItems
  );
  const electricDeliveryLineItem = findLineItemByNameAndRemove(
    LINE_ITEM_NAMES.ELECTRIC_DELIVERY_CHARGE,
    remainingLineItems
  );
  const gasChargeLineItem = findLineItemByNameAndRemove(
    LINE_ITEM_NAMES.GAS_CHARGE,
    remainingLineItems
  );

  const seasonPassDiscount = findLineItemByNameAndRemove(
    LINE_ITEM_NAMES.SEASON_PASS_DISCOUNT,
    remainingLineItems
  );
  const otherLineItemsCredits = remainingLineItems.filter(
    lineItem => lineItem.type === 'credit'
  );
  const otherLineItemsDebits = remainingLineItems.filter(
    lineItems => lineItems.type === 'debit'
  );

  return {
    electricDeliveryLineItem,
    gasChargeLineItem,
    otherLineItemsCreditCents: sumLineItems(otherLineItemsCredits),
    otherLineItemsDebitsCents: sumLineItems(otherLineItemsDebits),
    seasonPassDiscount,
    supplyLineItem,
  };
};

export const getPaymentMethodStringFromInvoice = (
  arcadiaInvoice: ArcadiaInvoiceForInvoiceUtilities,
  paymentMethods: FullPaymentMethodFragment[]
): string => {
  // First we'll attempt to get it from the most recent payment transaction
  const mostRecentPaymentTransaction = getMostRecentPaymentTransaction(
    arcadiaInvoice.paymentTransactions
  );
  if (mostRecentPaymentTransaction) {
    // We first get the payment info from the invoice. This should exist for all
    // payments that were not declined
    const paymentMethodStringFromInvoice =
      formatPaymentTransactionPaymentMethod(mostRecentPaymentTransaction);
    if (paymentMethodStringFromInvoice) return paymentMethodStringFromInvoice;

    // If the payment was declined, we search all payment methods for the id on the
    // invoice. This should work for all payment methods that were not deleted
    const paymentMethodObjectOnInvoice = paymentMethods.find(
      paymentMethod =>
        paymentMethod.id === mostRecentPaymentTransaction.paymentMethodId
    );
    if (paymentMethodObjectOnInvoice)
      return formatPaymentMethod(paymentMethodObjectOnInvoice);
    // If a payment method is declined and deleted, return N/A
    return getCopy('unknownPaymentMethod');
  }

  // If there are no transactions, that means the statement is pending/something
  // has gone wrong and we should get the default payment method
  const defaultPaymentMethod = getDefaultPaymentMethod(paymentMethods);
  return defaultPaymentMethod
    ? formatPaymentMethod(defaultPaymentMethod)
    : getCopy('unknownPaymentMethod');
};

// Returns true if the invoice is declined and
// the default payment method matches the id on the invoice
export const invoiceDeclinedAndRequiresAction = (
  invoice: ArcadiaInvoiceForInvoiceUtilities,
  paymentMethods: FullPaymentMethodFragment[]
): boolean => {
  if (!isArcadiaInvoiceDeclined(invoice)) return false;
  const mostRecentTransaction = getMostRecentPaymentTransaction(
    invoice.paymentTransactions
  );
  const { paymentMethodId } = mostRecentTransaction;
  return paymentMethodId === getDefaultPaymentMethod(paymentMethods)?.id;
};
