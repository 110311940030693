export const enrollment = {
  pendingData: {
    title: 'Hi %{firstName}!',
    subtitle:
      "Sit tight, we're retrieving your energy usage information. This page will auto-refresh once we have it.",
  },
  pendingEnrollment: {
    title: 'Nearly there, %{firstName}!',
    subtitle:
      "Sit tight, we're in the process of confirming your enrollment. Please check back in a day or two.",
  },
  rejected: {
    title: "We're sorry, %{firstName}.",
    subtitle:
      'Unfortunately, we are unable to offer you a plan right now. For more information, please contact our support team at 800-459-6040  Monday – Friday from 9 a.m. – 6 p.m. (ET)',
  },
  removed: {
    title: 'You’ve been removed from the Season Pass plan.',
    subtitle:
      'For more information, please contact our support team at 800-459-6040 Monday – Friday from 9 a.m. – 6 p.m. (ET)',
    maxDeclines:
      'Due to multiple declined payments, you have been automatically removed from the Season Pass plan.',
    maxDeclinesContact:
      'If you have any questions, please contact our support team at 800-459-6040 Monday-Friday 9 a.m. - 6 p.m. (ET)',
  },
  error: {
    general: {
      title: 'An error ocurred',
      subtitle:
        "Something went wrong on our end, and we're fixing it. Refresh or try again later.",
    },
    invalidCredentials: {
      title: 'Your account has an issue',
      profileLink: 'Profile',
      subtitle: 'Something is wrong with your account, please check your ',
      subtitle2: ' and update any information that may be causing an issue.',
    },
  },
};
