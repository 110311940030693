import { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton, Form } from 'styles/simple-grid.style';
import { Alert } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { isValidPassword, noop } from 'utilities';
import { PasswordInput } from 'components/password-input';
import { useMutation } from '@apollo/client';
import { useQueryParams } from 'hooks/use-query-params.hook';
import { loginRoot } from 'config/routes';
import { RESET_PASSWORD_WITH_TOKEN } from './password-reset-form.api';
import { DescriptionText } from './password-reset-form.style';

const getCopy = copyFor('login.passwordReset');

export const PasswordResetForm = (): JSX.Element => {
  const history = useHistory();
  const [password, setPassword] = useState('');
  const queryParams = useQueryParams();
  const token = queryParams.get('token') || '';

  const onCompleted = useCallback(() => {
    history.push(loginRoot);
  }, [history]);

  const [resetPasswordWithToken, { error, loading }] = useMutation<
    ResetPasswordWithTokenMutation,
    ResetPasswordWithTokenMutationVariables
  >(RESET_PASSWORD_WITH_TOKEN, { onCompleted, onError: noop });

  const handleSubmit = useCallback(() => {
    resetPasswordWithToken({
      variables: {
        input: {
          newPassword: password,
          token,
        },
      },
    });
  }, [password, resetPasswordWithToken, token]);

  const isValid = useMemo(() => isValidPassword(password), [password]);
  const disabled = !isValid;

  return (
    <Form onSubmit={handleSubmit}>
      <PasswordInput
        name={getCopy('inputs.password.name')}
        label={getCopy('inputs.password.label')}
        placeholder={getCopy('inputs.password.placeholder')}
        onChange={setPassword}
        value={password}
      />
      <DescriptionText>
        {getCopy('inputs.password.description')}
      </DescriptionText>
      <PrimaryButton disabled={disabled} loading={loading} type="submit">
        {getCopy('cta')}
      </PrimaryButton>
      {error && <Alert>{getCopy('errors.generic')}</Alert>}
    </Form>
  );
};
