import { DashboardProfileUser } from 'pages/dashboard/profile';
import { ProfileHeader } from 'components/dashboard-profile-header';
import { copyFor } from 'config/copy';
import { useModal } from 'components/modals';
import { MainMenuContainer } from 'styles';
import { PaymentMethodItem } from 'components/dashboard-profile-payment-method';
import { PrimaryButton } from 'styles/simple-grid.style';
import { dashboardProfile } from 'config/routes';
import { PaymentMethodChangeModal } from 'components/dashboard-profile-payment-method-change-modal';
import { PaymentMethodAddModal } from 'components/dashboard-profile-payment-method-add-modal';
import { getDefaultPaymentMethod } from 'utilities/payment-method';
import { Subtitle } from './payment-method.style';

const getCopy = copyFor('dashboard.paymentMethod');

export type Props = {
  user: DashboardProfileUser;
};

export type PaymentMethod = {
  active: boolean;
  expiration?: string;
  id: number;
  lastFour: number;
  type: string;
  bank?: string;
};

export const ProfilePaymentMethod = ({ user }: Props): JSX.Element => {
  const { paymentMethods } = user;
  const activeMethod = getDefaultPaymentMethod(paymentMethods);
  const alternateMethods = paymentMethods.filter(
    method => method.id !== activeMethod?.id
  );

  const { isOpen: isAddOpen, toggle: toggleAdd } = useModal();
  const { isOpen: isChangeOpen, toggle: toggleChange } = useModal();

  return (
    <MainMenuContainer>
      <ProfileHeader title={getCopy('title')} backLink={dashboardProfile} />

      {activeMethod && (
        <>
          <Subtitle>{getCopy('active')}</Subtitle>
          <PaymentMethodItem
            paymentMethod={activeMethod}
            toggleChangePaymentMethod={toggleChange}
          />
        </>
      )}

      {alternateMethods.length > 0 && (
        <>
          <Subtitle>{getCopy('alternate')}</Subtitle>
          {alternateMethods.map(paymentMethod => (
            <PaymentMethodItem
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
            />
          ))}
        </>
      )}

      <PrimaryButton margin={{ top: '82px' }} onClick={toggleAdd}>
        {getCopy('addPayment')}
      </PrimaryButton>

      {isAddOpen && <PaymentMethodAddModal hide={toggleAdd} />}
      {isChangeOpen && (
        <PaymentMethodChangeModal
          isOpen={isChangeOpen}
          hide={toggleChange}
          paymentMethods={paymentMethods}
        />
      )}
    </MainMenuContainer>
  );
};
