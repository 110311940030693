import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

import { SecondaryButton } from 'styles/simple-grid.style';

export const Wrapper = styled.div`
  padding: 20px 5px;
  border-bottom: 1px solid ${props => props.theme.colors.accent3};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const DataContainer = styled.div``;

export const ActionContainer = styled.div``;

export const InfoText = styled(Text)`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

export const CCIcon = styled.img`
  margin-right: 0.5rem;
`;

export const IconButton = styled(SecondaryButton)`
  padding: 6px;
`;
