import { FormEvent, useCallback, PropsWithChildren } from 'react';
import { Form as StyledForm } from './form.style';

export type Props = PropsWithChildren<{
  className?: string;
  onSubmit: () => void | Promise<void>;
}>;

export const Form = ({ className, children, onSubmit }: Props): JSX.Element => {
  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      return onSubmit();
    },
    [onSubmit]
  );

  return (
    <StyledForm className={className} onSubmit={handleSubmit}>
      {children}
    </StyledForm>
  );
};
