import styled from 'styled-components';

export const MobileBackButtonContainer = styled.div`
  ${props => props.theme.media.desktop`
    display: none;
  `}
`;

export const DesktopBackButtonContainer = styled.div`
  ${props => props.theme.media.phoneAndTablet`
    display: none;
  `}
`;
