import { useContext } from 'react';
import { ProfileMenuOptionsWrapper, DashboardProfileMenuWrapper } from 'styles';
import { Notification as ShrikeToastNotification } from '@arcadiapower/shrike';
import { MenuOption } from 'components/dashboard-menu-option';
import { Notification } from 'components/dashboard-notification';
import { ProfileHeader } from 'components/dashboard-profile-header';
import { copyFor } from 'config/copy';
import {
  profileAccount,
  profileConnect,
  profilePayment,
  logout,
} from 'config/routes';
import {
  NotificationsContext,
  Notifications,
  NotificationKeys,
} from 'contexts/notifications';
import {
  formatPaymentMethod,
  getDefaultPaymentMethod,
} from 'utilities/payment-method';
import { TOAST_DISPLAY_TIME, Toast } from 'config/constants';
import { useMutation } from '@apollo/client';
import { SEND_CONFIRMATION_EMAIL } from './profile-menu.api';

const getCopy = copyFor('dashboard.profileMainMenu');

export type DashboardProfileUser = NonNullable<
  GetDashboardProfileUserQuery['currentUser']
>;

type DashboardProfileMenuProps = {
  user: DashboardProfileUser;
};

type ErrorNotification = {
  message: string;
  linkAction?: () => void;
  linkUrl?: string;
  linkText: string;
  disableAfterClick?: boolean;
  key?: string;
};

export const DashboardProfileMenu = ({
  user,
}: DashboardProfileMenuProps): JSX.Element => {
  const { addNotification } = useContext(ShrikeToastNotification.Context);

  const triggerToast = ({ message, variant = 'success' }: Toast) => {
    addNotification({
      displayTime: TOAST_DISPLAY_TIME,
      message,
      variant,
    });
  };

  const { notifications } = useContext<NotificationsContext>(Notifications);
  const utilityName =
    user.preferredUtilityAccount?.utilityName || user.utility?.name;
  const primaryPaymentMethod = getDefaultPaymentMethod(user.paymentMethods);

  const [sendConfirmationEmail] = useMutation<
    SendConfirmationEmailMutation,
    SendConfirmationEmailMutationVariables
  >(SEND_CONFIRMATION_EMAIL, {
    onCompleted: response => {
      const { sendConfirmationEmail } = response;
      if (sendConfirmationEmail.success) {
        triggerToast({ message: getCopy('emailConfirmationToastSuccess') });
        return;
      }
      triggerErrorToast();
    },
    onError: () => triggerErrorToast(),
  });

  const triggerErrorToast = () => {
    triggerToast({
      message: getCopy('emailConfirmationToastFail'),
      variant: 'error',
    });
  };

  const errorNotifications: ErrorNotification[] = [];

  if (notifications.includes(NotificationKeys.connect)) {
    errorNotifications.push({
      linkText: getCopy('credentialErrorBtn'),
      linkUrl: profileConnect,
      message: getCopy('credentialError', {
        utility: utilityName,
      }),
    });
  }

  const paymentNotificationKeys = [
    NotificationKeys.defaultPaymentMethodFailed,
    NotificationKeys.paymentExpired,
  ];
  const showPaymentNotification = paymentNotificationKeys.some(
    paymentNotification => notifications.includes(paymentNotification)
  );

  if (showPaymentNotification) {
    errorNotifications.push({
      linkText: getCopy('paymentErrorBtn'),
      linkUrl: profilePayment,
      message: getCopy('paymentError'),
    });
  }

  if (notifications.includes(NotificationKeys.emailUnconfirmed)) {
    errorNotifications.push({
      disableAfterClick: true,
      key: 'emailUnconfirmed',
      linkAction: () => sendConfirmationEmail({ variables: { input: {} } }),
      linkText: getCopy('unconfirmedEmailBtn'),
      message: getCopy('unconfirmedEmail'),
    });
  }

  return (
    <DashboardProfileMenuWrapper>
      {errorNotifications.map(errorNotification => (
        <Notification
          inMenu
          key={errorNotification.linkUrl || errorNotification.key}
          testId={`notification-${
            errorNotification.linkUrl || errorNotification.key
          }`}
          errorMessage={errorNotification.message}
          linkUrl={errorNotification.linkUrl}
          linkText={errorNotification.linkText}
          linkAction={errorNotification.linkAction}
        />
      ))}
      <ProfileMenuOptionsWrapper>
        <ProfileHeader title={getCopy('title')} />
        <MenuOption
          title={getCopy('accountDetails')}
          subtitle={`${getCopy('accountDetailsSubtitle', {
            firstName: user.firstName,
            lastName: user.lastName,
          })}`}
          to={profileAccount}
          testId="optionAccountDetails"
        />
        <MenuOption
          title={getCopy('paymentMethod')}
          subtitle={
            primaryPaymentMethod
              ? formatPaymentMethod(primaryPaymentMethod)
              : getCopy('noPaymentMethod')
          }
          to={profilePayment}
          testId="optionPaymentMethod"
          notification={showPaymentNotification}
        />
        <MenuOption
          title={getCopy('connect')}
          subtitle={utilityName}
          to={profileConnect}
          testId="optionConnect"
          notification={notifications.includes(NotificationKeys.connect)}
        />
        <MenuOption
          title={getCopy('signOut')}
          to={logout}
          testId="optionLogout"
        />
      </ProfileMenuOptionsWrapper>
    </DashboardProfileMenuWrapper>
  );
};
