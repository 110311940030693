import { gql } from '@apollo/client';

export const GET_CURRENT_BRIGHTEN_PLAN = gql`
  query getCurrentBrightenPlan($utilityId: Int!) {
    currentBrightenPlan(utilityId: $utilityId) {
      id
      availabilityStartDate
      availabilityEndDate
      campaignProductId
      termLengthMonths
      hasCurrentContracts
      cohortMonthlyRates {
        id
        brightenPlanCohortId
        month
        baseRate
        seasonsPassDiscount
      }
    }
  }
`;

export const CREATE_BRIGHTEN_PLAN = gql`
  mutation createBrightenPlan($input: CreateBrightenPlanInput!) {
    createBrightenPlan(input: $input) {
      id
    }
  }
`;

export const generateCurrentBrightenPlan = (): GetCurrentBrightenPlanQuery => ({
  currentBrightenPlan: {
    availabilityEndDate: '2022-12-12',
    availabilityStartDate: '2021-12-12',
    campaignProductId: 'ARCADIA01010101',
    cohortMonthlyRates: [
      {
        baseRate: 10.29,
        brightenPlanCohortId: 1,
        id: 1,
        month: '2022-01-01',
        seasonsPassDiscount: 0,
      },
      {
        baseRate: 10.29,
        brightenPlanCohortId: 1,
        id: 2,
        month: '2022-02-01',
        seasonsPassDiscount: 5.15,
      },
    ],
    hasCurrentContracts: true,
    id: 1,
    termLengthMonths: 12,
  },
});
