import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const Wrapper = styled.div`
  padding: 2rem 1rem 0 1rem;
  background-color: white;
  ${props => props.theme.media.desktop`
    grid-area: top_right;
    border: 1px solid #ced4da;
    border-radius: 8px;
  `}
  ${props => props.theme.media.phoneAndTablet`
    margin-bottom: 1rem;
  `}
`;

export const LoadingWrapper = styled.div`
  margin-top: 2rem;
  background-color: white;
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const EnergyUseHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NoDataNoticeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
`;

export const NoDataNotice = styled(Text)`
  padding: 2rem 1rem;
`;
