import { gql } from '@apollo/client';
import {
  FULL_PAYMENT_METHOD_FRAGMENT,
  generateFullPaymentMethod,
} from 'api/fragments';

export const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation setDefaultPaymentMethod($input: SetDefaultPaymentMethodInput!) {
    setDefaultPaymentMethod(input: $input) {
      user {
        id
        paymentMethods {
          id
          ...FullPaymentMethod
        }
      }
    }
  }
  ${FULL_PAYMENT_METHOD_FRAGMENT}
`;

export const generateSetDefaultPaymentMethodResponse =
  (): SetDefaultPaymentMethodMutation => ({
    setDefaultPaymentMethod: {
      user: {
        id: '2',
        paymentMethods: [
          generateFullPaymentMethod({
            paymentMethod: {
              id: 2,
            },
          }),
          generateFullPaymentMethod({
            paymentMethod: {
              defaultPaymentMethodForUtilityAccountIds: [2],
              id: 3,
            },
          }),
        ],
      },
    },
  });
