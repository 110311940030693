const checkEnvForBoolean = (value?: string) =>
  (value || '').toUpperCase() === 'TRUE';

export const API_URL = process.env.ARCADIA_API_URL ?? 'http://localhost:3000';
export const APOLLO_LOGGER = checkEnvForBoolean(
  process.env.ARCADIA_APOLLO_LOGGER
);
export const DEBUG_ANALYTICS = checkEnvForBoolean(
  process.env.ARCADIA_DEBUG_ANALYTICS
);

export const STORE_KEY_UTILITY = 'utility';
export const STORE_SIGNUP_MONTH = 'signupMonth';
export const STORE_KEY_ZIP = 'zip';
export const STORE_KEY_BRIGHTEN_PLAN_ID = 'brightenPlanId';

export const STRIPE_KEY = process.env.ARCADIA_STRIPE_KEY;

// Note: The default value should hit the right URL for all environments.
// (it refers to the endpoint https://api.arcadia.com).
// Whether the account is sandboxed or not is determined by the token on the BE
//
// You may still overwrite this variable to hit "local" penguin by setting
// this to local
export const CONNECT_ENV = process.env.REACT_APP_CONNECT_ENV;

export const SENTRY_DSN =
  process.env.ARCADIA_SENTRY_DSN ??
  'https://examplePublicKey@o0.ingest.sentry.io/0';

export const APPLE_CLIENT_ID =
  process.env.ARCADIA_APPLE_CLIENT_ID ?? 'com.arcadia.brightenergy.auth';

export const APPLE_SIGNIN_ENABLED = checkEnvForBoolean(
  process.env.ARCADIA_APPLE_SIGNIN_ENABLED
);

export const APP_BUILD_ENV = process.env.APP_BUILD_ENV ?? 'development';
// Should match the graphql type, which we cannot import directly because
// it's in the global namespace
export const USER_ENROLLED_STATES: Record<string, EnrollmentState> = {
  pending: 'pending',
  enrolled: 'enrolled',
  rejected: 'rejected',
  removed: 'removed',
};

export const APPLE_SINGUP_REDIRECT_URI = `${process.env.ARCADIA_API_URL}${process.env.ARCADIA_APPLE_SINGUP_REDIRECT_URI}`;

export const APPLE_LOGIN_REDIRECT_URI = `${process.env.ARCADIA_API_URL}${process.env.ARCADIA_APPLE_LOGIN_REDIRECT_URI}`;

export const TOAST_DISPLAY_TIME = 4000;

export type Toast = {
  message: string;
  variant?: 'error' | 'success' | 'warning' | 'info';
};
