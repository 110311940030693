export const dashboard = {
  navbar: {
    usage: 'Usage',
    bill: 'Bill',
    profile: 'Profile',
    enrollment: 'Enrollment',
  },
  emailConfirmation: {
    titleExpired: 'This link has expired',
    title: 'Your account is now confirmed',
    contentExpired:
      'You can resend a confirmation email by going to your profile under Settings.',
    confirmExpired: 'Okay',
    confirm: 'Continue to dashboard',
    confirmLogin: 'Continue to login',
  },
  profileMainMenu: {
    title: 'Settings',
    accountDetails: 'Account Details',
    accountDetailsSubtitle: '%{firstName} %{lastName}, email, password',
    connect: 'Connect',
    noPaymentMethod: 'No payment method found',
    paymentMethod: 'Payment Method',
    signOut: 'Sign Out',
    errorTitle: 'An Error occured.',
    errorDetail: 'Please refresh the page and try again',
    credentialError:
      'We’re unable to sync with your %{utility} account. Your %{utility} username and/or password do not match.',
    credentialErrorBtn: 'Update credentials',
    paymentError:
      'Your payment method is invalid. Please update your payment details to continue using your Brighten account.',
    paymentErrorBtn: 'Update payment method',
    unconfirmedEmailBtn: 'Resend confirmation email',
    unconfirmedEmail:
      'Your email is still unconfirmed. Confirm your email to continue using your Brighten account.',
    emailConfirmationToastSuccess: 'Email sent',
    emailConfirmationToastFail: 'Failed sending email',
  },
  connect: {
    title: 'Utility Sync',
    login: '%{utility} Login',
    company: 'Utility Company',
    address: 'Service Address',
    account: '%{utility} Account',
    error: 'Something went wrong. Please try again.',
    accordionTitle: 'Why might my username be different?',
    accordionContentExplanation:
      'Some utilities require the username and billing email address to be the same. If this is the case, we must change the username on file to a unique Arcadia email.',
    accordionContentReason:
      "This ensures that our billing system receives your e-bill and can generate your statement. Your utility password doesn't change.",
  },
  accountDetails: {
    title: 'Account Details',
    name: 'Name',
    email: 'Brighten Energy Login',
    account: 'Brighten Energy Account',
    nameForm: {
      title: 'Name',
      inputs: {
        firstName: 'First Name',
        lastName: 'Last Name',
      },
      update: 'Update Name',
      undo: 'Nevermind',
      error: 'Something went wrong. Please try again.',
    },
    passwordForm: {
      title: 'Change Password',
      currentPasswordInput: {
        name: 'currentPassword',
        label: 'Current password',
        placeholder: '8+ characters',
        description: 'Must contain at least 8 characters.',
      },
      newPasswordInput: {
        name: 'password',
        label: 'New password',
        placeholder: '8+ characters',
        description: 'Must contain at least 8 characters.',
      },
      update: 'Change password',
      undo: 'Nevermind',
      toast: {
        success: 'Update successful!',
        error: 'Update failed',
        errorPassword: 'Update failed, please check your current password',
      },
      error: 'Something went wrong. Please try again.',
    },
  },
  offer: {
    title: "Here's $25 on us!",
    message: 'Get a $25 Prepaid Mastercard® for signing up.',
    button: 'Continue with bonus',
    accordionTitle: 'Terms & conditions apply.',
    accordionMessage:
      'Reward is fulfilled with a prepaid Mastercard®, subject to terms and conditions of card issuer. Brighten Energy reserves the right in its sole discretion to substitute a check of equal value for the prepaid card, and to otherwise modify or cancel the program at any time.',
    accordionMessage2:
      'This Card is issued by Sunrise Banks N.A., Member FDIC, pursuant to a license from Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated. This card may be used everywhere Debit Mastercard is accepted. Registration, activation, acceptance, or use of this card constitutes acceptance of the terms and conditions stated in the Prepaid Card Agreement.',
  },
  paymentMethod: {
    title: 'Payment Method',
    active: 'Active Payment Method',
    alternate: 'Alternate Payment Methods',
    expires: 'Expires: %{date}',
    checking: 'Checking **** %{lastFour}',
    addPayment: 'Add payment method',
    delete: {
      error: 'There was an issue removing your payment method',
      title: 'Remove Payment Method',
      deletePayment: 'Remove payment method',
      cancel: 'Nevermind',
      confirmation: 'Are you sure you want to remove this payment method?',
    },
    change: {
      error: 'There was an issue changing your payment method',
      title: 'Change Payment Method',
      cancel: 'Nevermind',
      confirm: 'Save payment method',
    },
    add: {
      title: 'Add Payment Method',
      errors: {
        generic: 'Something went wrong adding your payment method.',
        savingsAccount:
          'We do not support using a savings account. Please add a checking account or credit card.',
        processingError:
          'An error occurred while processing your card. Try again in a little bit.',
        bankAccountExists: 'Bank account already exists in our records.',
        creditCardExist: 'Credit card already exists in our records.',
        discoverCard: "Sorry, we don't accept Discover cards.",
        prepaidCard: "Sorry, we don't accept prepaid cards.",
        internationalCard:
          'Sorry, we only accept payment from credit cards issued within the United States.',
        tooManyRequests:
          'Too many requests, please try again in a few minutes.',
        unsupportedCard: 'Your card does not support this type of purchase.',
        expiredCard: 'Your card has expired.',
        insufficientFunds: 'Your card has insufficient funds.',
        incorrectCardNumber: 'Your card number is incorrect.',
        declinedCard: 'Your card was declined.',
        incorrectSecurityCode: "Your card's security code is incorrect.",
        invalidSecurityCode: "Your card's security code is invalid.",
        invalidCheckingsAndSavings:
          'We were unable to find any valid checking or savings account(s).',
        routingNumberDigits: 'Routing number must have 9 digits.',
      },
      input: {
        ach: {
          description: {
            postLink: 'Please select the account you want to use for payment',
            preLink:
              "We'll walk you through how to search for your bank, sign in, and select the account you want to use. Click the button below to get started.",
          },
          errors: {
            noAccounts:
              'There was a problem getting your accounts. Please try linking your account again.',
          },
          submit: {
            postLink: 'Save payment method',
            preLink: 'Link checking account',
          },
          toggle: 'Checking account',
        },
        creditCard: {
          cancel: 'Cancel',
          label: 'Card details',
          submit: 'Save payment method',
          toggle: 'Credit card',
        },
      },
      cancel: 'Nevermind',
      confirm: 'Add payment method',
      errorLoadingPayment: 'There was a problem loading the payment form',
    },
  },
  helpBox: {
    header: "We're here to help!",
    content: 'Call 800-459-6040 Monday - Friday from 9 a.m. - 6 p.m. (ET)',
  },
  backButton: 'Back',
};
