import { arcadiaLate2021Theme } from '@arcadiapower/shrike';

import deepmerge from 'deepmerge';
import { css } from 'styled-components';

const breakpointSmall = 600;
const breakpointMedium = 1200;

const BRIGHTEN_YELLOW = '#FABB3A';
const BRIGHTEN_GREEN = '#68A54A';
const BRIGHTEN_GRAY = '#5D6370';
const BRIGHTEN_GRAY_2 = '#757676';
const BRIGHTEN_GRAY_LIGHT = '#CED4DA';
const BRIGHTEN_GRAY_LIGHTER = '#F5F6F7';
const BRIGHTEN_GRAY_DARK = '#304F60';
const BRIGHTEN_GRAY_DARK_2 = '#15314B';
const BRIGTHEN_TAME_BLUE = '#A9DBF3';
const BRIGHTEN_DEEP_RED = '#BF0000';
const BRIGHTEN_LIGHT_RED = '#FFF4F2';

export const theme = deepmerge(arcadiaLate2021Theme, {
  colors: {
    primary: BRIGHTEN_YELLOW,
    accent1: BRIGHTEN_GREEN,
    accent2: BRIGHTEN_GRAY,
    onAccent2: BRIGHTEN_GRAY_LIGHT,
    onAccent3: BRIGHTEN_GRAY,
    accent3: BRIGHTEN_GRAY_LIGHT,
    accent4: BRIGHTEN_GRAY_2,
    background2: BRIGHTEN_GRAY_LIGHTER,
    backgroundVariant: BRIGHTEN_GRAY_LIGHT,
    propertyBackground: BRIGTHEN_TAME_BLUE,
    text1: BRIGHTEN_GRAY_DARK,
    error: BRIGHTEN_DEEP_RED,
    errorBackground: BRIGHTEN_LIGHT_RED,
    background: {
      accent2: BRIGHTEN_GRAY,
      errorBright: BRIGHTEN_DEEP_RED,
      primary: 'white',
      primaryInverse: BRIGHTEN_YELLOW,
    },
    content: {
      accent2: BRIGHTEN_GRAY,
      accent3: BRIGHTEN_GRAY_DARK,
      accent4: BRIGHTEN_GRAY_DARK_2,
      accent9: BRIGHTEN_YELLOW,
      error: BRIGHTEN_DEEP_RED,
      accent1: BRIGHTEN_GRAY_DARK,
      primary: BRIGHTEN_GRAY_DARK,
    },
  },
  media: {
    breakpoints: {
      medium: breakpointMedium,
      small: breakpointSmall,
    },
    desktop: (...args: any) =>
      css`
        @media (min-width: ${breakpointMedium}px) {
          ${
            /* @ts-expect-error need to spread args */
            css(...args)
          }
        }
      `,
    desktopAndTablet: (...args: any) =>
      css`
        @media (min-width: ${breakpointSmall}px) {
          ${
            /* @ts-expect-error need to spread args */
            css(...args)
          }
        }
      `,
    phone: (...args: any) =>
      css`
        @media (max-width: ${breakpointSmall - 1}px) {
          ${
            /* @ts-expect-error need to spread args */
            css(...args)
          }
        }
      `,
    phoneAndTablet: (...args: any) =>
      css`
        @media (max-width: ${breakpointMedium - 1}px) {
          ${
            /* @ts-expect-error need to spread args */
            css(...args)
          }
        }
      `,
    tablet: (...args: any) =>
      css`
        @media (min-width: ${breakpointSmall}px) {
          ${
            /* @ts-expect-error need to spread args */
            css(...args)
          }
        }
      `,
    tabletOnly: (...args: any) =>
      css`
        @media (min-width: ${breakpointSmall}px) and (max-width: breakpointMedium) {
          ${
            /* @ts-expect-error need to spread args */
            css(...args)
          }
        }
      `,
  },
});

export type VistraTheme = typeof theme;
