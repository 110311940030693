import { useContext, useState } from 'react';
import { GridContainer, PrimaryGrid, Title } from 'styles/simple-grid.style';
import { Text, Button, Alert, Loading } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { loginRoot } from 'config/routes';
import { Signup, SignupContext } from 'contexts/signup';
import { useQuery, useMutation } from '@apollo/client';
import { DashboardOfferModal } from 'components/dashboard-offer-modal';
import { noop, contractLink, contractTypes } from 'utilities';
import { trackEvent } from 'utilities/analytics';
import {
  PlanProperties,
  PropertiesContainer,
  DetailsContainer,
  TextWrapper,
  DetailsLinkContainer,
  DetailsLink,
  LoginLink,
} from './plan-selection.style';
import {
  GET_CURRENT_BRIGHTEN_PLAN,
  CREATE_BRIGHTEN_PLAN,
} from './plan-selection.api';

const getCopy = copyFor('signup.steps.planSelection');

export const PlanSelection = (): JSX.Element => {
  const [offerModalOpen, setOfferModalOpen] = useState(true);
  const { utility, next, setBrightenPlanId } =
    useContext<SignupContext>(Signup);

  const { data, error, loading } = useQuery<
    GetCurrentBrightenPlanQuery,
    GetCurrentBrightenPlanQueryVariables
  >(
    GET_CURRENT_BRIGHTEN_PLAN,
    // Skip the query if there's no utility
    utility ? { variables: { utilityId: utility.id } } : { skip: true }
  );

  const [createBrightenPlan, { loading: loadingSubmit, error: errorSubmit }] =
    useMutation<
      CreateBrightenPlanMutation,
      CreateBrightenPlanMutationVariables
    >(CREATE_BRIGHTEN_PLAN, {
      onCompleted: ({ createBrightenPlan }) => {
        setBrightenPlanId(createBrightenPlan.id);
        next();
      },
      onError: noop,
    });

  // TODO: refactor cohorts to handle more than one base rate + discount rate in the system
  // https://arcadiapower.atlassian.net/browse/VISTRA-359
  const cohortMonthlyRates = data?.currentBrightenPlan?.cohortMonthlyRates;
  const planRate = cohortMonthlyRates?.length && cohortMonthlyRates[0].baseRate;
  const seasonsPassRate = cohortMonthlyRates?.find(
    cohortRate => cohortRate.seasonsPassDiscount !== 0
  )?.seasonsPassDiscount;
  const discountRate =
    planRate &&
    seasonsPassRate &&
    parseFloat((planRate - seasonsPassRate).toFixed(3));

  const planDuration = data?.currentBrightenPlan.termLengthMonths;
  const brightenPlanCohortId = data?.currentBrightenPlan.id;
  const cohortHasAvailableContracts =
    data?.currentBrightenPlan.hasCurrentContracts;

  // If we're missing values, hide the page content
  const dataError =
    (data && (!planRate || !discountRate || !planDuration)) ||
    !utility ||
    !cohortHasAvailableContracts;

  const onClick = () => {
    trackEvent('Signup submit: plan-selection', {
      campaignProductId: data?.currentBrightenPlan?.campaignProductId,
      productName: 'Brighten Season Pass 12',
    });
    if (brightenPlanCohortId) {
      createBrightenPlan({ variables: { input: { brightenPlanCohortId } } });
    }
  };

  const utilityId = (utility as NonNullable<SignupContext['utility']>).id;

  const modalClick = () => {
    setOfferModalOpen(!offerModalOpen);
  };

  return (
    <GridContainer data-testid="step.plan-selection">
      <PrimaryGrid>
        {offerModalOpen && <DashboardOfferModal hide={modalClick} />}
        {!data && loading && (
          <div>
            <Loading />
          </div>
        )}
        {data && !dataError && (
          <>
            <Title>{getCopy('title')}</Title>
            <Text margin={{ bottom: '16px' }}>{getCopy('subtitle1')}</Text>
            <Text
              color="accent1"
              tag="h2"
              textStyle="heading600"
              margin={{ bottom: '20px' }}
            >
              {getCopy('subtitle2', { discountRate })}
            </Text>
            <PropertiesContainer>
              <PlanProperties>
                <Text color="accent2" tag="h2" textStyle="heading900">
                  {planRate}&cent;
                </Text>
                <Text color="accent2" textStyle="paragraph500">
                  {getCopy('perKWH')}
                </Text>
              </PlanProperties>
              <PlanProperties>
                <Text color="accent2" tag="h2" textStyle="heading900">
                  {planDuration}
                </Text>
                <Text color="accent2" textStyle="paragraph500">
                  {getCopy('months')}
                </Text>
              </PlanProperties>
            </PropertiesContainer>

            <DetailsContainer>
              <Text textStyle="paragraph400">{getCopy('planDetails')}</Text>
              <DetailsLinkContainer>
                <DetailsLink
                  href={contractLink(contractTypes.CONTRACT, utilityId)}
                  openInNewTab={true}
                  type="inline"
                >
                  {getCopy('rate')}
                </DetailsLink>
                <DetailsLink
                  href={contractLink(contractTypes.DISCLOSURE, utilityId)}
                  openInNewTab={true}
                  type="inline"
                >
                  {getCopy('terms')}
                </DetailsLink>
              </DetailsLinkContainer>
            </DetailsContainer>
            <Button
              type="button"
              onClick={onClick}
              data-testid="buttonSelectPlan"
              role="button"
              disabled={!data}
              loading={loadingSubmit}
            >
              {getCopy('button')}
            </Button>
          </>
        )}

        {(error || dataError || errorSubmit) && (
          <Alert margin={{ bottom: '30px' }}>{getCopy('error')}</Alert>
        )}

        <TextWrapper>
          <Text margin={{ top: '16px' }}>
            {getCopy('existingAccount')}{' '}
            <LoginLink to={loginRoot} hideIcon>
              {getCopy('login')}
            </LoginLink>
            .
          </Text>
        </TextWrapper>
        <TextWrapper>
          <Text>{getCopy('help')}</Text>
          <Text>{getCopy('contact')}</Text>
          <Text>{getCopy('schedule')}</Text>
        </TextWrapper>
        <TextWrapper>
          <Text textStyle="paragraph400">{getCopy('length')}</Text>
        </TextWrapper>
        <TextWrapper>
          <Text textStyle="paragraph400">
            {getCopy('disclaimer', { utility: utility?.name })}
          </Text>
        </TextWrapper>
      </PrimaryGrid>
    </GridContainer>
  );
};
