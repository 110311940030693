import { SignupProvider } from 'contexts/signup';
import { Page } from 'styles';
import { RemoteSignIn } from 'components/signup/remote-signin/remote-signin.component';
import { NavBarSimple } from 'components/nav-bar-simple';

export const Signup = (): JSX.Element => {
  return (
    <Page>
      <SignupProvider>
        <NavBarSimple />
        <RemoteSignIn />
      </SignupProvider>
    </Page>
  );
};
