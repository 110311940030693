import { useContext } from 'react';
import { GridContainer, PrimaryGrid, Title } from 'styles/simple-grid.style';
import { Text } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { Signup, SignupContext } from 'contexts/signup';
import { ReviewContent } from 'components/signup/forms/review';
import { generatePdf } from 'utilities';
import { useMutation } from '@apollo/client';
import { UPLOAD_ENROLLMENT_CONFIRMATION } from './review.component.api';

const getCopy = copyFor('signup.steps.review');
interface ReviewProps {
  pdfTargetId?: string;
}

export const Review: React.FC<ReviewProps> = ({
  pdfTargetId = 'enrollmentConfirmationContainer',
}) => {
  const { zip } = useContext<SignupContext>(Signup);
  const [uploadEnrollmentConfirmation] = useMutation<
    UploadEnrollmentConfirmationMutation,
    UploadEnrollmentConfirmationMutationVariables
  >(UPLOAD_ENROLLMENT_CONFIRMATION, {
    onError: () => {
      throw new Error('PDF upload failed');
    },
  });

  const onSubmit = async () => {
    const pdfTarget = document.getElementById(pdfTargetId);
    if (pdfTarget) {
      const base64PDF = await generatePdf(pdfTarget);
      await uploadEnrollmentConfirmation({
        variables: { input: { base64File: base64PDF } },
      });
      return;
    }
    throw new Error('PDF generation failed');
  };
  return (
    <GridContainer data-testid="step.review">
      <PrimaryGrid
        id="enrollmentConfirmationContainer"
        data-testid="enrollmentConfirmationContainer"
      >
        <Title>{getCopy('title')}</Title>
        <Text margin={{ bottom: '40px', top: '0' }}>
          {getCopy('description', { zip: zip || '' })}
        </Text>
        <ReviewContent onSubmit={onSubmit} />
      </PrimaryGrid>
    </GridContainer>
  );
};
