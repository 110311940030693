import { useMemo } from 'react';
import { Text } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';

export type Props = {
  user: { firstName: string };
  variant: 'pendingData' | 'pendingEnrollment';
};

export const PendingState = ({ user, variant }: Props): JSX.Element => {
  const getCopy = useMemo(() => copyFor(`enrollment.${variant}`), [variant]);
  return (
    <div data-testid={`pendingEnrollment-${variant}`}>
      <Text color="accent2" textStyle="heading800">
        {getCopy('title', { firstName: user.firstName })}
      </Text>
      <Text color="accent2" textStyle="paragraph500">
        {getCopy('subtitle')}
      </Text>
    </div>
  );
};
