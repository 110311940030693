import { Text } from '@arcadiapower/shrike';
import { useContext } from 'react';
import {
  GridContainer,
  PrimaryGrid,
  Title,
  PrimaryButton,
} from 'styles/simple-grid.style';
import { copyFor } from 'config/copy';
import { Signup, SignupContext } from 'contexts/signup';
import { dashboardEnrollment } from 'config/routes';
import { useHistory } from 'react-router-dom';

const getCopy = copyFor('signup.steps.welcome');

export const Welcome = (): JSX.Element => {
  const { user } = useContext<SignupContext>(Signup);
  const history = useHistory();

  return (
    <GridContainer data-testid="step.welcome">
      <PrimaryGrid id="welcomeContainer" data-testid="welcomeContainer">
        <Title>{getCopy('title', { firstName: user?.firstName })}</Title>
        <Text margin={{ bottom: '40px', top: '0' }}>
          {getCopy('description')}
        </Text>
        <PrimaryButton
          onClick={() => {
            history.push(dashboardEnrollment);
          }}
        >
          {getCopy('cta')}
        </PrimaryButton>
      </PrimaryGrid>
    </GridContainer>
  );
};
