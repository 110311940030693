import {
  ActionWrapper,
  Form,
  PrimaryButton,
  Title,
} from 'styles/simple-grid.style';
import { Input, Text, Alert } from '@arcadiapower/shrike';
import { useMutation } from '@apollo/client';
import { noop, isValidEmail } from 'utilities';
import { copyFor } from 'config/copy';
import { FORGOT_PASSWORD } from './email-form.api';

const getCopy = copyFor('login.forgotPassword');

export type EmailFormProps = {
  email: string;
  setEmail: (email: string) => void;
  onCompleted: () => void;
};

export const EmailForm: React.FC<EmailFormProps> = ({
  email,
  setEmail,
  onCompleted,
}) => {
  const [forgotPassword, { loading, error }] = useMutation<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >(FORGOT_PASSWORD, {
    onCompleted,
    onError: noop,
  });

  const handleSubmit = () => {
    forgotPassword({
      variables: { input: { email } },
    });
  };

  const canSubmit = isValidEmail(email);

  return (
    <>
      <Title>{getCopy('emailForm.title')}</Title>
      <Text>{getCopy('emailForm.description')}</Text>
      <Form onSubmit={handleSubmit}>
        <Input
          onChange={setEmail}
          name={getCopy('inputs.email.name')}
          label={getCopy('inputs.email.label')}
          placeholder={getCopy('inputs.email.placeholder')}
          value={email}
          margin={{ bottom: '24px', top: '24px' }}
        />
        <ActionWrapper>
          <PrimaryButton disabled={!canSubmit} loading={loading} type="submit">
            {getCopy('emailForm.cta')}
          </PrimaryButton>
          {error && <Alert>{getCopy('errors.generic')}</Alert>}
        </ActionWrapper>
      </Form>
    </>
  );
};
