import { copyFor } from 'config/copy';
import { Modal } from '@arcadiapower/shrike';
import { SecondaryButton, StyledModal } from 'styles/simple-grid.style';
import { PaymentForm } from 'components/form/payment-form';
import { CancelButtonContainer } from './payment-method-add.style';

const getCopy = copyFor('dashboard.paymentMethod.add');

export type Props = {
  hide: () => void;
};

export const PaymentMethodAddModal = ({ hide }: Props): JSX.Element => {
  return (
    <StyledModal aria-label="addPaymentMethodModal">
      <Modal.Header onClose={hide} title={getCopy('title')} />
      <Modal.Content>
        <PaymentForm onSubmit={hide} />
        <CancelButtonContainer>
          <SecondaryButton
            data-testid="cancelCreation"
            margin={{ top: '1rem' }}
            onClick={hide}
          >
            {getCopy('cancel')}
          </SecondaryButton>
        </CancelButtonContainer>
      </Modal.Content>
    </StyledModal>
  );
};
