import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoWrapper = styled.div`
  border-top: 1px solid;
  border-color: ${props => props.theme.colors.accent3};
  padding: 40px 0 20px;
  width: 100%;
`;

export const Label = styled(Text)`
  font-weight: 600;
  margin-bottom: 10px;
`;

export const Description = styled(Text)``;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const SmallInfo = styled(Text)`
  font-size: 11px;
  margin-top: 4px;
`;

export const AgreementsWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
`;
