import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const Logo = styled.img`
  height: 40px;
  width: auto;
  margin-bottom: 20px;
`;

export const BackButtonContainer = styled.div`
  margin: 2rem 0;
`;

export const Label = styled(Text)`
  font-weight: 700;
`;

export const PdfLinkContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  text-align: center;
`;

export const InfoContainer = styled.div`
  margin-bottom: 12px;
`;

export const MidTotalContainer = styled.div`
  border: 1px ${props => props.theme.colors.accent3};
  border-style: solid none;
  padding: 20px 0;
`;

export const MidTotalLabel = styled(Text)`
  font-weight: 500;
`;

export const MidTotalValue = styled(Text)`
  font-weight: 900;
  font-size: 32px;
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.accent3};
  padding: 12px 0;
`;

export const SeasonPassDiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
`;

export const BottomTotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

export const BottomTotalText = styled(Text)`
  font-weight: 800;
  font-size: 24px;
`;

type PaymentDateProps = {
  declined?: boolean;
};

export const PaymentDate = styled(Text)<PaymentDateProps>`
  ${props => props.declined && `color: ${props.theme.colors.error}`}
`;
