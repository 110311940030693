import { gql } from '@apollo/client';
import {
  PAYMENT_TRANSACTION_FOR_PAYMENT_METHOD,
  FULL_PAYMENT_METHOD_FRAGMENT,
  generateFullPaymentMethod,
  generatePaymentTransactionForPaymentMethod,
} from 'api/fragments';

export const GET_DASHBOARD_BILL_USER = gql`
  query getDashboardBillUser {
    currentUser {
      email
      id
      firstName
      lastName
      arcadiaUtilityCredential {
        id
        username
        verificationStatus
      }
      utility {
        id
        name
      }
      enrolledState
      brightenPlan {
        id
        brightenPlanCohort {
          id
          cohortMonthlyRates {
            id
            baseRate
            month
            seasonsPassDiscount
          }
        }
      }
    }
  }
`;

export const GET_LATEST_BRIGHTEN_STATEMENT = gql`
  query getLatestBrightenStatement {
    currentUser {
      id
      enrolledState
      paymentMethods {
        id
        ...FullPaymentMethod
      }
    }
    listBrightenStatements(limit: 1, offset: 0) {
      statements {
        id
        arcadiaInvoice {
          id
          issuedAt
          status
          paidAt
          total
          paymentScheduledAt
          lineItems {
            id
            amountCents
            type
            name
          }
          paymentTransactions {
            id
            ...PaymentTransactionPaymentMethod
          }
        }
        serviceStartDate
        serviceEndDate
        brightenSupplyChargeCents
        statementTotalCents
        seasonsPassDiscountCents
        stubBill
        utilityStatement {
          id
          kwh
        }
      }
    }
  }
  ${PAYMENT_TRANSACTION_FOR_PAYMENT_METHOD}
  ${FULL_PAYMENT_METHOD_FRAGMENT}
`;

export const generateLatestBrightenStatement = ({
  amount = 1,
} = {}): GetLatestBrightenStatementQuery => {
  return {
    currentUser: {
      __typename: 'User',
      enrolledState: 'enrolled',
      id: '1',
      paymentMethods: [generateFullPaymentMethod()],
    },
    listBrightenStatements: {
      statements: Array.from(Array(amount).keys()).map(id => ({
        arcadiaInvoice: {
          id: 2,
          issuedAt: '2022-02-24T17:17:01-05:00',
          lineItems: [
            {
              amountCents: 37,
              id: 4,
              name: 'Brighten Supply Charge',
              type: 'debit',
            },
            {
              amountCents: 5550,
              id: 7,
              name: 'Electric Delivery Charge',
              type: 'debit',
            },
          ],
          paidAt: '2022-02-25T11:55:55-05:00',
          paymentScheduledAt: '2022-02-27T17:17:01-05:00',
          paymentTransactions: [
            generatePaymentTransactionForPaymentMethod({
              variant: 'credit_card',
            }),
          ],
          status: 'paid',
          total: 5587,
        },
        brightenSupplyChargeCents: 1000,
        id: id + 1,
        seasonsPassDiscountCents: 200,
        serviceEndDate: '2021-10-10',
        serviceStartDate: '2021-10-01',
        statementTotalCents: 800,
        stubBill: false,
        utilityStatement: {
          dueDate: '10-10-2021',
          id: id * 2 + 1,
          kwh: 500,
          utilityCharge: 200,
        },
      })),
    },
  };
};
