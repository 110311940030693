import { MainMenuContainer } from 'styles';
import { ProfileHeader } from 'components/dashboard-profile-header';
import { copyFor } from 'config/copy';
import { MenuOption } from 'components/dashboard-menu-option';
import { DashboardProfileUser } from 'pages/dashboard/profile';
import {
  dashboardProfile,
  profileAccountName,
  profileAccountPassword,
} from 'config/routes';

const getCopy = copyFor('dashboard.accountDetails');

export type Props = {
  user: DashboardProfileUser;
};

export const ProfileAccountDetails = ({ user }: Props): JSX.Element => {
  return (
    <MainMenuContainer>
      <ProfileHeader title={getCopy('title')} backLink={dashboardProfile} />
      <MenuOption
        title={getCopy('name')}
        subtitle={`${user.firstName} ${user.lastName}`}
        to={profileAccountName}
        testId="optionName"
      />
      <MenuOption
        title={getCopy('email')}
        subtitle={user?.email}
        to={user.isOauthLogin ? undefined : profileAccountPassword}
        testId="optionEmail"
        hideArrow={user.isOauthLogin}
      />
      {user.brightenAccountNumber && (
        <MenuOption
          title={getCopy('account')}
          subtitle={`#${user.brightenAccountNumber}`}
          testId="optionAccount"
          hideArrow
        />
      )}
    </MainMenuContainer>
  );
};
