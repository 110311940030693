import { useCallback } from 'react';
import { PrimaryButton } from 'styles/simple-grid.style';
import { copyFor } from 'config/copy';
import { useConnect } from '@arcadia-eng/connect-react';
import { Alert } from '@arcadiapower/shrike';
import { CONNECT_ENV } from 'config/constants';
import { EVENT_LABELS, trackEvent } from 'utilities/analytics';

const getCopy = copyFor('signup.steps.connectArcadia');

export type Props = {
  disabled: boolean;
  next: () => void;
  saveUtilityCredentialId: (opts: {
    variables: {
      input: {
        arcadiaUtilityCredentialId: number;
      };
    };
  }) => void;
  connectToken?: string;
  zip: string;
  utility: ArcadiaUtility;
  updatingCredential: boolean;
};

export const ConnectArcadiaCtaButton = ({
  disabled,
  next,
  saveUtilityCredentialId,
  connectToken,
  zip,
  utility,
  updatingCredential,
}: Props): JSX.Element => {
  const [{ loading, error }, open] = useConnect();

  const onCredentialsSubmitted = useCallback(
    ({ utilityCredentialId }: { utilityCredentialId: number }) => {
      saveUtilityCredentialId({
        variables: {
          input: { arcadiaUtilityCredentialId: utilityCredentialId },
        },
      });
    },
    [saveUtilityCredentialId]
  );

  const onClose = useCallback(
    ({ status }) => {
      trackEvent(EVENT_LABELS.UTILITY_CREDENTIALS_STATUS_UPDATED, {
        utilityCredentialStatus: status,
      });

      if (!['no_submit', 'error', 'rejected'].includes(status)) {
        next();
      }
    },
    [next]
  );

  const config = {
    _experimental: { cannotChangeUtility: true },
    callbacks: { onClose, onCredentialsSubmitted },
    connectToken,
    env: CONNECT_ENV,
    newCredentialData: updatingCredential
      ? undefined
      : { utilityId: utility.id, zipCode: zip },
  };

  return (
    <>
      <PrimaryButton
        onClick={() => open(config)}
        disabled={loading || disabled}
      >
        {getCopy('cta')}
      </PrimaryButton>
      {error && <Alert>{getCopy('errors.generic')}</Alert>}
    </>
  );
};
