import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loading } from 'components/signup/loading';
import { SignupContext, Signup } from 'contexts/signup/signup.context';
import jwt from 'jwt-decode';
import { useQueryParams } from 'hooks/use-query-params.hook';
import { useMutation } from '@apollo/client';
import { CREATE_USER } from 'components/signup/forms/create-account/create-account-form.api';
import { GET_SIGNUP_USER } from 'contexts/signup/signup.api';
import { signup } from 'config/routes';
import { StepConnector } from '../step-connector/step-connector.component';

type AppleJWT = {
  authTime: number;
  cHash: string;
  email: string;
  emailVerified: boolean;
  exp: number;
  iat: number;
  isPrivateEmail: boolean;
  iss: string;
  sub: string;
  nonceSupported: boolean;
};

export const RemoteSignIn = (): JSX.Element | null => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const signInToken = queryParams.get('signInToken');
  const firstName = queryParams.get('firstName');
  const lastName = queryParams.get('lastName');
  const accountSource = queryParams.get('accountSource');

  const {
    brightenPlanId,
    utility,
    setAuthenticated,
    zip,
    next,
    authenticated,
  } = useContext<SignupContext>(Signup);
  const [submitting, setSubmitting] = useState(false);

  const [email, setEmail] = useState('');
  const [signInCode, setSignInCode] = useState('');

  const [createUser] = useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CREATE_USER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_SIGNUP_USER }],
  });

  useEffect(() => {
    const submitUserCreation = async () => {
      if (firstName && lastName) {
        setSubmitting(true);
        try {
          await createUser({
            variables: {
              input: {
                accountSource,
                brightenPlanId,
                email,
                firstName,
                lastName,
                password: 'defaultP@ssword',
                signInCode,
                utilityId: (utility as NonNullable<SignupContext['utility']>)
                  .id,
                zipCode: zip as NonNullable<SignupContext['zip']>,
              },
            },
          });
          setAuthenticated(true);
          next();
          window.location.reload();
        } catch (error) {
          history.push(signup);
        }
      }
    };

    if (signInToken) {
      const decoded: AppleJWT = jwt(signInToken);

      setEmail(decoded.email);
      setSignInCode(decoded.sub);

      if (
        email &&
        firstName &&
        lastName &&
        signInCode &&
        zip &&
        brightenPlanId &&
        (utility as NonNullable<SignupContext['utility']>).id &&
        !submitting &&
        !authenticated
      ) {
        submitUserCreation();
      }
    }
  }, [
    signInToken,
    email,
    firstName,
    lastName,
    signInCode,
    zip,
    utility,
    submitting,
    createUser,
    accountSource,
    brightenPlanId,
    setAuthenticated,
    next,
    authenticated,
    history,
  ]);

  if (submitting) return <Loading />;
  return <StepConnector />;
};
