import styled from 'styled-components';

export const Logo = styled.img`
  height: 40px;
  width: auto;
  left: 48px;
  position: absolute;

  ${props => props.theme.media.phoneAndTablet`
    display: none;
  `};
`;

export const NavbarWrapper = styled.div`
  background-color: white;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 72px;
  z-index: 1000;

  ${props => props.theme.media.desktop`
    top: 0;
    border-bottom: 1px solid ${props.theme.colors.accent3};
  `};

  ${props => props.theme.media.phoneAndTablet`
    bottom: 0;
    border-top: 1px solid ${props.theme.colors.accent3};
  `};
`;

export const NavButtonsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.media.desktop`
    width: 375px;
  `};
`;
