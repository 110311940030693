import { useState, useEffect, useMemo, useCallback } from 'react';
import { Text, Icon } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { useQuery } from '@apollo/client';
import { EnergySkeleton } from '../energy-skeleton';
import { StatementGraph } from './statement-graph';
import { StatementComparator } from './statement-comparator';
import { HeaderIcon } from './header-icon';
import {
  Wrapper,
  LoadingWrapper,
  ButtonsContainer,
  EnergyUseHeader,
  NoDataNotice,
  NoDataNoticeContainer,
} from './energy-use.style';
import { GET_DASHBOARD_BRIGHTEN_STATEMENTS_GRAPH } from './energy-use.api';

const getCopy = copyFor('usage.energyUse');

export type BrightenStatementList = NonNullable<
  ListBrightenStatementsGraphQuery['listBrightenStatements']['statements']
>;

export type EnergyUseProps = {
  className?: string;
  loading: boolean;
};

export const EnergyUse: React.FC<EnergyUseProps> = ({ className, loading }) => {
  const [currentStatementIndex, setCurrentStatementIndex] = useState<number>(0);
  const [offset, setOffset] = useState(0); // Query offset
  const itemsPerPage = 10;

  const {
    data: listBrightenStatementsQueryData,
    loading: listBrightenStatementsQueryLoading,
    error: listBrightenStatementsQueryError,
    fetchMore,
  } = useQuery<
    ListBrightenStatementsGraphQuery,
    ListBrightenStatementsGraphQueryVariables
  >(GET_DASHBOARD_BRIGHTEN_STATEMENTS_GRAPH, {
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: itemsPerPage,
      offset,
    },
  });

  // Invert order so that newer statements appear to the right
  const sortedStatementList = useMemo(() => {
    return [
      ...(listBrightenStatementsQueryData?.listBrightenStatements?.statements ||
        []),
    ].reverse();
  }, [listBrightenStatementsQueryData]);

  const resetCurrentStatementIndex = useCallback(async () => {
    // We're getting a fresh list of statements
    // So we need to make sure the currentStatementIndex points to one of
    //   the statements currently in view.
    // To do this we set the  currentStatementIndex to the last element in the
    //   current list of statements.
    setCurrentStatementIndex(
      sortedStatementList.length - 1 > 0 ? sortedStatementList.length - 1 : 0
    );
  }, [sortedStatementList.length]);

  const handleFetchMore = useCallback(
    async (newPage: number) => {
      const nextOffset = itemsPerPage * (newPage - 1);
      await fetchMore?.({
        variables: { limit: itemsPerPage, offset: nextOffset },
      });
      setOffset(nextOffset);
      resetCurrentStatementIndex();
    },
    [fetchMore, resetCurrentStatementIndex]
  );

  useEffect(() => {
    if (sortedStatementList) {
      resetCurrentStatementIndex();
    }
  }, [sortedStatementList, resetCurrentStatementIndex]);

  const changeCurrentStatement = (newStatementIndex: number) => {
    // Fetching for older statements
    setCurrentStatementIndex(newStatementIndex);
    if (newStatementIndex < 0 && currentPage < pages) {
      handleFetchMore(currentPage + 1);
    }

    // Fetching for newer statements
    if (sortedStatementList.length - 1 < newStatementIndex && currentPage > 1) {
      handleFetchMore(currentPage - 1);
    }
  };

  if (loading || listBrightenStatementsQueryLoading) {
    return (
      <LoadingWrapper data-testid="energyUseSkeletonContainer">
        <EnergySkeleton />
      </LoadingWrapper>
    );
  }

  const pages =
    listBrightenStatementsQueryData?.listBrightenStatements.pages || 1;
  const currentPage = offset / itemsPerPage + 1;
  const emptyStatementList = sortedStatementList.length < 1;

  return (
    <Wrapper className={className}>
      <EnergyUseHeader data-testid="energyUseContainer">
        <Text textStyle="heading800">{getCopy('header')}</Text>
        <ButtonsContainer data-testid={`controlsPage${currentPage}`}>
          <HeaderIcon
            onClick={() => changeCurrentStatement(currentStatementIndex - 1)}
            icon="ChevronLeft"
            testid="energyUseIconLeft"
            disabled={
              emptyStatementList ||
              !!listBrightenStatementsQueryError ||
              (currentStatementIndex === 0 && currentPage === pages)
            }
          />
          <HeaderIcon
            onClick={() => changeCurrentStatement(currentStatementIndex + 1)}
            icon="ChevronRight"
            testid="energyUseIconRight"
            disabled={
              (currentPage === 1 &&
                offset === 0 &&
                currentStatementIndex === sortedStatementList.length - 1) ||
              !!listBrightenStatementsQueryError ||
              emptyStatementList
            }
          />
        </ButtonsContainer>
      </EnergyUseHeader>
      {emptyStatementList || listBrightenStatementsQueryError ? (
        <NoDataNoticeContainer data-testid="noDataNoticeContainer">
          {listBrightenStatementsQueryError ? (
            <NoDataNoticeContainer className="usagePage">
              <Icon
                icon="ExclamationCircleInverted"
                color="accent2"
                scale={3}
                margin={{ bottom: '1rem' }}
              />
              <Text textStyle="paragraph400">{getCopy('error')}</Text>
            </NoDataNoticeContainer>
          ) : (
            <NoDataNotice textStyle="paragraph400">
              {getCopy('noData')}
            </NoDataNotice>
          )}
        </NoDataNoticeContainer>
      ) : (
        <>
          <StatementComparator
            currentStatementIndex={currentStatementIndex}
            statements={sortedStatementList}
          />
          <StatementGraph
            currentStatementIndex={currentStatementIndex}
            statements={sortedStatementList}
          />
        </>
      )}
    </Wrapper>
  );
};
