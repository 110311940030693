import { gql } from '@apollo/client';
import { generateDashboardProfileUser } from 'mocks/user';
import { FULL_PAYMENT_METHOD_FRAGMENT } from 'api/fragments';

export const GET_DASHBOARD_PROFILE_USER = gql`
  query getDashboardProfileUser {
    currentUser {
      email
      emailConfirmed
      id
      firstName
      lastName
      enrolledState
      brightenAccountNumber
      isOauthLogin
      arcadiaUtilityCredential {
        id
        username
        verificationStatus
      }
      utility {
        id
        name
      }
      paymentMethods {
        id
        ...FullPaymentMethod
      }
      preferredUtilityAccount {
        id
        accountNumber
        utilityCredentialId
        serviceAddressCity
        serviceAddressState
        serviceAddressStreetOne
        serviceAddressStreetTwo
        serviceAddressZip
        utilityName
      }
    }
  }
  ${FULL_PAYMENT_METHOD_FRAGMENT}
`;

export const generateCurrentUserOutput = (): GetDashboardProfileUserQuery => {
  return {
    currentUser: generateDashboardProfileUser(),
  };
};
