import styled from 'styled-components';

export const IconWrapper = styled.div`
  align-items: center;
  background-color: #5d6370;
  border-radius: 25px;
  display: flex;
  margin-right: 0.75rem;
  padding: 0.5rem;
`;

export const LastIconWrapper = styled.div`
  align-items: center;
  background-color: #5d6370;
  border-radius: 25px;
  display: flex;
  margin-right: 0.75rem;
  padding: 0.5rem;
  opacity: 0.5;
`;
