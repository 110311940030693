import { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { statementDetail } from 'config/routes';
import { Text, Icon } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { EnergyUsePagination } from 'components/usage/energy-use-pagination';
import {
  formatDateRange,
  parseDynamicRoute,
  formatCurrencyFromCents,
} from 'utilities';
import { EnergySkeleton } from '../usage/energy-skeleton';
import {
  Wrapper,
  LoadingWrapper,
  TableWrapper,
  TableRow,
  AmountContainer,
  IconContainer,
  CenteredText,
} from './energy-use-history.style';
import { GET_BRIGHTEN_STATEMENTS } from './energy-use-history.api';

export type EnergyUseHistoryProps = {
  className?: string;
  showBillAmountInDetailField?: boolean;
  loading: boolean;
  title?: string;
  gridArea?: string;
};

const MAX_ITEMS = 10;
const getCopy = copyFor('usage.useHistory');

export const EnergyUseHistory: React.FC<EnergyUseHistoryProps> = ({
  className,
  showBillAmountInDetailField = false,
  loading,
  title = getCopy('title'),
  gridArea = 'bottom_right',
}) => {
  const history = useHistory();
  const [offset, setoffset] = useState(0);
  const {
    data,
    loading: loadingStatements,
    error,
    fetchMore,
  } = useQuery<
    ListBrightenStatementsHistoryQuery,
    ListBrightenStatementsHistoryQueryVariables
  >(GET_BRIGHTEN_STATEMENTS, {
    variables: { limit: MAX_ITEMS, offset },
  });

  const handleFetchMore = useCallback(
    (page: number) => {
      const newOffset = MAX_ITEMS * (page - 1);
      setoffset(newOffset);
      fetchMore?.({
        variables: { limit: MAX_ITEMS, offset: newOffset },
      });
    },
    [fetchMore]
  );

  const pages = data?.listBrightenStatements.pages;
  const statements = data?.listBrightenStatements.statements;
  const currentPage = offset / MAX_ITEMS + 1;

  const navigateToDetailPage = (id: number) => {
    if (showBillAmountInDetailField)
      history.push(parseDynamicRoute(statementDetail, { id }));
  };

  const header = (
    <Text textStyle="heading800" margin={{ bottom: '1rem' }}>
      {title}
    </Text>
  );

  if (error) {
    return (
      <Wrapper className={className} gridArea={gridArea}>
        {header}
        <div style={{ margin: '1rem 0', textAlign: 'center' }}>
          <Icon
            icon="ExclamationCircleInverted"
            color="accent2"
            scale={2}
            margin={{ top: '1rem' }}
          />
        </div>
        <CenteredText margin={{ left: '2rem', right: '2rem' }}>
          {getCopy('error.query')}
        </CenteredText>
      </Wrapper>
    );
  }

  if (loading || loadingStatements) {
    return (
      <LoadingWrapper className={className}>
        <EnergySkeleton />
      </LoadingWrapper>
    );
  }

  if (data?.listBrightenStatements.statements.length === 0) {
    return (
      <Wrapper className={className} gridArea={gridArea}>
        {header}
        <CenteredText
          textStyle="paragraph600"
          margin={{ bottom: '1rem', left: '20%', right: '20%', top: '2rem' }}
        >
          {getCopy('noStatements')}
        </CenteredText>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className} gridArea={gridArea}>
      {header}
      <TableWrapper>
        {statements?.map(statement => (
          <TableRow
            className={showBillAmountInDetailField ? 'linked' : ''}
            key={statement.id}
            data-testid={`statement-${statement.id}`}
            onClick={() => navigateToDetailPage(statement.id)}
          >
            <Text>{formatDateRange(statement)}</Text>
            {showBillAmountInDetailField ? (
              <AmountContainer data-testid={`billAmountField-${statement.id}`}>
                <Text>
                  {statement.arcadiaInvoice &&
                    formatCurrencyFromCents(statement.arcadiaInvoice?.total)}
                </Text>
                <IconContainer>
                  <Icon icon="ChevronRight" color="accent3" />
                </IconContainer>
              </AmountContainer>
            ) : (
              <span data-testid={`kwhField-${statement.id}`}>
                <Text>{`${statement.utilityStatement.kwh} kWh`}</Text>
              </span>
            )}
          </TableRow>
        ))}
      </TableWrapper>
      <EnergyUsePagination
        pages={pages || 0}
        currentPage={currentPage}
        fetchMore={handleFetchMore}
      />
    </Wrapper>
  );
};
