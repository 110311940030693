import { PropsWithChildren } from 'react';
import { Text } from '@arcadiapower/shrike';
import { InfoIcon } from './error-state.style';

export type Props = PropsWithChildren<{
  testId?: string;
  title: string;
}>;

export const ErrorState = ({ testId, title, children }: Props): JSX.Element => {
  return (
    <div data-testid={testId}>
      <div>
        <InfoIcon icon="ExclamationCircleInverted" color="accent2" />
      </div>
      <Text textStyle="heading800" color="accent3" margin="1em">
        {title}
      </Text>
      <Text textStyle="paragraph600" color="accent3">
        {children}
      </Text>
    </div>
  );
};
