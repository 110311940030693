import { Icon, IconProps } from '@arcadiapower/shrike';
import { IconWrapper, LastIconWrapper } from './header-icon.style';

export type HeaderIconProps = {
  className?: string;
  icon: IconProps['icon'];
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  testid?: string;
};

export const HeaderIcon: React.FC<HeaderIconProps> = ({
  className,
  icon,
  onClick,
  disabled = false,
  testid,
}) => {
  if (disabled) {
    return (
      <LastIconWrapper className={className} data-testid={testid}>
        <Icon icon={icon} color="primaryInverse" />
      </LastIconWrapper>
    );
  }
  return (
    <IconWrapper onClick={onClick} className={className} data-testid={testid}>
      <Icon icon={icon} color="primaryInverse" />
    </IconWrapper>
  );
};
