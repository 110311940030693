import { gql } from '@apollo/client';
import {
  FULL_PAYMENT_METHOD_FRAGMENT,
  generateFullPaymentMethod,
  generatePaymentTransactionForPaymentMethod,
  PAYMENT_TRANSACTION_FOR_PAYMENT_METHOD,
} from 'api/fragments';

export const GET_BRIGHTEN_STATEMENT = gql`
  query getBrightenStatement($brightenStatementId: Int!) {
    getBrightenStatement(brightenStatementId: $brightenStatementId) {
      id
      arcadiaInvoice {
        id
        issuedAt
        status
        paidAt
        total
        paymentScheduledAt
        lineItems {
          id
          amountCents
          type
          name
        }
        paymentTransactions {
          id
          ...PaymentTransactionPaymentMethod
        }
      }
      rate
      discountRate
      kwh
      stubBill
      user {
        id
        email
        firstName
        lastName
        paymentMethods {
          id
          ...FullPaymentMethod
        }
        preferredUtilityAccount {
          id
          accountNumber
          serviceAddressStreetOne
          serviceAddressCity
          serviceAddressZip
          serviceAddressState
        }
      }
      utilityStatement {
        id
        kwh
        pdfUrl
        serviceStartDate
        serviceEndDate
        utilityName
      }
    }
  }
  ${PAYMENT_TRANSACTION_FOR_PAYMENT_METHOD}
  ${FULL_PAYMENT_METHOD_FRAGMENT}
`;

export const generateStatementDetail = (): GetBrightenStatementQuery => {
  return {
    getBrightenStatement: {
      arcadiaInvoice: {
        id: 2,
        issuedAt: '2022-02-24T17:17:01-05:00',
        lineItems: [
          {
            amountCents: 37,
            id: 4,
            name: 'Brighten Supply Charge',
            type: 'debit',
          },
          {
            amountCents: 5550,
            id: 7,
            name: 'Electric Delivery Charge',
            type: 'debit',
          },
        ],
        paidAt: '2022-02-25T11:55:55-05:00',
        paymentScheduledAt: '2022-02-27T17:17:01-05:00',
        paymentTransactions: [
          generatePaymentTransactionForPaymentMethod({
            variant: 'credit_card',
          }),
        ],
        status: 'paid',
        total: 5587,
      },
      discountRate: [0.0, 0.0],
      id: 1,
      kwh: [200, 200],
      rate: [0.123, 0.134],
      stubBill: false,
      user: {
        email: 'example@mail.com',
        firstName: 'John',
        id: '1',
        lastName: 'Doe',
        paymentMethods: [generateFullPaymentMethod()],
        preferredUtilityAccount: {
          accountNumber: '1234567',
          id: 1,
          serviceAddressCity: 'city',
          serviceAddressState: 'ST',
          serviceAddressStreetOne: 'street one',
          serviceAddressZip: '1234',
        },
      },
      utilityStatement: {
        id: 1,
        kwh: 400,
        pdfUrl: 'https://filesamples.com/samples/document/pdf/sample3.pdf',
        serviceEndDate: '2022-01-12',
        serviceStartDate: '2021-12-12',
        utilityName: 'PECO',
      },
    },
  };
};
