import styled from 'styled-components';

export const Wrapper = styled.div`
  color: white;
  padding: 2rem 1rem;
  background-color: ${props => props.theme.colors.accent1};
  ${props => props.theme.media.desktop`
    padding-top: 4rem;
    padding-bottom: 4rem;
  `}
`;

export const LoadingWrapper = styled.div`
  padding: 2rem 1rem;
  background-color: ${props => props.theme.colors.accent1};
`;

export const TextContainer = styled.div`
  ${props => props.theme.media.desktop`
    max-width: 1200px;
    padding: 0 1.5rem;
    margin: 0 auto;
  `}
`;
