import {
  CenteredPrimaryGrid,
  GridContainer,
  Title,
} from 'styles/simple-grid.style';
import { Text } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';

const getCopy = copyFor('signup.error');

export const Error = (): JSX.Element => {
  return (
    <GridContainer data-testid="signup.error">
      <CenteredPrimaryGrid>
        <Title>{getCopy('title')}</Title>
        <Text>{getCopy('description')}</Text>
      </CenteredPrimaryGrid>
    </GridContainer>
  );
};
