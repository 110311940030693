import i18n from 'i18n-js';
import { Get } from 'type-fest';
import { signup } from './signup';
import { login } from './login';
import { logout } from './logout';
import { dashboard } from './dashboard';
import { enrollment } from './pre-enrollment';
import { usage } from './usage';
import { bill } from './bill';
import { utilities } from './utilities';

const copy = {
  en: {
    signup,
    login,
    dashboard,
    enrollment,
    logout,
    usage,
    bill,
    utilities,
  },
};

i18n.translations = copy;

type PathImpl<T, K extends keyof T> = K extends string
  ? T[K] extends Record<string, any>
    ? T[K] extends ArrayLike<any>
      ? K | `${K}.${PathImpl<T[K], Exclude<keyof T[K], keyof any[]>>}`
      : K | `${K}.${PathImpl<T[K], keyof T[K]>}`
    : K
  : never;

type Path<T> = PathImpl<T, keyof T> | keyof T;

type PathValue<T, P extends Path<T>> = P extends `${infer K}.${infer Rest}`
  ? K extends keyof T
    ? Rest extends Path<T[K]>
      ? PathValue<T[K], Rest>
      : never
    : never
  : P extends keyof T
  ? T[P]
  : never;

export type KeysFromCopyScope<PathString> = keyof PathValue<
  CopyDictionary,
  PathString extends Path<CopyDictionary> ? PathString : never
>;

type CopyDictionary = typeof copy.en;

type GetCopyFunction<T> = Path<
  PathValue<CopyDictionary, T extends Path<CopyDictionary> ? T : never>
>;

export const copyFor =
  <T extends Path<CopyDictionary>>(scope: T) =>
  <P extends GetCopyFunction<T> & string>(
    property: P,
    ...args: Record<string, string | number | undefined>[]
  ) => {
    const result = i18n.t(`${scope}.${property}`, ...args);
    return result as Get<CopyDictionary, `${T}.${P}`, { strict: true }>;
  };
