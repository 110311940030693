import { useState } from 'react';
import { Page } from 'styles';
import { GridContainer, PrimaryGrid } from 'styles/simple-grid.style';
import { NavBarSimple } from 'components/nav-bar-simple';
import { EmailForm, SubmittedEmail } from 'components/forgot-password';
import { loginRoot } from 'config/routes';
import { BackButton } from 'components/dashboard-back-button';
import {
  DesktopBackButtonContainer,
  MobileBackButtonContainer,
} from './forgot-password.style';

export const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  return (
    <Page>
      <MobileBackButtonContainer style={{ margin: '0 0 0 1rem ' }}>
        <BackButton to={loginRoot} />
      </MobileBackButtonContainer>
      <NavBarSimple />

      <GridContainer>
        <PrimaryGrid>
          <>
            <DesktopBackButtonContainer style={{ margin: '2rem 0' }}>
              <BackButton to={loginRoot} />
            </DesktopBackButtonContainer>
            {submitted ? (
              <SubmittedEmail
                email={email}
                ctaAction={() => {
                  setSubmitted(false);
                  setEmail('');
                }}
              />
            ) : (
              <EmailForm
                email={email}
                setEmail={setEmail}
                onCompleted={() => setSubmitted(true)}
              />
            )}
          </>
        </PrimaryGrid>
      </GridContainer>
    </Page>
  );
};
