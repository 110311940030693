import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const Wrapper = styled.div`
  margin: 1rem 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const StyledText = styled(Text)`
  cursor: pointer;
  font-weight: bold;
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
`;
