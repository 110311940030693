import styled from 'styled-components';
import { Modal } from '@arcadiapower/shrike';

export const StyledModal = styled(Modal)`
  & > div {
    margin: 1rem;
    background: ${props => props.theme.colors.accent1};
  }

  h2 {
    color: white;
  }
  button svg path {
    fill: white;
  }
  button[aria-label='Close button'] {
    border: white 1px solid;
  }
`;
