import { gql } from '@apollo/client';
import { USER_ENROLLED_STATES } from 'config/constants';
import { AuthenticatedUserQuery } from 'utilities/typescript';

export const CURRENT_USER_FOR_PRE_ENROLLMENT = gql`
  query currentUserForPreEnrollment {
    currentUser {
      id
      enrolledState
      firstName
      preferredUtilityAccountId
      planStart
      rejectionReason
      arcadiaUtilityCredential {
        id
        accountsLoadInProgress
        verificationStatus
      }
    }
  }
`;

export const generateUserForPreEnrollment = ({
  accountsLoadInProgress = false,
  verificationStatus = 'unverified',
  preferredUtilityAccountId = null,
  rejectionReason = null,
}: {
  accountsLoadInProgress?: boolean;
  verificationStatus?: string;
  preferredUtilityAccountId?: Maybe<number>;
  rejectionReason?: RejectionReason | null;
} = {}): AuthenticatedUserQuery<CurrentUserForPreEnrollmentQuery> => ({
  currentUser: {
    __typename: 'User',
    arcadiaUtilityCredential: {
      accountsLoadInProgress,
      id: 22,
      verificationStatus,
    },
    enrolledState: USER_ENROLLED_STATES.pending,
    firstName: 'John',
    id: '2',
    planStart: null,
    preferredUtilityAccountId,
    rejectionReason,
  },
});
