import { APPLE_CLIENT_ID, APPLE_SINGUP_REDIRECT_URI } from 'config/constants';
import AppleLogin from 'react-apple-login';
import AppleLogo from 'assets/apple-logo.png';
import { Button, Text } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';

export type Props = {
  buttonCopy?: string;
  className?: string;
  redirectURI?: string;
};

const getCopy = copyFor('login.loginEmail');

export const AppleSignInButton = ({
  buttonCopy = getCopy('appleSignin') || '',
  className,
  redirectURI = APPLE_SINGUP_REDIRECT_URI || '',
}: Props): JSX.Element => {
  return (
    <div style={{ cursor: 'pointer' }} data-testid="AppleSignInButtonContainer">
      <AppleLogin
        clientId={APPLE_CLIENT_ID}
        redirectURI={redirectURI}
        render={renderProps => (
          <Button
            backgroundless
            type="button"
            onClick={renderProps.onClick}
            style={{
              padding: '5px 10px',
            }}
          >
            <img
              src={AppleLogo}
              alt="apple logo"
              style={{ paddingRight: '5px' }}
            />
            <Text textStyle="heading400">{buttonCopy}</Text>
          </Button>
        )}
        scope="name email"
        responseType="code id_token"
        responseMode="form_post"
      />
    </div>
  );
};
