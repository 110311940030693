import styled from 'styled-components';
import { Select as ShrikeSelect } from '@arcadiapower/shrike';

export const Select = styled(ShrikeSelect)`
  width: 100%;
  max-width: 100%;
`;

export const ContactInfoWrapper = styled.div`
  margin-top: 2rem;
  text-align: center;
`;
