import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { identifyUser, trackPage, resetTracking } from 'utilities/analytics';

export const IDENTIFY_USER_QUERY = gql`
  query identifyUserData {
    currentUser {
      id
      email
      firstName
      lastName
    }
  }
`;

export const useGlobalTracking = (): void => {
  const location = useLocation();
  useEffect(() => {
    trackPage();
  }, [location]);

  // Note - in the case that we fail to load the currentUser - this should
  // silently fail
  const { data } = useQuery<
    IdentifyUserDataQuery,
    IdentifyUserDataQueryVariables
  >(IDENTIFY_USER_QUERY);

  useEffect(() => {
    if (!data) return;
    // If the user is logged in, we identify them.
    // If the user is not, we unidentify them.
    //
    // The cache updating should cause this to always be up to date if the user
    // signs up or logs in.
    const user = data.currentUser;
    if (user) {
      identifyUser(user);
    } else {
      resetTracking();
    }
  }, [data]);
};
