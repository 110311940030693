import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100vh;
    /* Safari  */
    height: -webkit-fill-available;
    margin: 0;
    width: 100%;
    height: 100%;
    
    && {
      padding: 0;
    }
  }
`;
